import { SelectOptions } from '../definitions/select-options';

/**
 * Maps arrays of keys and values into an array of SelectOptions.
 *
 * Handles missing keys or values arrays gracefully.
 *
 * @param values - An array of values or undefined.
 * @param keys - An array of keys or undefined.
 * @returns An array of SelectOptions or undefined if both inputs are undefined.
 */
export function keyValueToSelectOption(
    values: Array<string | number> | undefined,
    keys: Array<string> | undefined,
): Array<SelectOptions> | undefined {
    if (!values && !keys) {
        return undefined;
    }

    const length = Math.max((values ?? []).length, (keys ?? []).length);

    return Array.from({ length }, (_item, index) => {
        const key = keys?.[index];
        const value = values?.[index];

        return new SelectOptions(
            key ?? value?.toString() ?? '',
            value ?? key ?? '',
        );
    });
}
