import { ResponseRow } from '@hrs-ui/util-definitions';
import { ApiService } from '@hrs-ui/api/util-api';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * A service that handles different CRUD operations for deleting and saving rows.
 * Also, helper functions to compliment save and delete
 */
@Injectable({
    providedIn: 'root',
})
export class CrudService {

    public apiService = inject(ApiService);

    public saveRow(row?: ResponseRow, operationId?: string): Observable<string | null> {

        if (!operationId) {
            throw new Error('UPDATE ROW ERROR: missing operationId');
        }

        if (!row) {
            throw new Error('UPDATE ROW ERROR: missing row');
        }

        // getting update function with operationId
        const updateFn = this.apiService.getFunction<string | null>(operationId);

        const commandsp = row.updatesp;

        if (!commandsp) {
            throw new Error(`UPDATE ROW ERROR: missing updatesp for "${ operationId }"`);
        }

        const updateBody = {
            operationtype: 'update',
            commandsp,
            // aka form.value is just rowData
            data: row.rowData,
        };

        // values only from key/value pair + updateBody
        return updateFn(updateBody);
    }

    /**
     * Deletes one row. Requires a deletesp, which is in the ResponseRow, as well as an operationId
     *
     * @param row to be deleted
     * @param operationId of type string
     * @returns an observable of type string
     */
    public deleteRow(row: ResponseRow, operationId: string): Observable<string | null> {

        if (!operationId) {
            throw new Error('DELETE ROW ERROR: missing operationId');
        }

        // getting update function with operationId
        const updateFn = this.apiService.getFunction<string | null>(operationId);

        const commandsp = row.deletesp;

        if (!commandsp) {
            throw new Error('DELETE ROW ERROR: missing deletesp');
        }

        const updateBody = {
            operationtype: 'delete',
            commandsp,
            // aka form.value is just rowData
            data: row.rowData,
        };

        // values only from key/value pair + updateBody
        return updateFn(updateBody);
    }
}
