import { Pipe, PipeTransform } from '@angular/core';

/**
 * transforms javascript line breaks to textarea or html line breaks for display.
 */
@Pipe({
    name: 'convertLineBreaks',
    standalone: true,
})
export class ConvertLineBreaksPipe implements PipeTransform {
    public transform(value: string, format: 'html' | 'textarea'): string {
        const newLine = 13;
        const lineFeed = 10;

        const lineBreakString = String.fromCharCode(newLine, lineFeed);

        if (format === 'html') {
            return value.replace(/\\n/g, '<br/>');
        } else {
            // in case of format === 'textarea'
            return value.replace(/\\n/g, lineBreakString);
        }

    }
}
