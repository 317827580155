import { Injectable } from '@angular/core';
import { merge, mergeAll, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { adaptNgrx } from '@state-adapt/ngrx';
import { SortOrder } from '../definitions/sort-order';
import { BaseSettingsService } from './base-settings.service';

type TableSort = Record<string, SortedColumn>;

export interface SortedColumn {
    columnName: string;
    sortOrder: SortOrder;
}

interface StateOptions extends SortedColumn {
    tableName: string;
}

@Injectable({
    providedIn: 'root',
})
export class HtTableSortService extends BaseSettingsService<TableSort, StateOptions> {

    constructor() {
        super(
            'tableSort',
            'table-sort',
            '[table-sort] patch data',
            '[table-sort] get data based on org unit',
        );
    }

    protected readonly _state = adaptNgrx({} as TableSort, {
        path: this._statePath,
        adapter: this._adapter,
        sources: {
            set: [this._getAction$, this._patchAction$],
        },
    });

    // We need to provide the state in this point to be able to use it within a computed()
    public readonly state$ = this._state.state$;

    /**
     * set sorted column by tableName
     *
     * @param tableName
     * @param columnName
     * @param sortOrder
     */
    public setSortedColumn(tableName: string, columnName: string, sortOrder: SortOrder): void {
        this._patch$.next({ tableName, columnName, sortOrder });
    }

    protected _updateData$(options: StateOptions, currentState: TableSort): Observable<TableSort> {

        const { tableName, columnName, sortOrder } = options;

        const data: TableSort = {
            ...currentState,
            [tableName]: {
                columnName,
                sortOrder,
            },
        };

        return merge([
            of(data),
            this._setData$(this._orgUnitService.data, data).pipe(
                map(() => data),
                catchError(() => of(data)),
            ),
        ])
            .pipe(mergeAll());
    }
}
