export class SelectOptions {
    public id: string;
    public value: string | number;

    constructor(id: string, value: string | number) {
        this.id = id;
        this.value = value;
    }

    /**
     * Converts the object to a string representation.
     *
     * @returns The string representation of the value.
     */
    public toString(): string {
        return this.value.toString();
    }

    /**
     * Serializes the object to a JSON string representation.
     *
     * @returns The ID of the object.
     */
    public toJSON(): string {
        return this.id;
    }
}
