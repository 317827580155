import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { fileToUrl$ } from '@hrs-ui/util-core';
import { ApiService } from '@hrs-ui/api/util-api';

/**
 * Pref Card Image Service
 */
@Injectable({
    providedIn: 'root',
})
export class PCImageService {
    public redirectUrl = '';

    private readonly _apiService = inject(ApiService);

    public updateImage(pcId: string, imageId: string, file: File): Observable<Blob> {
        return this._apiService.getFunction<Blob>('put_image')(pcId, imageId, file)
            .pipe(
                take(1),
            );
    }

    public deleteImage(pcId: string, imageId: string): Observable<Blob> {
        return this._apiService.getFunction<Blob>('delete_image')(pcId, imageId)
            .pipe(
                take(1),
            );
    }

    public getImage(pcId: string, imageId: string): Observable<File> {
        return this._apiService.getFunction<File>('get_image')({ pcId, imageId })
            .pipe(
                take(1),
            );
    }

    public getImageAsUrl(pcId: string, imageId: string): Observable<string> {
        return this.getImage(pcId, imageId)
            .pipe(
                switchMap(image => {
                    return fileToUrl$(image);
                }),
            );
    }
}
