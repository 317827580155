import {
    ChangeDetectionStrategy,
    Component,
    computed,
    effect,
    ElementRef,
    EventEmitter,
    HostBinding,
    inject,
    Input,
    input,
    Output,
    QueryList,
    Signal,
    signal,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { Observable } from 'rxjs';
import { DataTypes, HtItemType, HtRowData, HtTableColumn, HtTableRow, Settings } from '@hrs-ui/util-definitions';
import { HtTableWidthService } from '../../services/table-width.service';
import { TranslateModule } from '@ngx-translate/core';
import { TableCellMapperComponent } from '../table-cell-mapper/table-cell-mapper.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HtCheckboxComponent } from '@hrs-ui/ui/ui-input';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { SortOrder } from '../../definitions/sort-order';
import { HtTableSortService, SortedColumn } from '../../services/table-sort.service';
import { toSignal } from '@angular/core/rxjs-interop';

export interface HtCellComponent {
    column?: HtTableColumn;
    row?: HtTableRow;
    settings?: Settings;
    tabIndex?: number;
    editModeDisabled?: boolean;
    readonlyClass?: boolean;
    disabled?: boolean;
    defaultFocus?: boolean;
}

const BATCH_SIZE = 100;
const BATCH_DELAY = 300;

@Component({
    selector: 'ht-dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: [
        './dynamic-table.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        HtCheckboxComponent,
        UiIconComponent,
        MatTooltipModule,
        TableCellMapperComponent,
        TranslateModule,
    ],
})
export class HtDynamicTableComponent {
    @ViewChildren('tableHeader')
    public tableHeaders?: QueryList<ElementRef<HTMLTableCellElement>>;

    // For TableComponent to lookup the header row height
    @ViewChild('tableHeaderRow', { static: false })
    public tableHeaderRow?: ElementRef<HTMLTableRowElement>;

    @ViewChildren('tableCell')
    public tableCells?: QueryList<TableCellMapperComponent>;

    @HostBinding('class.half-width')
    @Input()
    public halfWidth?: boolean = false;

    @HostBinding('class.fixed-width')
    @HostBinding('style.min-width.px')
    @Input()
    public width?: string;

    @Input()
    public contentType?: string;

    @Output()
    public readonly checkAllRowsOfColumn = new EventEmitter<{ column: string; shouldBeChecked: boolean }>();

    @Output()
    public readonly cellValueChange = new EventEmitter<{ value: HtItemType; rowId: string; column: string }>();

    public readonly isFrontendSortEnabled = input(false);
    public readonly defaultSort = input<SortedColumn>();

    public readonly tableKey = input<string>('');
    private readonly _tableSortService = inject(HtTableSortService);

    protected _sortOptionsState = toSignal(this._tableSortService.state$);
    protected _sortOptions = computed(() => {
        return this.isFrontendSortEnabled()
            ? this._sortOptionsState()?.[this.tableKey()] ?? this.defaultSort()
            : {
                columnName: undefined,
                sortOrder: undefined,
            };
    });

    public currentSortedColumn = computed(() => this._sortOptions()?.columnName ?? '');
    public sortOrder = computed(() => this._sortOptions()?.sortOrder ?? SortOrder.Asc);

    public readonly rows = input<Array<HtTableRow>>([]);
    public readonly sortedRows = computed(() => this._getSortedRows().slice(0, this._visibleCount()));
    public readonly iconName = computed(() => (this.sortOrder() === 'asc' ? 'arrow-up' : 'arrow-down'));

    public readonly columns = input<Array<HtTableColumn>>([]);
    public readonly rowData = input<Record<string, HtRowData>>({});

    public readonly columnWidths: Signal<Array<Observable<number | undefined>>>;
    public readonly columnMinWidths: Signal<Array<number | undefined>>;
    public readonly rowsCheckedStatusByColumn: Signal<Record<string, boolean>>;

    private _resizeElemX = 0;
    private _resizeElemWidth = 0;
    private _resizeElem?: HTMLTableCellElement;
    private _resizeColumnName?: string;

    protected _visibleCount = signal(BATCH_SIZE);

    private readonly _tableWidthService = inject(HtTableWidthService);

    constructor() {
        effect(() => {
            this.rows();

            setTimeout(() => {
                this._visibleCount.set(BATCH_SIZE);
            }, BATCH_DELAY);
        });

        effect(() => {
            const totalLength = this.rows().length;
            const visibleCount = this._visibleCount();

            if (visibleCount < totalLength) {
                setTimeout(() => {
                    this._visibleCount.set(Math.min(visibleCount + BATCH_SIZE, totalLength));
                }, BATCH_DELAY);
            }
        });

        this.rowsCheckedStatusByColumn = computed(() => {
            const rowData = this.rowData();

            return this.columns()
                .reduce<Record<string, boolean>>((collection, column) => {
                    if (column.type === DataTypes.Boolean && column.grouped) {
                        return {
                            ...collection,
                            [column.name]: !!Object.values(rowData).every(data => !!data[column.name]),
                        };
                    }

                    return collection;
                }, {});
        });

        this.columnWidths = computed(() => {
            const tableKey = this.tableKey();
            const columns = this.columns();

            return (tableKey && columns.length > 0)
                ? columns.map(column => this._tableWidthService.cellWidth$(tableKey, column.name))
                : [];
        });

        this.columnMinWidths = computed(() => {
            const tableKey = this.tableKey();
            const columns = this.columns();

            return (tableKey && columns.length > 0)
                ? columns.map(column => this._tableWidthService.minWidthByType(column.type))
                : [];
        });
    }

    /**
     * resize event
     *
     * @param $event
     * @param elem
     * @param columnName
     */
    public mousedown($event: MouseEvent, elem: HTMLTableCellElement, columnName: string): void {
        this._resizeElem = elem;
        this._resizeElemX = $event.pageX;
        this._resizeElemWidth = elem.offsetWidth;
        this._resizeColumnName = columnName;
    }

    /**
     * resize event
     *
     * @param $event
     */
    public mousemove($event: MouseEvent): void {
        if (this._resizeElem) {
            this._resizeElem.style.width = `${ this._resizeElemWidth + $event.pageX - this._resizeElemX }px`;
        }
    }

    /**
     * resize event
     */
    public mouseup(): void {
        const tableKey = this.tableKey();

        if (this._resizeElem && tableKey && this._resizeColumnName) {
            this._tableWidthService.setCellWidth(this._resizeElem.offsetWidth, tableKey, this._resizeColumnName);
        }

        this._resizeElem = undefined;
    }

    /**
     * React to value changes in any of the cells
     *
     * @param event
     * @param event.value
     * @param event.rowId
     * @param event.column
     */
    public handleCellValueChange({ value, rowId, column }: { value: HtItemType; rowId: string; column: string }): void {
        this.cellValueChange.emit({ value, rowId, column });
    }

    /**
     * Tell the parent table and the children to check all cells of the given boolean column
     *
     * @param columnName
     */
    public triggerCheckAllRowsOfColumn(columnName: string): void {
        const areAllRowsChecked = this.rowsCheckedStatusByColumn()[columnName];

        this.checkAllRowsOfColumn.emit({ column: columnName, shouldBeChecked: !areAllRowsChecked });
    }

    /**
     * click handler to sort column by name
     *
     * @param name
     */
    public sortByColumn(name: string): void {
        if (!this.isFrontendSortEnabled()) {
            return;
        }

        let sortOrder = SortOrder.Asc;

        if (this.currentSortedColumn() === name) {
            sortOrder = this.sortOrder() === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc;
        }

        this._tableSortService.setSortedColumn(this.tableKey(), name, sortOrder);
    }

    public isSortingDefault = computed(() => {
        const defaultSort = this.defaultSort();
        const columnName = this.currentSortedColumn();
        const sortOrder = this.sortOrder();

        return defaultSort?.columnName === columnName && defaultSort.sortOrder === sortOrder;
    });

    public resetToDefaultSorting(): void {
        const { columnName, sortOrder } = this.defaultSort() ?? {};

        if (columnName) {
            this._tableSortService.setSortedColumn(this.tableKey(), columnName, sortOrder ?? SortOrder.Asc);
        }
    }

    protected _getSortedRows(): Array<HtTableRow> {
        // only sort columns if frontend sort is enabled
        if (!this.isFrontendSortEnabled()) {
            return this.rows();
        }

        const orderModifier = this.sortOrder() === SortOrder.Asc ? 1 : -1;
        const name = this.currentSortedColumn();

        return [...this.rows()].sort((a, b) => {
            const aValue = a.rowData[name];
            const bValue = b.rowData[name];

            // `==` was used on purpose here to handle both null and undefined values!
            if (aValue == null && bValue == null) {
                return 0;
            }
            if (aValue == null) {
                return -1 * orderModifier;
            }
            if (bValue == null) {
                return 1 * orderModifier;
            }

            if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
                if (aValue === bValue) {
                    return 0;
                }

                return aValue ? 1 * orderModifier : -1 * orderModifier;
            }

            if (typeof aValue === 'number' && typeof bValue === 'number') {
                return (aValue - bValue) * orderModifier;
            }

            // objects are not sortable
            if (typeof aValue !== 'object' && typeof bValue !== 'object') {
                return aValue.toString().localeCompare(bValue.toString()) * orderModifier;
            }

            return 0;

        });
    }
}
