/**
 * `ErrorMessage` is contained in all the generated libs and looks all the same...
 * copied over here to make sure to import only this!
 */
export interface ErrorMessage {
    code: string;
    name?: string;
    message: string;
}

// Type guard to check if err.error is of type ErrorMessage
/**
 * @returns ErrorMessage
 * @param error should be 'HttpErrorResponse.error'
 */
export function isErrorMessage(error: unknown): error is ErrorMessage {
    // Ensure that `error` is an object before accessing its properties
    if (typeof error === 'object' && error !== null) {
        // Cast to an object with unknown properties
        const errorObj = error as Record<string, unknown>;
        return (
            typeof errorObj['code'] === 'string'
            && typeof errorObj['message'] === 'string'
            && (typeof errorObj['name'] === 'undefined' || typeof errorObj['name'] === 'string')
        );
    }
    return false;
}
