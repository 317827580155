import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, inject } from '@angular/core';
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { noop } from 'rxjs';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { UiInputComponent } from '../ui-input/ui-input.component';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { UiButtonComponent } from '@hrs-ui/ui/ui-button';
import { HtTextComponent } from '../ht-text/ht-text.component';

@Component({
    selector: 'ht-textarea',
    templateUrl: './ht-textarea.component.html',
    styleUrls: ['./ht-textarea.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        UiInputComponent,
        HtTextComponent,
        UiIconComponent,
        MatTooltipModule,
        MatInputModule,
        TranslationModule,
        UiButtonComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: HtTextareaComponent,
        },
    ],
})
export class HtTextareaComponent implements ControlValueAccessor, AfterViewInit {

    @ViewChild('textarea', { static: false }) public textarea?: ElementRef<HTMLElement>;
    @ViewChild('editForm', { static: false }) public form?: NgForm;

    @Input()
    public modalId?: number;

    @Input()
    public editModeDisabled = true;

    @Input()
    public required = false;

    @Input()
    public placeholderText = '';

    @Input()
    public name?: string;

    @Input()
    public tabIndex = -1;

    @Input()
    public onReturnValue?: ($event: string) => void;

    public innerTextValue = '';
    public innerTextDisplayValue = '';

    /**
     * Implemented by Angular via registerOnChange, provides outward binding for (ngModelChange).
     */
    private _onChange: (value: string) => void = noop;

    private readonly _modalService = inject(ModalService);

    public ngAfterViewInit(): void {
        this.resetFocus();
    }

    /**
     * Provides binding for [ngModel].
     *
     * @param value The string to display and edit.
     */
    public writeValue(value: string | null): void {
        if (value !== null) {
            const converted = this._convertLineBreaks(value);

            this.innerTextValue = converted.forTextarea;
            this.innerTextDisplayValue = converted.forHtml;
        } else {
            this.innerTextValue = '';
            this.innerTextDisplayValue = '';
        }
    }

    public registerOnChange(onChange: (value: string) => void): void {
        this._onChange = onChange;
    }

    public registerOnTouched(): void {
        // registerOnTouched is not used.
    }

    public onValueChange($event: string): void {
        this.innerTextValue = $event;
        this._onChange($event);
    }

    public openInModal(): void {
        this._modalService
            .open(HtTextareaComponent, { ...this, onReturnValue: this.onValueChange.bind(this) });
    }

    /**
     * close modal
     */
    public closeModal(): void {
        this._modalService.closeModal(this.modalId);
    }

    /**
     * close modal with the changed value
     */
    public returnValueAndClose(): void {
        this.onReturnValue?.(this.innerTextValue);
        this.closeModal();
    }

    public resetFocus(): void {
        const textAreaElement = this.textarea?.nativeElement;

        if (textAreaElement) {
            textAreaElement.focus({
                preventScroll: true,
            });
        }
    }

    /**
     * Converts javascript line breaks to textarea line breaks for display.
     *
     * @param text as string
     * @returns an object with properties 'forTextarea' and 'forHtml'
     */
    private _convertLineBreaks(text: string): { forTextarea: string; forHtml: string } {
        const newLine = 13;
        const lineFeed = 10;

        const lineBreakString = String.fromCharCode(newLine, lineFeed);

        return {
            forTextarea: text.replace(/\\n/g, lineBreakString),
            forHtml: text.replace(/\\n/g, '<br/>'),
        };
    }
}
