import { Injectable, inject } from '@angular/core';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

const defaultItemsPerPage = 10;

@Injectable({
    providedIn: 'root',
})
export class PaginationConfigService {
    public defaultItemsPerPage = defaultItemsPerPage;

    private readonly _tableItemsPerPageStorageKey: string = 'hrs_table_per_page_map';

    private readonly _route = inject(ActivatedRoute);

    public setItemsPerPage(itemsPerPage: number): void {
        const itemsPerPageMap = this._getItemsPerPageMap();

        this._route.queryParams.pipe(take(1)).subscribe(params => {
            const key = params['operationId'] as string;

            itemsPerPageMap[key] = itemsPerPage;
            window.localStorage.setItem(this._tableItemsPerPageStorageKey, JSON.stringify(itemsPerPageMap));
        });
    }

    public getItemsPerPage(operationId?: string): number {
        const itemsPerPageMap = this._getItemsPerPageMap();

        return operationId ? itemsPerPageMap[operationId] ?? this.defaultItemsPerPage : this.defaultItemsPerPage;
    }

    private _getItemsPerPageMap(): Record<string, number> {
        const storage = window.localStorage.getItem(this._tableItemsPerPageStorageKey);

        return storage ? JSON.parse(storage) as Record<string, number> : {};
    }
}
