import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LoadingService, OrgUnitService, TokenService, TabTitleService } from '@hrs-ui/app-status/domain-app-status';
import { QuickAccessService } from '@hrs-ui/quick-access/domain-quick-access';
import { QuickAccessItem } from '@hrs-ui/quick-access/util-quick-access';
import { RequestDataService, MagicRouterService } from '@hrs-ui/api/domain-api';
import { UrlParserUtil } from '@hrs-ui/util-route';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { SaveQuickAccessComponent, SaveQuickAccessEditComponent } from '@hrs-ui/quick-access/ui-quick-access';

@Component({
    selector: 'ht-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: true,
    imports: [
        UiIconComponent,
        CommonModule,
        DragDropModule,
        TranslationModule,
        SaveQuickAccessComponent,
        SaveQuickAccessEditComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit {
    public readonly cards$: Observable<Array<QuickAccessItem>>;

    public userNameParam: { userName: string };

    private _draggingCard?: QuickAccessItem;

    private readonly _quickAccessService = inject(QuickAccessService);
    private readonly _orgUnitService = inject(OrgUnitService);
    private readonly _magicRouterService = inject(MagicRouterService);
    private readonly _requestDataService = inject(RequestDataService);
    private readonly _tabTitleService = inject(TabTitleService);
    private readonly _loadingService = inject(LoadingService);
    private readonly _tokenService = inject(TokenService);

    constructor() {
        this.userNameParam = {
            userName: this._tokenService.getUserName(),
        };

        this.cards$ = combineLatest([
            this._quickAccessService.data$,
            this._orgUnitService.data$
                .pipe(
                    tap(() => this._loadingService.loadingStatus = true),
                ),
        ])
            .pipe(
                map(([response, data]) => response[data] ?? []),
                tap(() => this._loadingService.loadingStatus = false),
            );
    }

    public ngOnInit(): void {
        this._tabTitleService.setTitleByTranslationKey('content.dashboard.tabTitle');
    }

    /**
     * removes card
     *
     * @param $event
     * @param card
     */
    public deleteCard($event: MouseEvent, card: QuickAccessItem): void {
        $event.stopPropagation();
        this._quickAccessService.removeFromQuickAccess(card);
    }

    /**
     * stop event bubbeling
     *
     * @param $event
     */
    public dragHandleClick($event: MouseEvent): void {
        $event.stopPropagation();
    }

    /**
     * execute something when clicking on a card
     *
     * @param card
     */
    public clickCard(card: QuickAccessItem): void {
        this._magicRouterService.operationId = card.operationId;
        this._requestDataService.updateData(card.requestData);
    }

    /**
     * initiate Drag
     *
     * @param $event
     * @param card
     * @param cardElem
     */
    public dragStart($event: DragEvent, card: QuickAccessItem, cardElem: HTMLElement): void {
        const requestData = UrlParserUtil.parseDataToUrl(card.requestData);
        const url = `${ window.location.origin }/#/content?operationId=${ card.operationId }&requestData=${ requestData }`;

        if ($event.dataTransfer) {
            $event.dataTransfer.setData('text/plain', url || 'about:blank');
        }

        this._draggingCard = card;
        requestAnimationFrame(() => {
            cardElem.classList.add('dragging');
        });
    }

    /**
     * End Drag
     *
     * @param $event
     * @param cardElem
     */
    public dragEnd($event: DragEvent, cardElem: HTMLElement): void {
        $event.preventDefault();
        cardElem.removeAttribute('draggable');
        cardElem.classList.remove('dragging');
    }

    /**
     * enable drag on button click
     *
     * @param $event
     * @param cardElem
     */
    public enableDrag($event: MouseEvent, cardElem: HTMLElement): void {
        cardElem.setAttribute('draggable', 'true');
    }

    /**
     * moves cards on drag
     *
     * @param $event
     * @param card
     */
    public dragEnter($event: DragEvent, card: QuickAccessItem): void {
        $event.preventDefault();

        if (this._draggingCard) {
            this._quickAccessService.moveItemInQuickAccess(this._orgUnitService.data, this._draggingCard, card);
        }
    }

    // public toggleEdit($event: MouseEvent, cardIndex: number): void {
    //     this.cards$
    //     $event.stopPropagation();
    // }
}
