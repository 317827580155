import type { FormElement, DataItem } from '@hrs-ui/util-definitions';

export class CollectHiddenDataFromDataItemsUtil {

    /**
     * Collects and combines filter items with hidden columns
     *
     * @param insertItems Array<DataItem | FormElement>
     * @returns object
     */
    public static collectHiddenData(insertItems: Array<DataItem | FormElement>): object {
        let hiddenDataFromFilterItems: object = {};

        insertItems.forEach(item => {
            if (item.hidden) {
                hiddenDataFromFilterItems = {
                    ...hiddenDataFromFilterItems,
                    [item.name]: item.value,
                };
            }
        });

        return hiddenDataFromFilterItems;
    }
}
