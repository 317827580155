import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppRoutes } from '@hrs-ui/util-route';
import { AuthService } from '@hrs-ui/authentication/domain-authentication';
import { TokenService } from '@hrs-ui/app-status/domain-app-status';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    private readonly _tokenService = inject(TokenService);
    private readonly _authService = inject(AuthService);

    /**
     * checks if a route can activate
     *
     * @param route
     * @param state
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        if (!this._tokenService.isValid()) {
            if (
                ![
                    `/${ AppRoutes.Login }`,
                    `/${ AppRoutes.MFA }`,
                ].includes(state.url)
            ) {
                this._authService.redirectUrl = state.url;

                if (this._authService.userWaitingForMFA) {
                    this._authService.redirectToMFA();
                } else {
                    this._authService.clearUser();
                }

                return of(false);
            }
        }

        return of(true);
    }
}
