import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseCellComponent } from '../base-cell.component';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { HtRowData } from '@hrs-ui/util-definitions';
import { EditableNumberComponent } from '@hrs-ui/ui/ui-input';

@Component({
    selector: 'ht-numeric-cell',
    templateUrl: './numeric-cell.component.html',
    styleUrls: ['../base-cell.component.scss', './numeric-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        EditableNumberComponent,
    ],
})
export class NumericCellComponent extends BaseCellComponent<string> implements HtCellComponent {

    public defaultValue = '';

    public readonly nonNeg: boolean = false;

    protected override _getValue(rowData: HtRowData, column: string): string {
        const value = rowData[column];

        if (value === null || value === undefined || typeof value === 'object') {
            return this.defaultValue;
        }

        return value.toString();
    }

}
