import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Configuration, AuthApi } from '@hrs-ui/apiclient';

type UserAccessToken = AuthApi.UserAccessToken;

@Injectable({
    providedIn: 'root',
})
export class TokenService extends Configuration {
    private readonly _sessionId: string;

    private readonly _accessTokenKey: string;
    private readonly _accessTokenTime: string;
    private readonly _accessTokenRenewalTime: string;
    private readonly _accessOrgUnitId: string;
    private readonly _accessUserId: string;
    private readonly _accessUserName: string;

    private readonly _ssoAccessToken: string = 'hrs_sso';

    constructor() {
        super();

        this._sessionId = sessionStorage['tabID'] ? sessionStorage['tabID'] as string : sessionStorage['tabID'] = uuidv4();

        this._accessTokenKey = `hrs_token-${ this._sessionId }`;
        this._accessTokenTime = `hrs_token_time-${ this._sessionId }`;
        this._accessTokenRenewalTime = `hrs_token_expiry_time-${ this._sessionId }`;
        this._accessOrgUnitId = `hrs_org_unit-${ this._sessionId }`;
        this._accessUserId = `hrs_user-${ this._sessionId }`;
        this._accessUserName = `hrs_user_name-${ this._sessionId }`;
    }

    public getSessionId(): string {
        return this._sessionId;
    }

    public getToken(): string | undefined {
        return window.localStorage.getItem(this._accessTokenKey) ?? undefined;
    }

    public getToken$(): Observable<string | undefined> {
        return of(this.getToken());
    }

    /**
     * Get current username.
     * User id is used as a fallback option.
     *
     * @returns username as string
     */
    public getUserName(): string {
        return window.localStorage.getItem(this._accessUserName)
            ?? window.localStorage.getItem(this._accessUserId) ?? '';
    }

    public getOrgUnit(): string {
        return window.localStorage.getItem(this._accessOrgUnitId) ?? '';
    }

    public setUser(userName: string): void {
        window.localStorage.setItem(this._accessUserId, userName);
    }

    public setToken(token?: UserAccessToken): void {
        if (token) {
            window.localStorage.setItem(this._accessTokenKey, token.token ?? '');
            window.localStorage.setItem(this._accessOrgUnitId, token.orgUnitID ?? '');
            window.localStorage.setItem(this._accessUserName, token.userName ?? '');

            if (token.validUntil) {
                window.localStorage.setItem(this._accessTokenTime, `${ token.validUntil }000`);
            } else {
                window.localStorage.removeItem(this._accessTokenTime);
            }

            if (token.tokenExpiry) {
                window.localStorage.setItem(this._accessTokenRenewalTime, `${ token.tokenExpiry }000`);
            } else {
                window.localStorage.removeItem(this._accessTokenRenewalTime);
            }
        } else {
            window.localStorage.removeItem(this._accessTokenKey);
            window.localStorage.removeItem(this._accessTokenTime);
            window.localStorage.removeItem(this._accessTokenRenewalTime);
            window.localStorage.removeItem(this._accessOrgUnitId);
            window.localStorage.removeItem(this._accessUserId);
            window.localStorage.removeItem(this._accessUserName);
        }
    }

    /**
     * check if Token is valid, otherwise reroute user to login page
     *
     * @returns a boolean
     */
    public isValid(): boolean {
        const tokenTime = window.localStorage.getItem(this._accessTokenTime);

        return !!tokenTime
            && !isNaN(Number(tokenTime))
            && Number(tokenTime) - Date.now() >= 0;
    }

    /**
     * renew tokenTime
     */
    public renewTokenTime(): void {
        const tokenRenewalTime = window.localStorage.getItem(this._accessTokenRenewalTime);

        if (!tokenRenewalTime) {
            return;
        }

        const newTokenTime = parseInt(tokenRenewalTime, 10) + Date.now();

        window.localStorage.setItem(this._accessTokenTime, `${ newTokenTime }`);
    }

    public getAndDestroySsoToken(): UserAccessToken | undefined {
        const ssoToken = window.localStorage.getItem(this._ssoAccessToken);

        window.localStorage.removeItem(this._ssoAccessToken);

        try {
            return ssoToken ? JSON.parse(ssoToken) as UserAccessToken : undefined;
        } catch {
            return undefined;
        }
    }
}
