import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { keyValueToSelectOption } from '../../../utils/key-value-to-select-option-mapper';
import { SelectOptions } from '../../../definitions/select-options';
import { FormsModule } from '@angular/forms';
import { UiInputComponent } from '../../ui-input/ui-input.component';
import { HtTextComponent } from '../../ht-text/ht-text.component';

@Component({
    selector: 'ht-editable-text',
    templateUrl: './editable-text.component.html',
    styleUrls: ['./editable-text.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        UiInputComponent,
        HtTextComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableTextComponent implements OnChanges {

    @Input()
    public textValue = '';

    @Input()
    public editModeDisabled = true;

    @Input()
    public placeholderText = '';

    @Input()
    public values?: Array<string>;

    @Input()
    public keys?: Array<string>;

    /**
     * Decides whether this input gets autofocused when the page loads
     */
    @Input()
    public isDefaultTextEntry = false;

    @Input()
    public tabIndex = -1;

    @Output()
    public readonly valueChange = new EventEmitter<string>();

    public selectValues?: Array<SelectOptions>;

    public get disabledValue(): string {
        const keyIndex = this.keys?.indexOf(this.textValue);
        const value = keyIndex !== undefined && keyIndex !== -1 && this.values?.[keyIndex];

        return value ? value : this.textValue;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ('values' in changes || 'keys' in changes) {
            this.selectValues = keyValueToSelectOption(
                changes['values']?.currentValue as Array<string> | undefined,
                changes['keys']?.currentValue as Array<string> | undefined,
            );
        }
    }

    public onValueChange($event: string): void {
        this.valueChange.emit($event);
    }
}
