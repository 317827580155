import type { Environment } from '../definitions/environment';
import { appName, iconSizes, version, basePath } from './config';

export const environment: Environment = {
    production: true,
    BASE_PATH: basePath,
    VERSION: version,
    APP_NAME: appName,
    ICON_SIZES: iconSizes,
};
