export class KeyValueMapperUtil {

    /**
     * Maps two Arrays into one Object
     *
     * @param keys as array of strings
     * @param values as array of T
     * @returns new Record
     */
    public static mapKeysValues<T>(
        keys: Array<string>,
        values: Array<T>,
    ): Record<string, T | undefined> {
        const result: Record<string, T | undefined> = {};

        keys.forEach((key, i) => result[key] = values[i]);

        return result;
    }
}
