import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationSeverity, Notification } from '@hrs-ui/notification/util-notification';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    public static readonly DEFAULT_LIFETIME: number = 4000;
    public _notifications$: BehaviorSubject<Array<Notification>>
        = new BehaviorSubject<Array<Notification>>([]);

    public idCount = 0;

    public get notifications$(): Observable<Array<Notification>> {
        return this._notifications$.asObservable();
    }

    public push(
        message: string,
        severity: NotificationSeverity = NotificationSeverity.Error,
        lifetime: number = NotificationService.DEFAULT_LIFETIME,
    ): void {
        const id = this.idCount += 1;

        this._notifications$.next(
            [
                { message, severity, id },
                ...this._notifications$.getValue(),
            ],
        );
        setTimeout(() => {
            this.removeNotification(id);
        }, lifetime);
    }

    public removeNotification(id?: number): void {
        const notifications = this._notifications$.getValue();
        const notificationIndex = notifications
            .findIndex(notification => notification.id === id);

        if (notificationIndex !== -1) {
            notifications.splice(notificationIndex, 1);
            this._notifications$.next(notifications);
        }
    }

    public clear(): void {
        this._notifications$.next([]);
    }
}
