import { Injectable, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslationService } from '@hrs-ui/translation/domain-translation';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TabTitleService {
    protected readonly _titleService = inject(Title);
    protected readonly _translationService = inject(TranslationService);

    public setTitle(title: string): void {
        if (title !== '') {
            this._loadAppTitle()
                .subscribe(appTitle => {
                    this._titleService.setTitle(`${ title } - ${ appTitle }`);
            });
        } else {
            this.resetTitle();
        }
    }

    public setTitleByTranslationKey(key: string): void {
        this._translationService.translate$(key)
            .pipe(
                take(1),
            )
            .subscribe(
                title => this.setTitle(title),
            );
    }

    public resetTitle(): void {
        this._loadAppTitle()
            .subscribe(appTitle => this._titleService.setTitle(appTitle));
    }

    private _loadAppTitle(): Observable<string> {
        return this._translationService.translate$('app.title')
            .pipe(
                take(1),
            );
    }
}
