<div class="navbar">
    <div
        class="navbar__menu-backdrop"
        [class.navbar__menu-backdrop--open]="isOpen"
        (click)="toggleDropdown()"
    ></div>

    <a
        class="navbar__home"
        (click)="navigateHome($event)"
    >
        <img
            class="navbar__logo"
            src="assets/images/title-logo.svg"
            alt=""
        />

        <div class="navbar__divider"></div>

        <div class="navbar__app-name">
            {{ 'navbar.productName' | translate }}
        </div>
    </a>

    <div class="navbar__user-wrapper">
        <!--Todo: add profile picture and default prof-->
        <div
            class="user"
            (click)="toggleDropdown()"
        >
            <span class="user__profile-pic">
                <!--TODO: container exists for when we decide to use real profile pictures to swap out default-->
                <ui-icon
                    iconName="user"
                    [iconSize]="20"
                />
            </span>

            <span class="user__text-container">
                <span class="user__name">
                    {{ userName }}
                </span>

                <span class="user__organization">
                    {{ currentOrgUnit$ | async }}
                </span>
            </span>

            <span class="chevron">
                <ui-icon
                    iconName="chevron-up"
                    [class.navbar--open]="isOpen"
                    [iconSize]="16"
                />
            </span>
        </div>

        <div
            class="navbar__dropdown-menu"
            [class.navbar__dropdown-menu--open]="isOpen"
        >
            <div class="dropdown-menu__item">
                <ui-icon
                    iconName="organizations"
                    [iconSize]="16"
                />

                <div class="select-wrapper">
                    <label>{{ 'navbar.selectOrgUnit' | translate }}</label>

                    <mat-select
                        class="navbar-select"
                        tabindex="-1"
                        [panelWidth]="'auto'"
                        [value]="currentOrgUnit$ | async"
                        (selectionChange)="changeOrgUnit($event)"
                    >
                        @for (orgUnit of orgUnits$ | async; track orgUnit.id) {
                            <mat-option [value]="orgUnit.id">
                                {{ orgUnit.value }}
                            </mat-option>
                        }
                    </mat-select>
                </div>
            </div>
            <div class="dropdown-menu__item">
                <ui-icon
                    iconName="world"
                    [iconSize]="16"
                />

                <div class="select-wrapper">
                    <label>{{ 'navbar.selectLanguage' | translate }}</label>

                    <mat-select
                        class="navbar-select"
                        tabindex="-1"
                        [panelWidth]="'auto'"
                        [value]="currentLanguage$ | async"
                        (selectionChange)="changeLanguage($event)"
                    >
                        @for (language of languages$ | async; track language.CultureCode) {
                            <mat-option [value]="language.CultureCode">{{ language.DisplayName }}</mat-option>
                        }
                    </mat-select>
                </div>
            </div>

            <div
                class="dropdown-menu__item"
                (click)="isVisible.set(true)"
            >
                <ui-icon
                    iconName="info"
                    [iconSize]="16"
                />

                {{ 'navbar.license' | translate }}
            </div>

            <div
                class="dropdown-menu__item"
                (click)="logout()"
            >
                <ui-icon
                    iconName="logout"
                    [iconSize]="16"
                />

                {{ 'navbar.logout' | translate }}
            </div>

            <p-dialog
                header="License info"
                appendTo="body"
                [modal]="true"
                [style]="{ width: '80vw' }"
                [(visible)]="isVisible"
            >
                <ht-licensing />
            </p-dialog>

            <div class="dropdown-menu__item version">{{ 'navbar.version' | translate }}: {{ appVersion }}</div>
        </div>
    </div>
</div>
