import { ChangeDetectionStrategy, Component, computed, inject, Input, input, Signal, ViewChild } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { DataItem, DataTypes, HtButtType, HtItemType, HtRowData, ResponseTable } from '@hrs-ui/util-definitions';
import { KeyValueMapperUtil } from '@hrs-ui/util-data-mapping';
import { EditData } from '../../../definitions/edit-data';
import { ApiService } from '@hrs-ui/api/util-api';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { MagicRouterService } from '@hrs-ui/api/domain-api';
import { finalize, tap } from 'rxjs';
import { CollectHiddenDataFromDataItemsUtil } from '@hrs-ui/util-core';
import { ButtonCallbacks, ButtonEventData } from '@hrs-ui/ht-button/util-ht-button';
import { ButtonService } from '@hrs-ui/ht-button/domain-ht-button';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { HtButtonComponent } from '@hrs-ui/ht-button/ui-ht-button';
import { HtInputMapperComponent } from '@hrs-ui/ui/ui-input';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'ht-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss'],
    standalone: true,
    imports: [
        TranslationModule,
        FormsModule,
        HtButtonComponent,
        HtInputMapperComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditComponent {
    @Input() public tableKey = '';
    @Input() public modalId?: number;
    @ViewChild('editForm', { static: true }) public form?: NgForm;

    public readonly itemData$ = input<ResponseTable | undefined>(undefined, { alias: 'itemData' });
    public readonly editData$: Signal<EditData>;

    public editButtonData?: ButtonEventData;
    public deleteButtonData?: ButtonEventData;

    private readonly _apiService = inject(ApiService);
    private readonly _modalService = inject(ModalService);
    private readonly _magicRouterService = inject(MagicRouterService);
    private readonly _buttonService = inject(ButtonService);

    constructor() {
        this.editData$ = computed(() => this._parseResponseTable(this.itemData$()));

        this._buttonService.buttonEvent$
            .pipe(
                takeUntilDestroyed(),
            )
            .subscribe((buttonEvent: ButtonEventData) => {
                this._handleButtonEvent(buttonEvent);
            });
    }

    /**
     * close modal
     *
     * @param modalId
     */
    public closeModal(modalId: number): void {
        this._modalService.closeModal(modalId);
    }

    /**
     * updates the date after buttonService-Event
     *
     * @param buttonData
     * @param buttonCallbacks
     */
    public saveEdit(buttonData: ButtonEventData, buttonCallbacks?: ButtonCallbacks): void {

        console.log('# saveEdit', this.form?.value);

        if (!this.form || !this.tableKey) {
            console.warn('EditError');

            return;
        }

        if (!buttonData.operationId) {
            console.warn('SUBMIT_EDIT_ERROR: operationId missing');

            return;
        }

        const updateFn = this._apiService.getFunction(buttonData.operationId);

        if (!buttonData.parameter) {
            console.warn('SUBMIT_EDIT_ERROR: parameter missing');

            return;
        }

        const { operationtype = 'update', commandsp, ...data } = buttonData.parameter;
        const updatesp = this.editData$().updatesp;
        const deletesp = this.editData$().deletesp;

        const updateBody = {
            operationtype,
            commandsp: (commandsp ?? (operationtype === 'update' ? updatesp : deletesp)) ?? '',
            data: {
                ...data,
                ...this.form.value as Record<string, HtItemType>,
            },
        };

        const callbacks = buttonData.buttonCallbacks ?? buttonCallbacks;

        callbacks?.buttonDisableCallback();

        updateFn(updateBody)
            .pipe(
                finalize(() => { callbacks?.buttonEnableCallback(); }),
                tap(() => {
                    if (this.modalId) {
                        this._modalService.closeModal(this.modalId);
                    }

                    this._magicRouterService.reloadPage();
                }),
            )
            .subscribe();
    }

    /**
     * Turns ResponseTable into EditData
     * Perfect for displaying data as a key / value pair
     * ex. 'DescriptionOfValueType : Value'
     *
     * @param data
     */
    private _parseResponseTable(data?: Partial<ResponseTable>): EditData {
        if (!data) {
            return {
                tableTitle: '',
                items: [],
            };
        }

        const itemValues = data.rows?.[0]?.rowData ?? {};

        return {
            tableTitle: data.tableTitle ?? '',
            items: data.columns
                ? data.columns.reduce<EditData['items']>((items, column) => {
                if (column.id === 'edit_butt') {
                    const value: string | number | boolean | object | Array<string> = itemValues[column.id] ?? [];

                    if (column.values && Array.isArray(value)) {
                        const { operationId, parameter } = KeyValueMapperUtil.mapKeysValues(
                            column.values,
                            value,
                        ) as Partial<ButtonEventData>;

                        this.editButtonData = {
                            buttType: HtButtType.SubmitCrud,
                            operationId,
                            parameter,
                        };
                    }

                    return items;
                } else if (column.id === 'delete_butt') {
                    const value: string | number | boolean | object | Array<string> = itemValues[column.id] ?? [];

                    if (column.values && Array.isArray(value)) {
                        const { operationId, parameter } = KeyValueMapperUtil.mapKeysValues(
                            column.values,
                            value,
                        ) as Partial<ButtonEventData>;

                        this.deleteButtonData = {
                            buttType: HtButtType.SubmitCrud,
                            operationId,
                            parameter,
                        };
                    }

                    return items;
                }

                const item: DataItem = {
                    name: column.id,
                    displayName: column.titletext,
                    type: column.dataType,
                    values: column.values,
                    keys: column.keys,
                    readOnly: column.readOnly,
                    hidden: column.settings?.visible === 'false',
                    maxLength: column.maxLength,
                    settings: column.settings,
                    required: false,
                    value: itemValues[column.id] !== undefined ? itemValues[column.id] : '',
                };

                items.forEach(i => {
                    if (i.type === DataTypes.AjaxArray) {
                        i.filters = CollectHiddenDataFromDataItemsUtil.collectHiddenData(items);
                    }
                });

                return [...items, item];
            }, [])
            : [],
            updatesp: data.rows?.[0]?.updatesp,
            deletesp: data.rows?.[0]?.deletesp,
        };
    }

    /**
     * create filter submit Data
     *
     * @param buttonEvent
     */
    private _getEditSubmitData(buttonEvent: ButtonEventData): HtRowData {
        if (!this.form) {
            return {};
        }

        const formValue = this.form.value as Record<string, HtItemType>;

        console.log('# _getEditSubmitData', formValue);

        const formData = { ...formValue, ...buttonEvent.parameter };

        Object.keys(formData)
            .map(key => {
                if (formData[key] instanceof Date) {
                    const timezoneOffsetMultiplier = 60000;

                    formData[key] = new Date(formData[key].getTime() - (formData[key].getTimezoneOffset() * timezoneOffsetMultiplier))
                        .toISOString();
                }
            });

        return formData;
    }

    /**
     * handle events from the buttons
     *
     * @param buttonEvent
     */
    // eslint-disable-next-line complexity
    private _handleButtonEvent(buttonEvent: ButtonEventData): void {
        if (buttonEvent.table
            && buttonEvent.table.toLowerCase() !== this.tableKey.toLowerCase()
            && buttonEvent.buttType !== HtButtType.SubmitCrud
        ) {
            return;
        }

        switch (buttonEvent.buttType) {
            case HtButtType.SubmitCrud: {
                this.saveEdit({
                    ...(buttonEvent),
                    ...(this.editButtonData ?? {}),
                });
                break;
            }
            case HtButtType.SubmitCrudAll:
            case HtButtType.Submit:
            case HtButtType.Send:
            case HtButtType.SaveMultiple:
            case HtButtType.Reset:
            case HtButtType.RedirectNewTab:
            case HtButtType.Redirect:
            case HtButtType.PagePrevious:
            case HtButtType.PageNext:
            case HtButtType.NavFilter:
            case HtButtType.Menu:
            case HtButtType.Download:
            case HtButtType.Delete:
            case HtButtType.CustomSubmit:
            case HtButtType.CheckboxTrue:
            case HtButtType.CheckboxFalse:
            case HtButtType.Back:
            case HtButtType.Modal:
            default: {
                if (buttonEvent.dataSubject) {
                    buttonEvent.dataSubject.next(this._getEditSubmitData(buttonEvent));
                }
            }
        }
    }
}
