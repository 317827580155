import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment, APP_CONFIG } from '@hrs-ui/config/util-config';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { provideAnimations } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app/app-routing.module';
import { defaultStoreProvider } from '@state-adapt/angular';
import { authInterceptorProvider } from '@hrs-ui/authentication/ui-authentication';
import { contentInterceptorProvider } from '@hrs-ui/api/ui-api';
import { apiConfigProvider } from '@hrs-ui/config/domain-config';
import { apiServiceProvider } from '@hrs-ui/api/domain-apiclient';
import { messageComponentProvider } from '@hrs-ui/modal/ui-modal';
import { contentWidgetComponentProvider } from '@hrs-ui/feature-htrak-shell';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { actionSanitizer, adaptReducer, stateSanitizer } from '@state-adapt/core';
import { bugsnagProvider } from './bugsnag';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';
import type { PrimeNGConfigType } from 'primeng/config/primeng';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            // General
            AppRoutingModule,
            FormsModule,
            TranslationModule.forRoot(),
        ),
        {
            provide: APP_CONFIG,
            useValue: environment,
        },
        contentWidgetComponentProvider,
        messageComponentProvider,
        apiServiceProvider,
        apiConfigProvider,
        contentInterceptorProvider,
        authInterceptorProvider,
        defaultStoreProvider,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        importProvidersFrom(
            StoreModule.forRoot({ adapt: adaptReducer }),
            StoreDevtoolsModule.instrument({
              maxAge: 25,
              logOnly: environment.production,
              actionSanitizer,
              stateSanitizer,
            }),
        ),
        bugsnagProvider,
        provideAnimationsAsync(),
        providePrimeNG({
            theme: {
                preset: Aura as unknown,
                options: {
                    darkModeSelector: false,
                },
            },
        } as PrimeNGConfigType),
    ],
})
    .catch((err: unknown) => console.error(err));
