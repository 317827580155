import { Injectable, Type, inject } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, defaultRequestData } from '@hrs-ui/api/util-api';
import { ButtonClickData } from '@hrs-ui/ht-button/util-ht-button';
import { CONTENT_COMPONENT, MESSAGE_COMPONENT, Modal } from '@hrs-ui/modal/util-modal';
import { ResponseData, ServerResponse } from '@hrs-ui/util-definitions';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private _idCount = 0;
    private readonly _modals$: BehaviorSubject<Array<Modal>> = new BehaviorSubject<Array<Modal>>([]);

    private readonly _apiService = inject(ApiService);
    private readonly _contentComponentType: Type<object> = inject(CONTENT_COMPONENT);
    private readonly _messageComponentType: Type<object> = inject(MESSAGE_COMPONENT);

    public get modals$(): Observable<Array<Modal>> {
        return this._modals$.asObservable();
    }

    public get isModalOpen(): Observable<boolean> {
        return this._modals$.pipe(
            map(modals => modals.length > 0),
        );
    }

    public getModal(id: number): Modal | undefined {
        const modals = this._modals$.getValue();
        const notificationIndex = modals.findIndex(modal => modal.id === id);

        if (notificationIndex !== -1) {
            return modals[notificationIndex];
        } else {
            return undefined;
        }
    }

    public open<T extends object>(component: Type<T>, properties: Partial<T> = {}, buttonData?: ButtonClickData): void {
        const id = this._idCount += 1;

        this._modals$.next([...this._modals$.getValue(), {
            component,
            inject: properties,
            id,
            buttonData,
            loading: false,
        }]);
    }

    public openContent(properties: Partial<object> = {}, buttonData?: ButtonClickData): void {
        this.open(
            this._contentComponentType,
            properties,
            buttonData,
        );
    }

    public closeModal(id?: number, reload = true): void {
        const modals = this._modals$.getValue();
        const notificationIndex = modals.findIndex(modal => modal.id === id);

        if (notificationIndex !== -1) {
            modals.splice(notificationIndex, 1);
            this._modals$.next(modals);

            // casting to "Modal | undefined" necessary since index access can return undefined
            const prevModal = modals[notificationIndex - 1] as Modal | undefined;

            if (reload && prevModal) {
                this.reloadModal(prevModal.id);
            }
        }
    }

    public reloadModal(id: number): void {
        const modals = this._modals$.getValue();
        const notificationIndex = modals.findIndex(modal => modal.id === id);

        if (notificationIndex !== -1) {
            const modal = modals[notificationIndex];
            const button = modal?.buttonData;

            if (!button?.operationId) {
                return;
            }

            const apiFunc = this._apiService.getFunction<ServerResponse>(button.operationId);

            const backPacket = modal?.buttonData?.backPacket;
            const hRSPageRequest = {
                backPacket,
                ...defaultRequestData,
                filters: [
                    button.parameter,
                ],
            };

            if (modal) {
                modal.loading = true;
            }
            firstValueFrom(
                apiFunc(hRSPageRequest),
            )
                .then(pageData => {
                    if (!('items' in pageData)) {
                        return;
                    }

                    this.closeModal(id, false);
                    this.openContent(
                        {
                            pageData: pageData.items[0],
                        },
                        {
                            ...button,
                            backPacket: { ...pageData.PageResponse.backPacket },
                        },
                    );
                });
        }
    }

    public openModal(button: ButtonClickData): void {
        if (!button.operationId) {
            return;
        }

        const modal = button.modalId && this.getModal(button.modalId);
        const backPacket = modal && modal.buttonData?.backPacket;

        const apiFunc = this._apiService.getFunction<ServerResponse | undefined>(button.operationId);

        const hRSPageRequest = {
            backPacket,
            ...defaultRequestData,
            filters: [
                button.parameter,
            ],
        };

        firstValueFrom(
            apiFunc(hRSPageRequest),
        )
            .then(pageData => {
                if (!pageData || !('items' in pageData)) {
                    console.error('No PageData');

                    return;
                }

                this.openContent(
                    {
                        pageData: pageData.items[0],
                    },
                    {
                        ...button,
                        backPacket: { ...pageData.PageResponse.backPacket },
                    },
                );
            });
    }

    public openMessage(responseData: ResponseData): void {
        this.open(this._messageComponentType, { responseData });
    }
}
