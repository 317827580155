import { InjectionToken } from '@angular/core';

export const LICENSING_PATH_CONFIG_TOKEN = new InjectionToken<LicensingPathConfig>('config');

export interface LicensingPathConfig {
    assetPath?: string;
    licensingFolder?: string;
    licenseTextPath?: string;
    internalLicenseTextPath?: string;
    licensesJson?: string;
    internalLicensesJson?: string;
}
