import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataTypes } from '@hrs-ui/util-definitions';
import { adaptNgrx } from '@state-adapt/ngrx';
import { BaseSettingsService } from './base-settings.service';

type TableWidth = Record<string, Record<string, number>>;

interface StateOptions {
    tableName: string;
    columnName: string;
    cellWidth: number;
}

@Injectable({
    providedIn: 'root',
})
export class HtTableWidthService extends BaseSettingsService<TableWidth, StateOptions> {

    /**
     * Column width values
     */
    protected readonly _defaultColumnWidth = 120;
    protected readonly _minWidthProperty = 120;
    protected readonly _minWidthDateProperty = 180;

    constructor() {
        super(
            'tableWidth',
            'table-width',
            '[table-width] patch data',
            '[table-width] get data based on org unit',
        );
    }

    protected readonly _state = adaptNgrx({} as TableWidth, {
        path: this._statePath,
        adapter: this._adapter,
        sources: {
            set: [this._getAction$, this._patchAction$],
        },
    });

    /**
     * return cellWidth
     *
     * @param tableName
     * @param columnName
     */
    public cellWidth$(
        tableName: string,
        columnName: string,
    ): Observable<number | undefined> {
        return this._state.state$.pipe(
            map(width => width[tableName]?.[columnName] ?? this._defaultColumnWidth),
        );
    }

    /**
     * setCellWidth
     *
     * @param cellWidth
     * @param tableName
     * @param columnName
     */
    public setCellWidth(cellWidth: number, tableName: string, columnName: string): void {
        this._patch$.next({ tableName, columnName, cellWidth });
    }

    /**
     * minWidthByType
     *
     * @param type
     */
    public minWidthByType(type: DataTypes): number | undefined {
        if (type === DataTypes.DateTime || type === DataTypes.Date) {
            return this._minWidthDateProperty;
        } else {
            return this._minWidthProperty;
        }
    }

    protected _updateData$(options: StateOptions, currentState: TableWidth): Observable<TableWidth> {

        const { tableName, columnName, cellWidth } = options;

        const data: TableWidth = {
            ...currentState,
            [tableName]: {
                ...currentState[tableName],
                [columnName]: cellWidth,
            },
        };

        return this._setData$(this._orgUnitService.data, data)
            .pipe(
                map(() => data),
            );
    }

}
