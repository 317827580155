import { ChangeDetectionStrategy, Component, Signal, computed, inject } from '@angular/core';
import { BaseCellComponent } from '../base-cell.component';
import { KeyValueMapperUtil } from '@hrs-ui/util-data-mapping';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { ButtonClickData } from '@hrs-ui/ht-button/util-ht-button';
import { ButtonService } from '@hrs-ui/ht-button/domain-ht-button';
import { HtButtType, ButtonName, HtTableRow, HtTableColumn } from '@hrs-ui/util-definitions';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { ConfirmationComponent } from '@hrs-ui/modal/ui-modal';

@Component({
    selector: 'ht-button-cell',
    templateUrl: './button-cell.component.html',
    styleUrls: ['../base-cell.component.scss', './button-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        UiIconComponent,
    ],
})
export class ButtonCellComponent extends BaseCellComponent implements HtCellComponent {
    public defaultValue = '';

    public readonly iconName$: Signal<string>;
    public readonly buttonText$: Signal<string>;

    private readonly _buttonService = inject(ButtonService);
    protected readonly _modalService = inject(ModalService);

    constructor() {
        super();

        this.iconName$ = computed(() => this._buttonService.getIconName(this.column$().name as ButtonName));

        this.buttonText$ = computed(() => {
            const row = this.row$();
            const column = this.column$();

            const parameter = KeyValueMapperUtil
                .mapKeysValues(column.values ?? [], row.rowData[column.name] as Array<string>);

            return parameter['buttontext'] ?? '';
        });
    }

    public click(): void {
        const confirmation = this.column$().buttonConfirmation;

        if (confirmation) {
            return this._modalService.open(ConfirmationComponent, {
                message: confirmation.message,
                acceptLabel: confirmation.acceptLabel,
                acceptCallback: () => this._executeButtonClick(),
                rejectLabel: confirmation.rejectLabel,
            });
        } else {
            // Execute button click anyway if there is no confirmation needed
            this._executeButtonClick();
        }
    }

    protected _executeButtonClick(): void {
        const buttonEvent = this._generateButtonClickData(this.row$(), this.column$());

        this._buttonService.buttonClick(buttonEvent);
    }

    private _generateButtonClickData(row: HtTableRow, column: HtTableColumn): ButtonClickData {
        const {
            operationId,
            postoperationId,
            filename,
            ...parameter
        } = KeyValueMapperUtil
            .mapKeysValues(column.values ?? [], row.rowData[column.name] as Array<string>);

        return {
            id: this.column$().name as ButtonName,
            buttType: column.buttType ?? HtButtType.Redirect,
            rowData: row.rowData,
            parameter,
            operationId,
            postoperationId,
            filename,
        };
    }
}
