import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent, OTPComponent, AuthGuard, MFAGuard, SSOGuard } from '@hrs-ui/authentication/ui-authentication';
import { DefaultComponent, DashboardComponent, ContentWrapperComponent } from '@hrs-ui/feature-htrak-shell';
import { AppRoutes } from '@hrs-ui/util-route';

const routes: Routes = [
    {
        path: AppRoutes.Login,
        canActivate: [SSOGuard],
        component: LoginComponent,
    },
    {
        path: AppRoutes.MFA,
        canActivate: [MFAGuard],
        component: OTPComponent,
    },
    {
        path: AppRoutes.Default,
        component: DefaultComponent,
        canActivate: [SSOGuard, AuthGuard],
        children: [
            {
                path: AppRoutes.Dashboard,
                component: DashboardComponent,
            },
            {
                path: AppRoutes.Content,
                component: ContentWrapperComponent,
            },
            {
                path: '**',
                redirectTo: AppRoutes.Dashboard,
            },
        ],
    },

];

@NgModule({
    imports: [
        RouterModule.forRoot(
            routes,
            { useHash: true },
),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
