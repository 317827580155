import type { ColumnConfig, HtTableColumn, ResponseRow } from '@hrs-ui/util-definitions';

/**
 * Util functions that help deal with our Settings, that affect columns, tables, buttons and rows.
 */
export class SettingsUtil {

    /**
     * Used in table cells to get any disabled setting specified in this row.
     *
     * @param currentRow The current row you want to check
     * @param columnName Name as string
     * @returns String list of only those settings that are disabled.
     */
    public static isCellDisabled(
        currentRow: ResponseRow,
        columnName: string,
    ): boolean {
        return !!(
            columnName
            && currentRow.settings?.cells?.[columnName]?.enabled === 'false'
        );
    }

    /**
     * Used in table cells to get any hidden setting specified in this row.
     *
     * @param currentRow The current row you want to check
     * @param column The column config
     * @returns String list of those settings that are hidden.
     */
    public static isCellHidden(
        currentRow: ResponseRow,
        column: ColumnConfig & {
            values?: Array<string>;
            keys?: Array<string>;
            readOnly?: boolean;
            maxLength?: number;
            editModeDisabled?: boolean;
        },
    ): boolean {
        return !(
            !currentRow.settings?.cells
            || !(
                column.name
                // TODO: @reviewer: don't know why typescript is not handling this correctly...
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                && (currentRow.settings.cells[column.name]?.visible) === 'false'
            )
        );
    }

    /**
     * Retrieve color for table row or cell.
     *
     * Row color settings are overwritten by cell colors, since they are more specific.
     *
     * @param row The current row
     * @param column The current column
     * @returns The table color as string
     */
    public static getTableColor(row: ResponseRow, column: HtTableColumn): string | undefined {
        let color = this.getTableRowColor(row);

        const rowSettingsColor = row.settings?.cells?.[column.name]?.colour;

        if (rowSettingsColor) {
            color = rowSettingsColor;
        }

        return color;
    }

    /**
     * Retrieve color defined for table row.
     *
     * @param row The current row
     * @returns The table row color as string
     */
    public static getTableRowColor(row: ResponseRow): string | undefined {
        return row.settings?.row?.['colour'];
    }
}
