import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthApi } from '@hrs-ui/apiclient';
import { ApiService } from '@hrs-ui/api/util-api';
import { TokenService } from './token.service';

type UserAccessToken = AuthApi.UserAccessToken;

interface ApiOrgUnit {
    OrgUnitID: string;
    OrgUnitName: string;
}

interface OrgUnitResponse {
    items: Array<ApiOrgUnit>;
}

/**
 * The org unit is the hospital or organization using this software.
 * One account can choose multiple org units but can only choose one at a time.
 * The org unit you use can completely alter what sidebar menu items and content that you can see
 */
@Injectable({
    providedIn: 'root',
})
export class OrgUnitService {
    private readonly _data$: BehaviorSubject<string>;
    private readonly _changeOrgUnitOperationId: string = 'auth_change_orgunit';
    private readonly _getOrgUnitOperationId: string = 'auth_orgunits';

    private readonly _apiService = inject(ApiService);
    private readonly _tokenService = inject(TokenService);

    constructor() {
        this._data$ = new BehaviorSubject<string>(this._tokenService.getOrgUnit());
    }

    public get data$(): Observable<string> {
        return this._data$.asObservable();
    }

    public get data(): string {
        return this._data$.getValue();
    }

    public set data(orgUnitId: string) {
        this._data$.next(orgUnitId);
    }

    public getAvailableOrgUnits(): Observable<Array<{ id: string; value: string }>> {
        return this._apiService.executeOperationId<OrgUnitResponse>(this._getOrgUnitOperationId)
            .pipe(
                map(orgUnits => orgUnits.items.map((orgUnit: {
                    OrgUnitID: string;
                    OrgUnitName: string;
                }) => (
                    { id: orgUnit.OrgUnitID, value: orgUnit.OrgUnitName }
                ))),
            );
    }

    public changeOrgUnit$(orgUnitId: string): Observable<UserAccessToken> {
        return this._apiService.getFunction<UserAccessToken>(this._changeOrgUnitOperationId)(orgUnitId)
            .pipe(
                tap(token => {
                    this._data$.next(token.orgUnitID ?? orgUnitId);
                    this._tokenService.setToken(token);
                }),
            );
    }

}
