import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageData } from '@hrs-ui/util-definitions';
import { QuickAccessItem } from '@hrs-ui/quick-access/util-quick-access';
import { OrgUnitService } from '@hrs-ui/app-status/domain-app-status';
import { QuickAccessService } from '@hrs-ui/quick-access/domain-quick-access';
import { RequestDataService } from '@hrs-ui/api/domain-api';
import { SaveQuickAccessEditComponent } from '../quick-access-edit/save-quick-access-edit.component';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { HtButtonComponent } from '@hrs-ui/ht-button/ui-ht-button';

@Component({
    selector: 'ht-save-quick-access',
    templateUrl: './save-quick-access.component.html',
    styleUrls: ['./save-quick-access.component.scss'],
    standalone: true,
    imports: [
        SaveQuickAccessEditComponent,
        UiIconComponent,
        TranslationModule,
        HtButtonComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveQuickAccessComponent implements OnChanges {
    @Input()
    public isFavorite?: boolean | null;

    @Input()
    public pageData?: PageData;

    @Input()
    public quickAccessItem?: QuickAccessItem;

    public isOpen = false;

    private readonly _quickAccessService = inject(QuickAccessService);
    private readonly _orgUnitService = inject(OrgUnitService);
    private readonly _requestDataService = inject(RequestDataService);
    private readonly _route = inject(ActivatedRoute);

    public ngOnChanges(): void {
        const opId = this._route.snapshot.queryParams['operationId'] as string;

        this.quickAccessItem = {
            orgUnitId: this._orgUnitService.data,
            title: this.pageData?.pageTitle ?? '',
            iconName: 'favorite',
            content: '',
            isOpen: false,
            requestData: this._requestDataService.data,
            operationId: opId,
        };
    }

    /**
     * Toggles the whether the save-quick-access-edit component should be open.
     *
     * @param forcedState
     */
    public toggleQuickAccessIcon(forcedState?: boolean): void {
        if (this.isFavorite && !forcedState) {
            this._quickAccessService.toggleFavorite({
                orgUnitId: this._orgUnitService.data,
                title: this.pageData?.pageTitle ?? '',
                iconName: 'favorite',
                content: '',
                isOpen: false,
                requestData: this._requestDataService.data,
                operationId: this._route.snapshot.queryParams['operationId'] as string,
            });

            return;
        }

        this.isOpen = !forcedState ? !this.isOpen : forcedState;
    }
}
