import Bugsnag from '@bugsnag/js';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { ErrorHandler } from '@angular/core';
import { environment } from '@hrs-ui/config/util-config';

Bugsnag.start({
    apiKey: '062c45f40fb04c2c1e7a14c5a064ce13',
    appVersion: environment.VERSION,
    releaseStage: environment.production ? 'production' : 'development',
    enabledReleaseStages: ['production'],
});
BugsnagPerformance.start({ apiKey: '062c45f40fb04c2c1e7a14c5a064ce13' });

/**
 *
 */
export function errorHandlerFactory(): BugsnagErrorHandler {
    return new BugsnagErrorHandler();
}

export const bugsnagProvider = { provide: ErrorHandler, useFactory: errorHandlerFactory };
