@if (sidebarUiState$ | async; as state) {
    @if (state.isCollapsed) {
        <ui-icon
            class="sidebar-button sidebar-button--collapsed"
            [iconName]="'maximize'"
            [iconSize]="20"
            (click)="openSidebar()"
        />
    }

    <div
        class="sidebar sidebar--resizable"
        mwlResizable
        [enableGhostResize]="true"
        [style.width.px]="state.width"
        [class.sidebar--collapsed]="state.isCollapsed"
        (resizeEnd)="onResizeEnd($event)"
        (resizing)="onResizeEnd($event)"
    >
        @if (!state.isCollapsed) {
            <ui-icon
                class="sidebar-button"
                [iconName]="'minimize'"
                [iconSize]="20"
                (click)="closeSidebar()"
            />
        }

        <div
            class="resize-handle-right"
            mwlResizeHandle
            [resizeEdges]="{ right: true }"
        ></div>

        @if (menuData$ | async; as menuData) {
            @for (data of menuData; track $index) {
                @if (data.asSidebarItem) {
                    <ht-sidebar-menu-item
                        class="sidebar__item"
                        [menuItem]="data.asSidebarItem"
                    />
                }

                @if (data.asLinkItem) {
                    <ht-sidebar-link-item
                        class="sidebar__item"
                        [linkItem]="data.asLinkItem"
                    />
                }
            }
        } @else {
            <ht-sidebar-loading />
        }
    </div>
}
