<div class="controls">
    <a
        *ngIf="!isSortingDefault() && this.isFrontendSortEnabled()"
        (click)="resetToDefaultSorting()"
    >
        <ui-icon
            class="icon"
            iconName="reset"
            [iconSize]="14"
        />
        {{ 'content.table.sort.reset' | translate }}
    </a>
</div>

<div class="container">
    <table
        class="ht-dynamic-table"
        (document:mousemove)="mousemove($event)"
        (document:mouseup)="mouseup()"
    >
        <tbody>
            <tr
                #tableHeaderRow
                class="ht-dynamic-table-header-row"
            >
                @for (column of columns(); track column.columnUId) {
                    @if (tableKey(); as tablekey) {
                        <th
                            #tableHeader
                            class="ht-dynamic-table-header-cell"
                            [style.textAlign]="column.headerAlign"
                            [style.width.px]="columnWidths()[$index] | async"
                            [style.minWidth.px]="columnMinWidths()[$index]"
                            [attr.data-name]="column.name"
                            [class.is-sorted]="currentSortedColumn() === column.name"
                            [class.is-sortable]="isFrontendSortEnabled()"
                        >
                            <span
                                class="ht-dynamic-table-header-cell__display-name"
                                (click)="sortByColumn(column.name)"
                            >
                                @if (!column.readOnly && column.type === 'boolean' && column.grouped) {
                                    <form #form="ngForm">
                                        <ht-checkbox
                                            name="select-all"
                                            [value]="rowsCheckedStatusByColumn()[column.name] ?? false"
                                            (valueChange)="triggerCheckAllRowsOfColumn(column.name)"
                                        />
                                    </form>
                                }

                                @if (currentSortedColumn() === column.name) {
                                    <ui-icon
                                        [class]="'icon'"
                                        [iconName]="iconName()"
                                        [iconSize]="16"
                                    />
                                }

                                {{ column.displayName | translate }}

                                @if (column.hint) {
                                    <ui-icon
                                        [iconName]="'info'"
                                        [iconSize]="16"
                                        [matTooltip]="column.hint"
                                    />
                                }
                            </span>

                            <div
                                class="resize-trigger"
                                (mousedown)="mousedown($event, tableHeader, column.name)"
                            ></div>
                        </th>
                    }
                }
            </tr>

            @for (row of sortedRows(); track row.rowUId) {
                <tr class="ht-dynamic-table-table-row">
                    @for (column of columns(); track column.columnUId) {
                        <ht-table-cell-mapper
                            class="ht-table-cell ht-dynamic-table-cell"
                            [row]="row"
                            [rowData]="rowData()[row.rowUId] ?? {}"
                            [column]="column"
                            (valueChange)="handleCellValueChange($event)"
                        />
                    }
                </tr>
            }
        </tbody>
    </table>
</div>
