@if (iconSize(); as iconSize) {
    @if (iconName(); as iconName) {
        @if (iconData(); as data) {
            <div
                [style.width.px]="iconSize"
                [style.height.px]="iconSize"
                [innerHtml]="data"
            ></div>
        }
    }
}
