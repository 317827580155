import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { defaultRequestData, RequestData } from '@hrs-ui/api/util-api';
import { ActivatedRoute } from '@angular/router';
import { UrlParserUtil } from '@hrs-ui/util-route';
import { skip } from 'rxjs/operators';
import { PaginationConfigService } from '@hrs-ui/config/domain-config';

@Injectable({
    providedIn: 'root',
})
export class RequestDataService {

    private readonly _data$: BehaviorSubject<RequestData>;
    private readonly _queryParam$: BehaviorSubject<RequestData>;

    private readonly _requestData: RequestData = { ...defaultRequestData };

    private readonly _route = inject(ActivatedRoute);
    private readonly _paginationConfigService = inject(PaginationConfigService);

    constructor() {
        this._data$ = new BehaviorSubject<RequestData>(this._requestData);
        this._queryParam$ = new BehaviorSubject(this._requestData);

        this._requestData.pageRequest = {
            ...this._requestData.pageRequest,
            ...{ perPage: this._paginationConfigService.defaultItemsPerPage },
        };
        this._data$.next(this._requestData);
        this._queryParam$.next(this._requestData);

        this._route.queryParams
            .pipe(
                // otherwise gets triggered before routing finished and therefore no data
                skip(1),
            )
            .subscribe(params => {
                const operationId = params['operationId'] as string;
                const itemsPerPage = this._paginationConfigService.getItemsPerPage(operationId);

                const requestData = params['requestData']
                    ? (UrlParserUtil.parseUrlToData(params['requestData'] as string) as RequestData)
                    : this._requestData;

                if (requestData.pageRequest) {
                    requestData.pageRequest.perPage = itemsPerPage;
                }

                this._data$.next(requestData);
            });
    }

    public get data(): RequestData {
        return this._data$.getValue();
    }

    public set data(_data: RequestData) {
        if (_data.pageRequest?.perPage) {
            this._paginationConfigService.setItemsPerPage(_data.pageRequest.perPage);
            this._requestData.pageRequest = {
                ...this._requestData.pageRequest,
                ...{ perPage: _data.pageRequest.perPage },
            };
        }

        this._queryParam$.next({ ...this._requestData, ..._data });
    }

    public get queryParam$(): Observable<RequestData> {
        return this._queryParam$.asObservable();
    }

    public get data$(): Observable<RequestData> {
        return this._data$.asObservable();
    }

    /**
     * sets Request Data to initial Values
     */
    public resetData(): void {
        this.data = {};
    }

    /**
     * updates RequestData with partial data
     * use-cases: tables, sidebar content, favorites in dashboard, buttons
     *
     * @param partialRequestData of type Partial<RequestData>
     */
    public updateData(partialRequestData: Partial<RequestData>): void {
        this.data = {
            ...this._data$.getValue(),
            ...partialRequestData,
        };
    }
}
