import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ButtonBase } from '../../definitions/button-base';
import {
    AppTheming,
    CanDisable,
    CanDisableConstructor,
    CanSetProcessingConstructor,
    CanTheme,
    CanThemeConstructor,
    mixinDisabled,
    mixinProcessing,
    mixinTheme,
} from '@hrs-ui/util-component-support';

import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

const _ButtonMixinBase: CanThemeConstructor & CanDisableConstructor & CanSetProcessingConstructor & typeof ButtonBase
    = mixinTheme(mixinDisabled(mixinProcessing(ButtonBase)));

/**
 * Represents a button
 */
@Component({
    selector: 'ui-button',
    templateUrl: './ui-button.component.html',
    styleUrls: ['./ui-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        UiIconComponent,
    ],
})
export class UiButtonComponent extends _ButtonMixinBase implements CanTheme, CanDisable {

    /**
     * The button styling.
     */
    @Input()
    public override theme = AppTheming.Secondary;

    /**
     * Whether the button is disabled.
     */
    @Input()
    public override disabled = false;

    /**
     * Whether the button should show as processing.
     * A processing button is also disabled.
     */
    @Input()
    public override processing: boolean | undefined | null = false;

    /**
     * The button label.
     */
    @Input()
    public label = '';

    /**
     * The button type.
     */
    @Input()
    public type = 'button';

    /**
     * The button icon.
     */
    @Input()
    public icon?: string;

    /**
     * The button icon size.
     */
    @Input()
    public iconSize?: number;

    /**
     * Whether to display the icon on the left side of the label instead.
     */
    @Input()
    public hasIconLeft = false;

    /**
     * Enables tab navigation on buttons.
     */
    @Input()
    public tabIndex = 0;

    /**
     * Whether to display the icon with an outlined style.
     */
    @Input()
    public isOutlined = false;
}
