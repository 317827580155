import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { BaseCellComponent } from '../base-cell.component';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { HtImageComponent } from '@hrs-ui/ui/ui-presentation';
import { Observable } from 'rxjs';
import { PCImageService } from '@hrs-ui/api/domain-api';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

@Component({
    selector: 'ht-image-link-cell',
    templateUrl: './image-link-cell.component.html',
    styleUrls: ['./image-link-cell.component.scss', '../base-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        UiIconComponent,
    ],
})
export class ImageLinkCellComponent extends BaseCellComponent implements HtCellComponent {

    public defaultValue = '';

    public imageUrl$?: Observable<string>;

    private readonly _modalService = inject(ModalService);
    private readonly _pcImageService = inject(PCImageService);

    constructor() {
        super();

        effect(() => {
            const row = this.row$();

            const imageId = row.rowData['pcimagelink'] as string;
            const itemId = row.rowData['id'] as string;

            if (imageId && itemId) {
                this.imageUrl$ = this._pcImageService.getImageAsUrl(itemId, imageId);
            } else {
                this.imageUrl$ = undefined;
            }
        });
    }

    /**
     * show image in Modal
     */
    public showImage(): void {
        if (!this.imageUrl$) {
            return;
        }

        this._modalService
            .open(HtImageComponent, {
                imageUrl$: this.imageUrl$,
            });
    }

}
