import { Injectable } from '@angular/core';
import { SidebarContentModel } from '../definitions/sidebar-content.model';
import { SidebarNode } from '../definitions/sidebar-node';

/**
 * Type guard
 */
@Injectable({
    providedIn: 'root',
})
export class SidebarContentTypeGuard {

    public isSidebarContent = (f: SidebarNode): f is SidebarContentModel => {
        // Check if 'name', 'submenus', and 'open' properties exist, as they are unique to SidebarContentModel
        return 'name' in f && 'submenus' in f && 'open' in f;
    };
}
