import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PageData } from '@hrs-ui/util-definitions';
import { RequestDataService, MagicRouterService } from '@hrs-ui/api/domain-api';
import { QuickAccessService } from '@hrs-ui/quick-access/domain-quick-access';
import { OrgUnitService, TabTitleService } from '@hrs-ui/app-status/domain-app-status';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { SaveQuickAccessComponent } from '@hrs-ui/quick-access/ui-quick-access';
import { ContentWidgetComponent } from '../../components/content-widget/content-widget.component';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';

@Component({
    selector: 'ht-content-wrapper',
    templateUrl: './content-wrapper.component.html',
    styleUrls: ['./content-wrapper.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        SaveQuickAccessComponent,
        ContentWidgetComponent,
        UiIconComponent,
        TranslationModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentWrapperComponent {
    public readonly pageData$: Observable<PageData | undefined>;
    public readonly isFavorite$: Observable<boolean>;

    private readonly _tabTitleService = inject(TabTitleService);
    private readonly _route = inject(ActivatedRoute);
    private readonly _magicRouterService = inject(MagicRouterService);
    private readonly _orgUnitService = inject(OrgUnitService);
    private readonly _quickAccessService = inject(QuickAccessService);
    private readonly _requestDataService = inject(RequestDataService);

    constructor() {
        // Sets the pageData from the top level and this flows into all of the smaller components
        this.pageData$ = this._magicRouterService.pageData$;

        this.isFavorite$
            = this.pageData$
                .pipe(
                    // isFavorite$ is only observed in the template if pageData.pageTitle exists.
                    // This filter ensures that the pipe operates under the same circumstance.
                    filter((pageData): pageData is PageData & { pageTitle: string } => pageData?.pageTitle !== undefined),
                    switchMap(pageData => this._quickAccessService.isFavorite$({
                        orgUnitId: this._orgUnitService.data,
                        title: pageData.pageTitle,
                        iconName: 'favorite',
                        content: '',
                        requestData: this._requestDataService.data,
                        operationId: this._route.snapshot.queryParams['operationId'] as string,
                        isOpen: false,
                    })),
                );

        this.pageData$
            .pipe(
                takeUntilDestroyed(),
            )
            .subscribe(pageData => {
                if (pageData?.pageTitle) {
                    this._tabTitleService.setTitle(pageData.pageTitle);
                } else {
                    this._tabTitleService.resetTitle();
                }
            });
    }
}
