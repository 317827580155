import type { HtItemType, HtRowData, HtTableRow, ResponseRow } from '@hrs-ui/util-definitions';

/**
 * Whenever a row is changed, we need to manually pass those changes to the API.
 * For this reason, we have some helper functions that can look through the current rows and collect them
 * for evaluation from the backend.
 */
export class RowCollectorUtil {

    /**
     * Get all rows and join any changes
     *
     * @param rows original HtTableRow as Array
     * @param changes as Record
     * @returns All rows, with the changes in the rowData
     */
    public static joinRowsWithChanges(rows: Array<HtTableRow>, changes: Record<string, HtRowData>): Array<HtTableRow> {
        return rows
            .map(row => ({
                ...row,
                rowData: { ...changes[row.rowUId] ?? row.rowData },
                hasChanges: !!changes[row.rowUId],
            }));
    }

    /**
     * Filter rows down to only changed rows.
     *
     * @param rows as Array of all rows
     * @returns an array of all changed rows
     */
    public static collectChangedRows<T extends ResponseRow>(rows: Array<T>): Array<T> {
        return rows.filter(row => row.hasChanges);
    }

    /**
     * Save a new value in the specified column of a row.
     * Marks the row as changed.
     *
     * @param newValue as HtItemType
     * @param row The row to change
     * @param column The identifier for the column
     *
     * @returns the row with the new value saved
     */
    public static applyChangedValue<T extends ResponseRow>(
        newValue: HtItemType,
        row: T,
        column: string,
    ): T {
        if (column && row.rowData[column] !== newValue) {
            row.rowData[column] = newValue;
            row.hasChanges = true;
        }

        return row;
    }
}
