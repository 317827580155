import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    inject,
} from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { DataTypes, ResponseTable } from '@hrs-ui/util-definitions';
import { KeyValueMapperUtil } from '@hrs-ui/util-data-mapping';
import { ApiService } from '@hrs-ui/api/util-api';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { InsertData } from '../../../definitions/insert-data';
import { MagicRouterService } from '@hrs-ui/api/domain-api';
import { CollectHiddenDataFromDataItemsUtil } from '@hrs-ui/util-core';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { UiButtonComponent } from '@hrs-ui/ui/ui-button';
import { HtInputMapperComponent } from '@hrs-ui/ui/ui-input';
import { ButtonMapperComponent } from '../../button-mapper/button-mapper.component';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'ht-insert',
    templateUrl: './insert.component.html',
    styleUrls: ['./insert.component.scss'],
    standalone: true,
    imports: [
        TranslationModule,
        FormsModule,
        ButtonMapperComponent,
        UiButtonComponent,
        HtInputMapperComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertComponent implements OnChanges {
    @Input() public tableKey?: string;
    @Input() public itemData?: ResponseTable;
    @Input() public modalId?: number;
    @Input() public openInModal = false;
    @ViewChild('insertForm', { static: false }) public form?: NgForm;
    @ViewChild('wrapper', { static: false }) public wrapper?: ElementRef<HTMLElement>;

    public insertData?: InsertData;
    public insertButtonData?: Record<string, string>;

    private readonly _apiService = inject(ApiService);
    private readonly _modalService = inject(ModalService);
    private readonly _magicRouterService = inject(MagicRouterService);

    /**
     * update itemData on change
     *
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const itemData = changes['itemData'];

        if (itemData?.currentValue) {
            this.insertData = this.parseResponseTable(itemData.currentValue as ResponseTable);
        }
    }

    /**
     * save the form-data
     */
    public saveInsert(): void {

        if (!this.form || !this.insertButtonData) {
            console.warn('InsertError');

            return;
        }

        // getting correct update function
        const { operationId, ...buttonData } = this.insertButtonData;

        if (!operationId) {
            return;
        }

        const insertFn = this._apiService.getFunction(operationId);

        const insertBody = {
            ...buttonData,
            data: this.form.value as Record<string, string>,
        };

        firstValueFrom(insertFn(insertBody))
            .then(() => {
                if (this.modalId) {
                    this._modalService.closeModal(this.modalId);
                }

                this._magicRouterService.reloadPage();
            });
    }

    /**
     * Turns ResponseTable into InsertData
     * Perfect for displaying data as a key / value pair
     * ex. 'DescriptionOfValueType : Value'
     *
     * @param data
     */
    public parseResponseTable(data: ResponseTable): InsertData {
        const itemValues = data.rows?.[0]?.rowData ?? {};

        const insertData = {
            tableTitle: (data.tableTitle ?? data.title) ?? '',
            items: (data.columns ?? []).reduce<InsertData['items']>((items, column) => {
                if (column.id === 'insert_butt') {
                    const value: string | number | boolean |
                        object | Array<string> = itemValues[column.id] ?? [];

                    if (column.values && Array.isArray(value)) {
                        this.insertButtonData = KeyValueMapperUtil.mapKeysValues(column.values, value);
                    }

                    return items;
                }

                return [...items, {
                    name: column.id,
                    displayName: column.titletext,
                    type: column.dataType,
                    values: column.values,
                    keys: column.keys,
                    readOnly: column.readOnly,
                    hidden: column.settings?.visible === 'false',
                    maxLength: column.maxLength,
                    ajaxoperationId: column.ajaxoperationId,
                    ajaxproperty: column.ajaxproperty,
                    ajaxtrigger: column.ajaxtrigger,
                    required: false,
                    settings: column.settings,
                    value: itemValues[column.id] !== undefined ? itemValues[column.id] : '',
                }];
            }, []),
            insertsp: data.insertsp,
        };

        insertData.items.forEach(i => {
            if (i.type === DataTypes.AjaxArray) {
                i.filters = CollectHiddenDataFromDataItemsUtil.collectHiddenData(insertData.items);
            }
        });

        return insertData;
    }

    /**
     * close modal
     *
     * @param modalId
     */
    public closeModal(modalId: number): void {
        this._modalService.closeModal(modalId);
    }

    /**
     * open Insert in Modal
     */
    public openInsertInModal(): void {
        if (!this.itemData) {
            return;
        }

        this._modalService
            .openContent({
                pageData: {
                    tables: {
                        insert: this.itemData,
                    },
                },
            });
    }

    /**
     * Reset focus to first focusable element
     */
    public resetFocus(): void {
        if (!this.wrapper?.nativeElement) {
            return;
        }

        const wrapperElement = this.wrapper.nativeElement;

        const firstFocusableElement = wrapperElement.querySelector<HTMLElement>('[tabindex="0"]');

        if (firstFocusableElement) {
            firstFocusableElement.focus({
                preventScroll: true,
            });
        }
    }
}
