import { GlobalPositionStrategy, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Injector,
    Input,
    ViewChild,
    effect,
    inject,
    model,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { take } from 'rxjs';
import { HtTextarrayDropdownComponent } from '../ht-textarray-dropdown/ht-textarray-dropdown.component';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';

@Component({
    selector: 'ht-textarray',
    templateUrl: './ht-textarray.component.html',
    styleUrls: ['./ht-textarray.component.scss'],
    standalone: true,
    imports: [UiIconComponent, HtTextarrayDropdownComponent],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class HtTextarrayComponent {
    @Input()
    public readOnly = false;

    @Input()
    public name?: string;

    @ViewChild('textarrayElem', { static: true })
    private readonly _textarrayElement?: ElementRef<HTMLElement>;

    public readonly value$ = model<Array<string>>([], { alias: 'value' });

    private readonly _overlay = inject(Overlay);
    private readonly _injector = inject(Injector);

    /**
     * Opens the dropdown for the text array input.
     */
    public openDropdown(): void {
        if (this.readOnly) return;

        const overlayConfig = this._createOverlayConfig();
        const overlayRef = this._overlay.create(overlayConfig);
        const dropdownPortal = new ComponentPortal(HtTextarrayDropdownComponent);
        const componentRef = overlayRef.attach(dropdownPortal);

        overlayRef.backdropClick()
            .pipe(take(1))
            .subscribe(() => overlayRef.dispose());

        componentRef.instance.values$.set(this.value$());
        componentRef.instance.readOnly = this.readOnly;

        const valuesUpdateEffect = effect(
            () => {
                const dropdownValues = componentRef.instance.values$();
                this.value$.set(dropdownValues);
            },
            {
                allowSignalWrites: true,
                injector: this._injector,
            },
        );

        componentRef.onDestroy(() => valuesUpdateEffect.destroy());
        componentRef.changeDetectorRef.detectChanges();
    }

    /**
     * Creates an OverlayConfig for the dropdown.
     *
     * @returns A configured OverlayConfig instance.
     */
    private _createOverlayConfig(): OverlayConfig {
        if (!this._textarrayElement) {
            return new OverlayConfig({});
        }

        const elemPosition: DOMRect = this._textarrayElement.nativeElement.getBoundingClientRect();
        const bottomPos = elemPosition.top + elemPosition.height;
        const leftPos = elemPosition.left;
        const rightPos = elemPosition.right;
        const windowWidth = window.innerWidth;

        const maxWidth = 450;
        const maxHeight = 450;

        const shouldBindRight
            = leftPos + maxWidth > windowWidth && rightPos - maxWidth >= 0;

        const positionStrategy = new GlobalPositionStrategy()
            .top(`${ bottomPos }px`)
            .left(shouldBindRight ? undefined : `${ leftPos }px`)
            .right(shouldBindRight ? `${ windowWidth - rightPos }px` : undefined);

        return new OverlayConfig({
            positionStrategy,
            width: 'auto',
            maxWidth,
            maxHeight,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
        });
    }
}
