import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    Signal,
    ViewChild,
    computed,
    effect,
    inject,
    input,
} from '@angular/core';
import { HtCellComponent } from '../dynamic-table/dynamic-table.component';
import { EventService } from '@hrs-ui/domain-event';
import { DataTypes, HtItemType, HtRowData, HtTableColumn, HtTableRow } from '@hrs-ui/util-definitions';
import { SettingsUtil } from '@hrs-ui/util-core';
import { ListCellComponent } from '../table-cell/list/list-cell.component';
import { DateTimeCellComponent } from '../table-cell/datetime/datetime-cell.component';
import { HtDefaultCellComponent } from '../table-cell/default/default-cell.component';
import { ImageLinkCellComponent } from '../table-cell/image-link/image-link-cell.component';
import { TextareaCellComponent } from '../table-cell/textarea/textarea-cell.component';
import { TextCellComponent } from '../table-cell/text/text-cell.component';
import { PasswordCellComponent } from '../table-cell/password/password-cell.component';
import { NumericNonNegCellComponent } from '../table-cell/numeric/numeric-nonneg-cell.component';
import { NumericCellComponent } from '../table-cell/numeric/numeric-cell.component';
import { DeleteCellComponent } from '../table-cell/delete-cell/delete-cell.component';
import { ButtonCellComponent } from '../table-cell/button/button-cell.component';
import { BooleanCellComponent } from '../table-cell/boolean-cell/boolean-cell.component';
import { CommonModule } from '@angular/common';

const defaultColumn: HtTableColumn = {
    type: DataTypes.Text,
    name: '',
    displayName: '',
    columnUId: '',
    columnIndex: 0,
};

const defaultRow: HtTableRow = {
    rowData: {},
    rowUId: '',
    rowIndex: 0,
};

@Component({
    selector: 'ht-table-cell-mapper',
    templateUrl: './table-cell-mapper.component.html',
    styleUrls: ['./table-cell-mapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
        BooleanCellComponent,
        ButtonCellComponent,
        DeleteCellComponent,
        NumericCellComponent,
        NumericNonNegCellComponent,
        PasswordCellComponent,
        TextCellComponent,
        TextareaCellComponent,
        ImageLinkCellComponent,
        HtDefaultCellComponent,
        DateTimeCellComponent,
        ListCellComponent,
    ],
})
export class TableCellMapperComponent {

    @ViewChild('.ht-dynamic-table-cell', { static: false }) public cell?: HtCellComponent;

    @Input()
    public editModeDisabled = true;

    @Output()
    public readonly valueChange = new EventEmitter<{ value: HtItemType; rowId: string; column: string }>();

    public readonly row$ = input<HtTableRow>(defaultRow, { alias: 'row' });

    public readonly column$ = input<HtTableColumn>(defaultColumn, { alias: 'column' });

    public readonly rowData$ = input<HtRowData>({}, { alias: 'rowData' });

    public readonly tabIndex$: Signal<number>;
    public readonly isDisabled$: Signal<boolean>;
    public readonly isHidden$: Signal<boolean>;

    public DataTypes = DataTypes;

    private readonly _isReadonly$: Signal<boolean>;
    private readonly _tableCellColor$: Signal<string | undefined>;

    private readonly _eventService = inject(EventService);

    constructor() {
        // Whenever the row, column or tabindex change, request focus if this is the first editable cell.
        effect(() => {
            const column = this.column$();
            const row = this.row$();
            const tabIndex = this.tabIndex$();

            if (
                column.firstEditableColumn === true
                && row.firstEditableRow === true
                && tabIndex !== -1
            ) {
                this._eventService.focusFirstEditableCellByTabIndex$.next(tabIndex);
            }
        });

        this.tabIndex$ = computed(() => this._determineTabIndex(
                this.row$(),
                this.column$(),
                {
                    isReadonly: this._isReadonly$(),
                    isDisabled: this.isDisabled$(),
                },
            ));

        this._isReadonly$ = computed(() => !!this.column$().readOnly || this.isDisabled$());

        this.isDisabled$ = computed(() => SettingsUtil.isCellDisabled(this.row$(), this.column$().name));

        this.isHidden$ = computed(() => SettingsUtil.isCellHidden(this.row$(), this.column$()));

        this._tableCellColor$ = computed(() => SettingsUtil.getTableColor(this.row$(), this.column$()));
    }

    @HostBinding('class.readonly')
    public get readonlyClass(): boolean {
        return this._isReadonly$();
    }

    @HostBinding('class')
    public get class(): string {
        const tableCellColor = this._tableCellColor$();

        return tableCellColor ? `ht-table-cell ht-table-cell__color--${ tableCellColor }` : 'ht-table-cell';
    }

    private _determineTabIndex(
        row: HtTableRow,
        column: HtTableColumn,
        {
            isReadonly,
            isDisabled,
        }: {
            isReadonly: boolean;
            isDisabled: boolean;
        },
    ): number {
        if (isReadonly || isDisabled) {
            // prevent this cell from ever being tabbable since it is not editable
            return -1;
        } else {
            const safeMinimumIndex = 100;

            // non-editable cells do not make it this far...
            return (row.rowIndex * safeMinimumIndex) + column.columnIndex;
        }
    }
}
