import { ChangeDetectionStrategy, Component, Input, Signal, computed, inject, input } from '@angular/core';
import { Observable } from 'rxjs';
import { PageData, PageResponse, ContentTypes, ResponseTable } from '@hrs-ui/util-definitions';
import { MagicRouterService } from '@hrs-ui/api/domain-api';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table/table.component';
import { DetailComponent } from './detail/detail.component';
import { FilterComponent } from './filter/filter.component';
import { QuickfilterComponent } from './quickfilter/quickfilter.component';
import { EditComponent } from './edit/edit.component';
import { InsertComponent } from './insert/insert.component';
import { InsertImageComponent } from './insert-image/insert-image.component';
import { SpacerComponent } from './spacer/spacer.component';
import { EventService } from '@hrs-ui/domain-event';

@Component({
    selector: 'ht-table-mapper',
    templateUrl: './table-mapper.component.html',
    styleUrls: [
        './table-mapper.component.scss',
    ],
    standalone: true,
    imports: [
        CommonModule,
        TableComponent,
        DetailComponent,
        FilterComponent,
        QuickfilterComponent,
        EditComponent,
        InsertComponent,
        InsertImageComponent,
        SpacerComponent,
    ],
    providers: [
        EventService,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableMapperComponent {

    @Input()
    public modalId?: number;

    public readonly tables$ = input<PageData['tables'] | undefined>(undefined, { alias: 'tables' });

    public readonly paginationDetails$: Observable<PageResponse | undefined>;
    public readonly shouldOpenInModal$: Signal<boolean>;

    public ContentTypes = ContentTypes;

    private readonly _magicRouterService = inject(MagicRouterService);
    private readonly _eventService = inject(EventService);

    constructor() {
        this.shouldOpenInModal$ = computed(() => {
            const tables = this.tables$();

            return tables
                ? Object.values(tables).some(table => ![ContentTypes.Insert, ContentTypes.InsertImage].includes(table.contentType))
                : false;
        });

        this.paginationDetails$
            = this._magicRouterService.pageData$
                .pipe(
                    map(pageData => (pageData ? pageData.PageResponse : undefined)),
                );

        this._eventService.defaultFocusColumnByTable = computed(() => {
            const tables = this.tables$();

            if (!tables) {
                return;
            }

            return this._getDefaultFocusColumn(tables);
        });
    }

    /**
     * returns 0 to prevent alphabetic sorting
     */
    public returnZero(): number {
        return 0;
    }

    private _getDefaultFocusColumn(tables: Record<string, ResponseTable>): Record<string, string> {
        return Object.entries(tables).reduce<Record<string, string>>((accumulator, [tableKey, table]) => {
            let defaultFocusColumn: string | undefined;

            if (table.rows) {
                for (const row of table.rows) {
                    const cells = row.settings?.cells ?? {};

                    // Check if any cell has default_textentry === 'true'
                    const defaultTextentry = Object.keys(cells).find(
                        cellKey => cells[cellKey]?.default_textentry === 'true',
                    );

                    // If found, stop searching
                    if (defaultTextentry) {
                        defaultFocusColumn = defaultTextentry;
                        break;
                    }
                }
            }

            if (defaultFocusColumn) {
                accumulator[tableKey] = defaultFocusColumn;
            }

            return accumulator;
        }, {});
    }

}
