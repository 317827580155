import { Injectable } from '@angular/core';
import { LinkModel } from '../definitions/link.model';
import { SidebarNode } from '../definitions/sidebar-node';

/**
 * Type guard
 */
@Injectable({
    providedIn: 'root',
})
export class LinkTypeGuard {

    public isLink = (f: SidebarNode): f is LinkModel => {
        // Check if 'f' has properties unique to LinkModel, i.e., 'text' and 'operationId'
        return 'text' in f && 'operationId' in f;
    };
}
