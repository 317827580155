@if (message) {
    <div class="message">
        {{ message }}
    </div>

    <div class="actions">
        <ui-button
            icon="close"
            [label]="rejectLabel | translate"
            [iconSize]="16"
            [hasIconLeft]="true"
            [theme]="rejectTheme"
            (click)="rejectConfirmation()"
        />

        <ui-button
            icon="check"
            [label]="acceptLabel | translate"
            [iconSize]="16"
            [hasIconLeft]="true"
            (click)="acceptConfirmation()"
        />
    </div>

}
