@if (dependencies$ | async; as dependencies) {
    @if (currentLicense) {
        <div
            class="flex flex-column"
            style="height: 100%"
        >
            <p-button
                class="mt-xs ml-xs"
                label="Back to overview"
                icon="pi pi-arrow-left"
                styleClass="p-button-rounded p-button-outlined"
                (onClick)="goBackToList()"
            />
            <p class="my-m font-bold">{{ currentLicense }}</p>
            @if (licenseText$ | async; as licenseText) {
                <div
                    class="text-800"
                    [innerHTML]="licenseText"
                ></div>
            } @else {
                <div
                    class="flex justify-content-center align-items-center"
                    style="height: 100%"
                >
                    loading...
                </div>
            }
        </div>
    }

    <p-table
        styleClass="p-datatable-sm"
        scrollHeight="flex"
        [scrollable]="true"
        [value]="dependencies"
        [hidden]="currentLicense"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 35%">Third Party Software</th>
                <th style="width: 20%">License</th>
                <th style="width: 15%">Repository</th>
                <th style="width: 15%">License Text</th>
            </tr>
        </ng-template>
        <ng-template
            let-dependency
            pTemplate="body"
        >
            <tr>
                <td>{{ dependency.name }}</td>
                <td class="license-column">{{ dependency.licenses }}</td>
                <td>
                    @if (dependency.repository) {
                        <a
                            pButton
                            target="_blank"
                            rel="noopener noreferrer"
                            icon="pi pi-external-link"
                            class="p-button-rounded p-button-text p-button-sm button-link"
                            [href]="dependency.repository"
                        >
                            Repository
                        </a>
                    }
                </td>
                <td>
                    <p-button
                        icon="pi pi-file"
                        styleClass="p-button-rounded p-button-text"
                        (onClick)="showLicenseText(dependency.name, dependency.isInternal)"
                    />
                </td>
            </tr>
        </ng-template>
    </p-table>
} @else {

    <div
        class="flex justify-content-center align-items-center"
        style="height: 100%"
    >
        loading...
    </div>
}
