import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import type { EventButtonsModel } from '../definitions/event-buttons-model';
import type { EventModel } from '../definitions/event-model';
import type { Signal } from '@angular/core';
import { signal } from '@angular/core';

export class EventService {
    public buttonEvents: Observable<EventButtonsModel>;
    public events: Observable<EventModel>;
    public focusFirstEditableCellByTabIndex$ = new BehaviorSubject<number>(-1);

    public defaultFocusColumnByTable: Signal<Record<string, string> | undefined> = signal<Record<string, string> | undefined>({});

    private readonly _buttonEvents = new Subject<EventButtonsModel>();
    private readonly _events = new Subject<EventModel>();

    constructor() {
        this.buttonEvents = this._buttonEvents.asObservable();
        this.events = this._events.asObservable();
    }

}
