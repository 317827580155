import { ChangeDetectionStrategy, Component, Input, Signal, computed, model, signal } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';

@Component({
    selector: 'ht-textarray-dropdown',
    templateUrl: './ht-textarray-dropdown.component.html',
    styleUrls: ['./ht-textarray-dropdown.component.scss'],
    standalone: true,
    imports: [
        UiIconComponent,
        TranslationModule,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class HtTextarrayDropdownComponent {
    @Input()
    public readOnly = false;

    public showAddInput = false;

    public readonly values$ = model<Array<string>>([], { alias: 'value' });
    public readonly filteredValues$: Signal<Array<string>>;

    private readonly _filterString$ = signal<string>('');

    constructor() {
        this.filteredValues$ = computed(() => {
            const filterString = this._filterString$();
            return this.values$().filter(
                value => (filterString
                        ? value.toLowerCase().includes(filterString.toLowerCase())
                        : true),
            );
        });
    }

    /**
     * Opens and closes the add-input.
     *
     * @param value Optional value to toggle add-input.
     */
    public toggleAdd(value?: boolean): void {
        this.showAddInput = value ?? !this.showAddInput;
    }

    /**
     * Adds a string to the values array.
     *
     * @param value The string to add.
     */
    public addText(value: string): void {
        const newValue = [value, ...this.values$()];
        this.values$.set(newValue);
        this.toggleAdd();
    }

    /**
     * Sets the filter string.
     *
     * @param $event The input event.
     */
    public setFilterString($event: Event): void {
        this._filterString$.set(($event.target as HTMLInputElement).value);
    }

    /**
     * Deletes the value at the given index.
     *
     * @param index The index of the value to delete.
     */
    public deleteIndex(index: number): void {
        const newArray = [...this.values$()];
        newArray.splice(index, 1);
        this.values$.set(newArray);
    }
}
