<p-date-picker
    #calendar
    showTransitionOptions="300ms"
    appendTo="body"
    [fluid]="true"
    [placeholder]="placeholderWithFallback()"
    [name]="name()"
    [class.disabled]="disabled()"
    [required]="required()"
    [disabled]="disabled()"
    [tabIndex]="tabIndex()"
    [readonlyInput]="false"
    [hideOnDateTimeSelect]="true"
    [dateFormat]="dateFormat()"
    [showTime]="showTime()"
    [showIcon]="true"
    [showOnFocus]="false"
    [(ngModel)]="value"
    (ngModelChange)="valueChange.emit($event)"
/>
