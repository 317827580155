/**
 * Creates an Array of FilterData out of the ResponseTable
 *
 * @param data
 */
import type { HtItemType, HtRowData, ResponseTable, FormElement } from '@hrs-ui/util-definitions';
import { DataTypes } from '@hrs-ui/util-definitions';
import type { ButtonEventData } from '@hrs-ui/ht-button/util-ht-button';
import type { NgForm } from '@angular/forms';

export class FormDataUtil {

    /**
     * Creates an Array of FilterData out of the ResponseTable
     *
     * @param data ResponseTable
     * @returns Array<FormElement>
     */
    public static makeFormData(data: ResponseTable | undefined): Array<FormElement> {
        if (!data?.rows?.[0]) {
            return [];
        }

        const row = data.rows[0];

        return (data.columns ?? []).map(column => {
            const defaultValue = FormDataUtil._parseValueByType(column.dataType, row.rowData[column.id]);

            return {
                name: column.id,
                titletext: column.titletext,
                type: column.dataType,
                values: column.values,
                keys: column.keys,
                readOnly: column.readOnly,
                hidden: column.settings && column.settings.visible === 'false',
                ajaxoperationId: column.ajaxoperationId,
                ajaxproperty: column.ajaxproperty,
                ajaxtrigger: column.ajaxtrigger,
                settings: row.settings ? row.settings : undefined,
                defaultValue,
                value: defaultValue !== undefined
                    ? defaultValue
                    : '',
            };
        });
    }

    public static getFormSubmitData(form: NgForm | undefined, buttonEvent: ButtonEventData): HtRowData {
        if (!form) {
            return {};
        }

        // Explicitly typing form value
        const formValue: HtRowData = form.value as HtRowData;
        const formData: HtRowData = { ...formValue, ...buttonEvent.parameter };

        Object.keys(formData).forEach(key => {
            const value = formData[key];
            if (value instanceof Date) {
                const timezoneOffsetMultiplier = 60000;
                formData[key] = new Date(value.getTime() - (value.getTimezoneOffset() * timezoneOffsetMultiplier))
                    .toISOString();
            }
        });

        return formData;
    }

    /**
     * Parses the value of a form element
     * to the correct type.
     *
     * If the value is undefined, the function returns undefined.
     * If the type is boolean, the function returns true or false.
     * If the type is date or datetime, the function returns a new date object.
     * For all other types, the function returns the value.
     *
     * @param dataType What type is the value
     * @param value The value to be parsed
     * @returns Parsed value
     */
    private static _parseValueByType(
        dataType: DataTypes,
        value: HtItemType,
    ): HtItemType {
        if (value === undefined) {
            return undefined;
        }

        if (dataType === DataTypes.Boolean) {
            return !!value;
        } else if (dataType === DataTypes.DateTime || dataType === DataTypes.Date) {
            return value && typeof value === 'string' ? new Date(value) : new Date();
        } else {
            return value;
        }
    }
}
