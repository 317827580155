import { ChangeDetectorRef, EventEmitter, Input, Output, HostListener, Directive, inject } from '@angular/core';
import { ControlValueAccessor, FormControl, NgModel, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive()
export class AbstractValueAccessor<T> implements ControlValueAccessor {

    /**
     * for NgModel binding.
     */
    @Input()
    public name = '';

    /**
     * for NgModel binding.
     */
    @Input()
    public validateFn: ValidatorFn | undefined;

    /**
     * Custom Focus & Blur Event because onTouchedCallback is not working.
     */
    // TODO: find solution for no-output-native with patrick
    @Output()
    public readonly focusSet = new EventEmitter<NgModel>();

    @Output()
    public readonly blurSet = new EventEmitter<NgModel>();

    protected _value: T = {} as T;

    protected readonly _changeDetectorRef = inject(ChangeDetectorRef);

    public get value(): T {
        return this._value;
    }

    public set value(val: T) {
        this._value = val;
        this.onChange(val);
        this._changeDetectorRef.markForCheck();
    }

    @HostListener('blur')
    public onBlur(): void {
        this.onTouched(this.value);
    }

    public onChange: (value: T) => void = () => undefined;

    public onTouched: (value: T) => void = () => undefined;

    public writeValue(value: T): void {
        if (this.value !== value) {
            this.value = value;
        }
    }

    public registerOnChange(fn: (value: T) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: (value: T) => void): void {
        this.onTouched = fn;
    }

    public validate(control: FormControl): ValidationErrors | null {
        if (this.validateFn) {
            return this.validateFn(control);
        } else {
            // not possible otherwise
            return null;
        }
    }
}
