import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { HttpStatusCodeHandlerService, ResponseBody } from '../services/https-status-code-handler.service';
import { TokenService } from '@hrs-ui/app-status/domain-app-status';
import { ResponseData } from '@hrs-ui/util-definitions';

@Injectable({
    providedIn: 'root',
})
export class XhrDownloaderUtil {
    private readonly _tokenService = inject(TokenService);
    private readonly _httpStatusCodeHandlerService = inject(HttpStatusCodeHandlerService);

    /**
     * use xhr to download blob
     *
     * This is a workaround, since the Angular httpClient breaks the download
     * of zip file encoded data.
     *
     * @param url : string
     * @param operation defaults to 'PUT'
     * @param reqBodyOrParams : string
     * @returns Observable of Blob or null
     */
    public getBlobWithXHR$(
        url: string,
        operation: 'GET' | 'PUT' = 'PUT',
        reqBodyOrParams: string = '',
    ): Observable<Blob | null> {

        return from<Promise<Blob | null>>(new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();

            xhr.onload = () => {
                if (xhr.readyState !== XMLHttpRequest.DONE) {
                    return;
                }

                if (xhr.status === HttpStatusCode.Ok) {
                    if (xhr.response instanceof Blob) {
                        resolve(xhr.response);
                    } else {
                        reject(new Error('XhrDownloaderUtil ::: Expected Blob response'));
                    }
                    return;
                }

                if (xhr.status === HttpStatusCode.Created) {
                    if (xhr.responseType === 'text' && typeof xhr.responseText === 'string') {
                        try {
                            const data = JSON.parse(xhr.responseText) as ResponseBody;
                            this._httpStatusCodeHandlerService.handleRedirectResponseBody(data);
                        } catch {
                            reject(new Error('XhrDownloaderUtil ::: Failed to parse JSON response'));
                            return;
                        }
                    }
                    resolve(null);
                    return;
                }

                if (xhr.status === HttpStatusCode.PartialContent) {
                    if (xhr.responseType === 'text' && typeof xhr.responseText === 'string') {
                        try {
                            const data = JSON.parse(xhr.responseText) as ResponseBody<ResponseData>;
                            this._httpStatusCodeHandlerService.handleMessageResponseBody(data);
                        } catch {
                            reject(new Error('XhrDownloaderUtil ::: Failed to parse JSON response'));
                            return;
                        }
                    }
                    resolve(null);
                    return;
                }

                resolve(null);
                return;
            };

            xhr.onerror = () => {
                const response = xhr.response as HttpErrorResponse | undefined;

                if (response) {
                    this._httpStatusCodeHandlerService.handleErrorResponseMessage(
                        response.status,
                        response.message,
                    );
                }

                reject(new Error('XhrDownloaderUtil ::: A xhr error occurred while fetching resources'));
            };

            xhr.open(operation, operation === 'GET' ? `${ url }${ reqBodyOrParams }` : url, true);

            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.setRequestHeader('HRS-Access-Token', this._tokenService.getToken() as string);

            xhr.responseType = 'blob';

            if (operation === 'PUT') {
                xhr.send(reqBodyOrParams);
            } else {
                xhr.send();
            }
        }));
    }
}
