import type { CanThemeConstructor } from '../definitions/can-theme';
import { AppTheming } from '../definitions/app-theming';
import type { HasElementRefConstructor } from '../definitions/has-element-ref';

/**
 * a mixin for themes
 *
 * @param base class
 * @param defaultColor optional default class
 * @returns new class
 */
export function mixinTheme<T extends HasElementRefConstructor>(
    base: T, defaultColor?: AppTheming,
): CanThemeConstructor & T {
    return class extends base {
        private _theme: AppTheming = AppTheming.Secondary;

        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        constructor(...args: Array<any>) {
            super(...args as Array<unknown>);

            // Set the default color that can be specified from the mixin.
            this.theme = defaultColor ? defaultColor : AppTheming.Secondary;
        }

        public get theme(): AppTheming { return this._theme; }

        public set theme(value: AppTheming) {
            const colorPalette = value;

            if (colorPalette !== this._theme) {
                this.elementRef?.nativeElement.classList.remove(`theme--${ this._theme }`);

                this.elementRef?.nativeElement.classList.add(`theme--${ colorPalette }`);

                this._theme = colorPalette;
            }
        }
    };
}
