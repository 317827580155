import { coerceBooleanProperty } from '@angular/cdk/coercion';
import type { HasElementRefConstructor } from '../definitions/has-element-ref';
import type { CanSetProcessingConstructor } from '../definitions/can-set-processing';

/**
 * Mixin to augment a directive with a `processing` property.
 *
 * @param base class
 * @param defaultClass optional default class
 * @returns new class
 */
export function mixinProcessing<T extends HasElementRefConstructor>(
    base: T,
    defaultClass?: string,
): CanSetProcessingConstructor & T {
    return class extends base {
        private _processing = false;
        private readonly _targetClass: string = '';

        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        constructor(...args: Array<any>) {
            super(...args as Array<unknown>);
            this._targetClass = defaultClass ? defaultClass : (this.elementRef?.nativeElement.className ?? '');
        }

        public get processing(): boolean {
            return this._processing;
        }

        public set processing(value: unknown) {
            this._processing = coerceBooleanProperty(value);
            this._processing
                ? this.elementRef?.nativeElement.classList.add(`${ this._targetClass }--processing`)
                : this.elementRef?.nativeElement.classList.remove(`${ this._targetClass }--processing`);
        }
    };
}
