import { LICENSING_PATH_CONFIG_TOKEN, type LicensingPathConfig } from '../config';
import { LicenseService } from '../data/license.service';
import type { Provider } from '@angular/core';

/**
 * provide the license service with dependencies
 *
 * @param config
 */
export function provideLicenseService(config: LicensingPathConfig): Provider {
    return [LicenseService, { provide: LICENSING_PATH_CONFIG_TOKEN, useValue: config }];
}
