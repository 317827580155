import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { UiButtonComponent } from '@hrs-ui/ui/ui-button';
import { TranslationModule } from '@hrs-ui/translation/domain-translation';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { AppTheming } from '@hrs-ui/util-component-support';

@Component({
    selector: 'ht-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: [
        './confirmation.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        UiButtonComponent,
        TranslationModule,
    ],
})
export class ConfirmationComponent {

    @Input() public message?: string;
    @Input() public acceptLabel = 'accept';
    @Input() public rejectLabel = 'reject';

    @Input() public acceptCallback?: () => void;
    @Input() public rejectCallback?: () => void;

    @Input() public modalId?: number;

    public readonly rejectTheme = AppTheming.Tertiary;

    private readonly _modalService = inject(ModalService);

    /**
     * close Modal
     */
    public acceptConfirmation(): void {
        if (this.modalId) {
            this._modalService.closeModal(this.modalId);
        }

        this.acceptCallback?.();
    }

    /**
     * close Modal
     */
    public rejectConfirmation(): void {
        if (this.modalId) {
            this._modalService.closeModal(this.modalId);
        }

        this.rejectCallback?.();
    }
}
