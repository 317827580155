import { inject, Injectable } from '@angular/core';
import { ApiService } from '@hrs-ui/api/util-api';
import { OrgUnitService } from '@hrs-ui/app-status/domain-app-status';
import { Action, createAdapter } from '@state-adapt/core';
import { NEVER, Observable, Subject, withLatestFrom } from 'rxjs';
import { watchNgrx } from '@state-adapt/ngrx';
import { catchError, switchMap, take } from 'rxjs/operators';
import { toSource } from '@state-adapt/rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export abstract class BaseSettingsService<T, S> {

    protected _adapter = createAdapter<T>()({});
    protected _patch$ = new Subject<S>();

    protected readonly _apiService = inject(ApiService);
    protected readonly _orgUnitService = inject(OrgUnitService);

    protected abstract _updateData$(options: S, currentState: T): Observable<T>;
    protected readonly _patchAction$: Observable<Action<T>>;
    protected readonly _getAction$: Observable<Action<T>>;

    constructor(
        protected _storageKey: string,
        protected _statePath: string,
        protected _patchActionKey: string,
        protected _getActionKey: string,
    ) {

        this._patchAction$ = this._patch$.pipe(
            withLatestFrom(watchNgrx(this._statePath, this._adapter).state$),
            switchMap(([options, currentState]) => this._updateData$(options, currentState)),
            toSource(this._patchActionKey),
        );

        this._getAction$ = this._orgUnitService.data$.pipe(
            switchMap(orgUnit => this._getValueByOrgUnit$(orgUnit)),
            catchError((err: HttpErrorResponse) => this._handleInvalidStorageKey$(err)),
            toSource(this._getActionKey),
        );

    }

    protected _initStoreKey$(key: string): Observable<never> {
        return this._apiService.getFunction('post-me')(key, {})
            .pipe(
                take(1),
                switchMap(() => NEVER),
            );
    }

    protected _handleInvalidStorageKey$(err: HttpErrorResponse): Observable<never> {
        // Error Code: invalid storage key
        if ('code' in err.error && (err.error as { code: string }).code === '6002') {
            return this._initStoreKey$(this._storageKey + this._orgUnitService.data);
        }

        return NEVER;
    }

    protected _getValueByOrgUnit$(orgUnit: string): Observable<T> {
        return this._apiService.getFunction<T>('get-me')(this._storageKey + orgUnit);
    }

    protected _setData$(orgUnit: string, data: T): Observable<undefined> {
        return this._apiService.getFunction<undefined>('put-me')(this._storageKey + orgUnit, data);
    }
}
