import { TranslationModule, TranslationService } from '@hrs-ui/translation/domain-translation';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AuthService } from '@hrs-ui/authentication/domain-authentication';
import { TabTitleService } from '@hrs-ui/app-status/domain-app-status';
import { QuickAccessService } from '@hrs-ui/quick-access/domain-quick-access';
import { UiButtonComponent } from '@hrs-ui/ui/ui-button';
import { FormsModule, NgForm } from '@angular/forms';
import { UiInputComponent } from '@hrs-ui/ui/ui-input';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { TitleScreenLogoComponent } from '../title-screen-logo/title-screen-logo.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { adaptNgrx } from '@state-adapt/ngrx';
import { toSource } from '@state-adapt/rxjs';
import { filter, map, Observable, of, type OperatorFunction, shareReplay, Subject, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { InteractionState, interactionStateAdapter } from '@hrs-ui/util-core';
import { AuthApi } from '@hrs-ui/apiclient';
import { Action } from '@state-adapt/core';

interface Credentials {
    username: string;
    password: string;
}

@Component({
    selector: 'ht-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        TranslationModule,
        UiButtonComponent,
        FormsModule,
        UiInputComponent,
        UiIconComponent,
        TitleScreenLogoComponent,
        NgIf,
        AsyncPipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
    public username = '';
    public password = '';
    public showingPassword = false;

    public submit$ = new Subject<NgForm>();

    protected _submit$: Observable<Credentials> = this.submit$.pipe(
        this._invalidateLoginForm(),
        this._getValidatedCredentialsFromForm(),
        shareReplay(1),
    );

    public state = adaptNgrx(new InteractionState(), {
        adapter: interactionStateAdapter,
        sources: {
            setPending: this._submit$.pipe(
                toSource('[login] submit'),
            ),
            setState: this._submit$.pipe(
                switchMap(credentials => this._loginWithCredentials(credentials).pipe(
                    this._mapToSuccessState(),
                    this._catchToErrorState(),
                )),
            ),
        },
    });

    protected readonly _authService = inject(AuthService);
    protected readonly _tabTitleService = inject(TabTitleService);
    protected readonly _quickAccessService = inject(QuickAccessService);

    protected readonly _translationService = inject(TranslationService);

    protected get _invalidTokenErrorMessage(): string {
        return this._translationService.translate('errorCodes.accessDenied');
    }

    public ngOnInit(): void {
        this._authService.updateAccessToken();

        this._tabTitleService.setTitleByTranslationKey('login.tabTitle');
    }

    /**
     * Toggles the state of the password being shown as text or hidden as ****
     */
    public toggleHideShowPassword(): void {
        this.showingPassword = !this.showingPassword;
    }

    protected _invalidateLoginForm(): OperatorFunction<NgForm, NgForm> {
        return tap(loginForm => {
            if (!loginForm.valid) {
                // since form.markAsTouched does not touch child controls
                // we need to do it ourselves to display error message at field level
                loginForm.form.get('username')?.markAsTouched();
                loginForm.form.get('password')?.markAsTouched();
                loginForm.form.updateValueAndValidity();
            }
        });
    }

    protected _getValidatedCredentialsFromForm(): OperatorFunction<NgForm, Credentials> {
        return (source: Observable<NgForm>) => source.pipe(
            map(loginForm => loginForm.value as Partial<Credentials>),
            filter((credentials): credentials is Credentials => !!credentials.username && !!credentials.password),
        );
    }

    protected _loginWithCredentials(credentials: Credentials): Observable<AuthApi.UserAccessToken> {
        return this._authService.loginUser$({
            user: credentials.username,
            password: credentials.password,
        })
            .pipe(
                tap(() => {
                    this._quickAccessService.onUserChanged();
                }),
            );
    }

    protected _mapToSuccessState<T>(): OperatorFunction<T, Action<InteractionState>> {
        return ($source: Observable<T>) => $source.pipe(
            map(() => InteractionState.success()),
            toSource('[login] success'),
        );
    }

    protected _catchToErrorState<T>(): OperatorFunction<T, T | Action<InteractionState>> {
        return ($source: Observable<T>) => $source.pipe(
            catchError(() => of(InteractionState.error(this._invalidTokenErrorMessage)).pipe(
                toSource('[login] failed'),
            )),
        );
    }
}
