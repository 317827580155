import { createAdapter } from '@state-adapt/core';

export class InteractionState {
    public isPending?: boolean;
    public isError?: boolean;
    public isSuccess?: boolean;
    public errorMessage?: string;
    public successMessage?: string;

    constructor({
                    isPending,
                    isError,
                    isSuccess,
                    errorMessage,
                    successMessage,
                }: InteractionState = {}) {
        this.isPending = isPending ?? false;
        this.isError = isError ?? false;
        this.isSuccess = isSuccess ?? false;
        this.errorMessage = errorMessage;
        this.successMessage = successMessage;
    }

    public static error(message: string = 'ERROR'): InteractionState {
        return new InteractionState({
            isError: true,
            errorMessage: message,
        });
    }

    public static success(message: string = 'SUCCESS'): InteractionState {
        return new InteractionState({
            isSuccess: true,
            successMessage: message,
        });
    }

    public static pending(): InteractionState {
        return new InteractionState({
            isPending: true,
        });
    }
}

export const interactionStateAdapter = createAdapter<InteractionState>()({
    setPending: () => InteractionState.pending(),
    setError: (_, { errorMessage }: Pick<InteractionState, 'errorMessage'>) => InteractionState.error(errorMessage),
    setSuccess: (_, { successMessage }: Pick<InteractionState, 'successMessage'>) => InteractionState.success(successMessage),
    setState: (_, newState: InteractionState) => newState,
    reset: () => new InteractionState(),
});
