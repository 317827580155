import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, SecurityContext } from '@angular/core';
import { catchError, map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { LicenseInfo, LicenseInfoResponse } from '../entity/license-info';
import { marked } from 'marked';
import { LICENSING_PATH_CONFIG_TOKEN, LicensingPathConfig } from '../config';
import { DomSanitizer } from '@angular/platform-browser';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

marked.use({
    mangle: false,
    headerIds: false,
});

@Injectable()
export class LicenseService {
    protected _assetPath = this._config.assetPath ?? 'assets';

    protected _licensingFolder = this._config.licensingFolder ?? 'licensing';
    protected _licenseTextPath = this._config.licenseTextPath ?? 'licenses';
    protected _licensesJson = this._config.licensesJson ?? 'licenses.json';

    protected _internalLicenseTextPath = this._config.internalLicenseTextPath ?? 'internal-licenses';

    constructor(
        protected readonly _http: HttpClient,
        @Inject(LICENSING_PATH_CONFIG_TOKEN) protected readonly _config: LicensingPathConfig,
        protected readonly _sanitizer: DomSanitizer,
    ) {}

    public getLicenseInfo$(): Observable<Array<LicenseInfo>> {
        return this._http
            .get<LicenseInfoResponse>(`${ this._assetPath }/${ this._licensingFolder }/${ this._licensesJson }`)
            .pipe(
                map(res => this._formatLicenseInfo(res)),
                catchError(this._handleError<Array<LicenseInfo>>([])),
                shareReplay(1),
            );
    }

    public getLicenseText(dependencyName: string, isInternal = false): Observable<string> {
        const assetFolder = isInternal ? this._internalLicenseTextPath : this._licenseTextPath;
        const assetPath = `${ this._assetPath }/${ this._licensingFolder }/${ assetFolder }`;

        const fileName = dependencyName
            .replace(/[./\s]/g, '-')
            .toLowerCase();

        // normally the { responseType: 'text' } should work here too, but does not
        return this._http.get<Blob>(`${ assetPath }/${ fileName }.txt`).pipe(
            switchMap(blob => fromPromise(blob.text())),
            map(res => this._sanitizeHTML(this._parseMarkdown(res))),
            catchError(this._handleError<string>('No license found')),
            shareReplay(1),
        );
    }

    protected _parseMarkdown(content: string): string {
        return marked.parse(content);
    }

    protected _formatLicenseInfo(dependency: LicenseInfoResponse, isInternal = false): Array<LicenseInfo> {
        const licenseList: Array<LicenseInfo> = [];

        for (const key in dependency) {
            if (Object.prototype.hasOwnProperty.call(dependency, key)) {
                const dependencyName = key;

                const { licenses, repository, publisher, licenseUrl } = dependency[key] ?? {};

                if (licenseUrl && licenseUrl !== repository) {
                    licenseList.push({
                        name: dependencyName,
                        licenses: licenses ?? '-',
                        repository: repository ? this._sanitizeUrl(repository) : '',
                        publisher: publisher ?? '-',
                        isInternal,
                    });
                }

            }
        }

        return licenseList;
    }

    protected _handleError<T>(result: T) {
        return (error: Error): Observable<T> => {
            console.error(error);

            return of(result);
        };
    }

    protected _sanitizeHTML(text: string): string {
        return this._sanitizer.sanitize(SecurityContext.HTML, text) ?? '';
    }

    protected _sanitizeUrl(url: string): string {
        return this._sanitizer.sanitize(SecurityContext.URL, url) ?? '';
    }
}
