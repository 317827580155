import { Injectable, Injector, inject } from '@angular/core';
import { Router } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import { AuthApi } from '@hrs-ui/apiclient';
import { UserLanguageService } from '@hrs-ui/translation/domain-translation';
import { BrowserService, OrgUnitService, TokenService } from '@hrs-ui/app-status/domain-app-status';
import { RequestDataService, MagicRouterService } from '@hrs-ui/api/domain-api';
import { RequestData, ApiService } from '@hrs-ui/api/util-api';
import { UrlParserUtil, AppRoutes } from '@hrs-ui/util-route';
import { Observable } from 'rxjs';

type UserAccessToken = AuthApi.UserAccessToken;
type UserAuthentication = AuthApi.UserAuthentication;

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public redirectUrl = '';
    public userWaitingForMFA?: UserAuthentication;

    private readonly _apiService = inject(ApiService);
    private readonly _tokenService = inject(TokenService);
    private readonly _orgUnitService = inject(OrgUnitService);
    private readonly _requestDataService = inject(RequestDataService);
    private readonly _browserService = inject(BrowserService);
    private readonly _router = inject(Router);
    private readonly _injector = inject(Injector);

    /**
     * login HRS user
     *
     * @param userAuthentication
     */
    public loginUser$(userAuthentication: UserAuthentication): Observable<UserAccessToken> {

        return this._apiService.getFunction<UserAccessToken>('auth_login')(userAuthentication)
            .pipe(
                take(1),
                tap({
                    next: token => {
                        if (token.oneTimePasswordRequired) {
                            this.userWaitingForMFA = userAuthentication;
                            this.redirectToMFA();
                            this._browserService.broadcastLoginChangeToOtherTabsWithSameSession();
                        } else if (token.token) {
                            this.userWaitingForMFA = undefined;
                            this._tokenService.setUser(userAuthentication.user);
                            this._tokenService.setToken(token);
                            this._injector.get<UserLanguageService>(UserLanguageService).language = token.cultureCode ?? '';
                            this._orgUnitService.data = token.orgUnitID ?? '';

                            const parsedRedirectURL = this._router.parseUrl(this.redirectUrl).queryParams;

                            this.redirectUrl = '';
                            this._injector.get<MagicRouterService>(MagicRouterService).operationId
                                = parsedRedirectURL['operationId'] as string || '';

                            if (parsedRedirectURL['requestData']) {
                                this._requestDataService.data
                                    = UrlParserUtil.parseUrlToData(parsedRedirectURL['requestData'] as string) as RequestData;
                            }

                            this._browserService.broadcastLoginChangeToOtherTabsWithSameSession();
                        } else {
                            throw Error('errorCodes.invalidToken');
                        }
                    },
                    error: error => {
                        console.warn(error);
                    },
                }),
            );
    }

    /**
     * logout HRS user
     */
    public logoutUser(): void {
        if (this._tokenService.getToken()) {
            this._apiService.executeOperationId('auth_logout')
                .subscribe();

            this._browserService.broadcastLoginChangeToOtherTabsWithSameSession();
        }

        this.clearUser();
    }

    /**
     * clear HRS user
     */
    public clearUser(): void {
        this._router.navigate([`/${ AppRoutes.Login }`]);
        this._tokenService.setToken();
        this.userWaitingForMFA = undefined;
    }

    /**
     * redirect to MFA page
     */
    public redirectToMFA(): void {
        this._router.navigate([`/${ AppRoutes.MFA }`]);
    }

    /**
     * Request for updated user access token.
     */
    public updateAccessToken(): void {
        if (!this._tokenService.getToken()) {
            return;
        }

        this._apiService.executeOperationId<UserAccessToken | undefined>('auth_me')
            .pipe(
                take(1),
                filter(success => !!success),
            )
            .subscribe(token => {
                this._tokenService.setToken(token);
                this._router.navigateByUrl(this.redirectUrl);
                this.redirectUrl = '';
            });
    }
}
