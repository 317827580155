<div class="login-wrapper">
    <div class="login-box">
        <ht-title-screen-logo/>

        <div class="login-box__text">
            <h4 class="subline">{{ 'login.subline' | translate }}</h4>
            <h3 class="headline">{{ 'login.headline' | translate }}</h3>
        </div>

        <form #loginForm="ngForm" (ngSubmit)="submit$.next(loginForm)">
            <ng-container *ngIf="{ username: loginForm.form.controls['username'] } as control">
                <ng-container *ngIf="{ showErrors: control.username?.invalid && control.username?.touched } as evaluation">
                    <div class="login-row username" [class.invalid]="evaluation.showErrors">
                        <ui-input
                            type="text"
                            id="usernameInput"
                            name="username"
                            autocomplete="username"
                            [required]="true"
                            [placeholder]="'login.userNamePlaceholder' | translate"
                            [hideValidityIcons]="true"
                            [tabIndex]="0"
                            [(ngModel)]="username"
                        />
                        <ui-icon
                            class="input-icon"
                            iconName='user'
                            [iconSize]="20"
                        />
                    </div>
                    <div
                        *ngIf="evaluation.showErrors"
                        class="error"
                    >
                        <ui-icon
                            [iconName]="'error-circle'"
                            [iconSize]="20"
                        />
                        {{ 'login.missingUsername' | translate }}
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="{ password: loginForm.form.controls['password'] } as control">
                <ng-container *ngIf="{ showErrors: control.password?.invalid && control.password?.touched } as evaluation">
                    <div class="login-row password" [class.invalid]="evaluation.showErrors">
                        <ui-input
                            id="passwordInput"
                            name="password"
                            autocomplete="current-password"
                            [required]="true"
                            [type]="showingPassword ? 'text' : 'password'"
                            [placeholder]="'login.passwordPlaceholder' | translate"
                            [hideValidityIcons]="true"
                            [tabIndex]="1"
                            [(ngModel)]="password"
                        />
                        <ui-icon
                            class="input-icon"
                            iconName='locked'
                            [iconSize]="20"
                        />
                        <ui-icon
                            class="show-hide-password input-icon"
                            [iconName]="showingPassword ? 'show' : 'hide'"
                            [iconSize]="20"
                            (click)="toggleHideShowPassword()"
                        />
                    </div>
                    <div
                        *ngIf="evaluation.showErrors"
                        class="error"
                    >
                        <ui-icon
                            [iconName]="'error-circle'"
                            [iconSize]="20"
                        />
                        {{ 'login.missingPassword' | translate }}
                    </div>
                </ng-container>
            </ng-container>
            <div class="login-row submit">
                <ui-button
                    class="login-button"
                    type="submit"
                    icon="arrow-right"
                    [label]="'login.loginLabel' | translate"
                    [iconSize]="16"
                    [hasIconLeft]="true"
                    [tabIndex]="2"
                    [processing]="(state.state$ | async)?.isPending ?? false"
                />
            </div>
            <div
                *ngIf="(state.state$ | async)?.isError"
                class="error box"
            >
                {{ ((state.state$ | async)?.errorMessage ?? '') | translate }}
            </div>
        </form>
    </div>
</div>
