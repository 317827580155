import type { Subscriber } from 'rxjs';
import { Observable } from 'rxjs';

/**
 * Converts a `File` object to a Data URL using the `FileReader` API and returns an Observable that emits the Data URL as a string.
 *
 * @param file - The file to be converted into a Data URL.
 * @returns An Observable that emits the Data URL as a string. If the conversion fails, the Observable emits an error.
 *
 * @example
 * const file = new File(["content"], "example.txt");
 * fileToUrl$(file).subscribe({
 *   next: (url) => console.log('File Data URL:', url),
 *   error: (error) => console.error('Error reading file:', error)
 * });
 */
export function fileToUrl$(file: File): Observable<string> {
    return new Observable(
        (subscriber: Subscriber<string>): void => {
            const fileReader = new FileReader();

            fileReader.onload = (event: ProgressEvent<FileReader>): void => {
                subscriber.next(event.target?.result as string | undefined ?? '');
            };

            fileReader.onerror = (event: ProgressEvent<FileReader>): void => {
                subscriber.error(event);
            };

            fileReader.readAsDataURL(file);
        },
    );
}
