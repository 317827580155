import { ChangeDetectionStrategy, Component, Signal, computed } from '@angular/core';
import { BaseCellComponent } from '../base-cell.component';
import { HtCellComponent } from '../../dynamic-table/dynamic-table.component';
import { Settings } from '@hrs-ui/util-definitions';
import { EditableTextComponent } from '@hrs-ui/ui/ui-input';

@Component({
    selector: 'ht-text-cell',
    templateUrl: './text-cell.component.html',
    styleUrls: ['../base-cell.component.scss', './text-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        EditableTextComponent,
    ],
})
export class TextCellComponent extends BaseCellComponent<string> implements HtCellComponent {

    public defaultValue = '';

    public readonly isDefaultTextEntry$: Signal<boolean>;

    constructor() {
        super();

        this.isDefaultTextEntry$ = computed(() => {
            const row = this.row$();
            const column = this.column$();

            return !!row.settings && this._determineDefaultTextInput(row.settings, column.name);
        });
    }

    /**
     * Checks to see if a given Settings contains a default_textentry
     *
     * @param settings
     * @param column
     * @returns
     */
    private _determineDefaultTextInput(settings: Settings, column: string): boolean {
        const isDefaultTextEntryTrue = (settingsObj?: {
            default_textentry?: string | boolean;
        }): boolean => settingsObj?.default_textentry !== undefined
            && settingsObj.default_textentry !== false && settingsObj.default_textentry !== 'false';

        return isDefaultTextEntryTrue(settings)
            || (
                !!settings.cells?.[column]
                && isDefaultTextEntryTrue(settings.cells[column])
            );
    }
}
