import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    inject,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslationModule, TranslationService } from '@hrs-ui/translation/domain-translation';
import { combineLatest } from 'rxjs';
import { HtButtType, HtButton, PageData, ButtonName } from '@hrs-ui/util-definitions';
import { QuickAccessItem } from '@hrs-ui/quick-access/util-quick-access';
import { QuickAccessService } from '@hrs-ui/quick-access/domain-quick-access';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { FormsModule } from '@angular/forms';
import { HtInputComponent } from '@hrs-ui/ui/ui-input';
import { HtButtonComponent } from '@hrs-ui/ht-button/ui-ht-button';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'ht-save-quick-access-edit',
    templateUrl: './save-quick-access-edit.component.html',
    styleUrls: ['./save-quick-access-edit.component.scss'],
    standalone: true,
    imports: [
        UiIconComponent,
        TranslationModule,
        FormsModule,
        HtInputComponent,
        HtButtonComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveQuickAccessEditComponent {
    @Input()
    public quickAccessItems?: Array<QuickAccessItem> | null;

    @Input()
    public isOpen = false;

    @Input()
    public isFavorite?: boolean | null;

    @Input()
    public pageData?: PageData;

    @Input()
    public quickAccessItem?: QuickAccessItem;

    @Output()
    public readonly backgroundClickHandler = new EventEmitter<boolean>();

    public saveQuickAccessItemButton?: HtButton;
    private _saveButtonText?: string;

    private readonly _quickAccessService = inject(QuickAccessService);
    private readonly _route = inject(ActivatedRoute);
    private readonly _translationService = inject(TranslationService);

    constructor() {
        combineLatest([
            this._route.queryParams,
            this._translationService.translate$('quickAccess.saveButton'),
        ])
            .pipe(
                takeUntilDestroyed(),
            )
            .subscribe(([queryParams, rowText]) => {
                const opId = queryParams['operationId'] as string;

                this._saveButtonText = rowText ? rowText : '';
                this.saveQuickAccessItemButton = {
                    id: ButtonName.Submit,
                    text: this._saveButtonText ? this._saveButtonText : '',
                    buttType: HtButtType.CustomSubmit,
                    operationId: opId,
                };
            });
    }

    /**
     * toggles the isOpen variable
     *
     * @param state
     */
    public toggleIsOpen(state: boolean): void {
        this.isOpen = state;
        this.backgroundClickHandler.emit(state);
    }

    /**
     * Saves the Quick Access item's name and description
     */
    public saveChanges(): void {
        if (this.quickAccessItem && this.quickAccessItems) {
            this._quickAccessService.updateQuickAccessItem(this.quickAccessItem);
        } else if (this.quickAccessItem) {
            this._quickAccessService.toggleFavorite(this.quickAccessItem);
        }

        this.toggleIsOpen(false);
    }
}
