/**
 * ATTENTION: THIS FILE CONTAINS A LOT OF MAGIC
 * LOL jk it's fine
 */

import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, of, merge } from 'rxjs';
import { PageData, ServerResponse } from '@hrs-ui/util-definitions';
import { LoadingService, OrgUnitService } from '@hrs-ui/app-status/domain-app-status';
import { tap, switchMap, shareReplay, debounceTime, map, catchError, take, skip } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UserLanguageService } from '@hrs-ui/translation/domain-translation';
import { UrlParserUtil } from '@hrs-ui/util-route';
import { ApiService } from '@hrs-ui/api/util-api';
import { DownloadService } from './download.service';
import { RequestDataService } from './request-data.service';

@Injectable({
    providedIn: 'root',
})
export class MagicRouterService {
    private readonly _pageData$: Observable<PageData | undefined>;
    private readonly _operationId$: BehaviorSubject<string>;

    private readonly _reloadSubject = new BehaviorSubject<string>('');
    private _backPacket?: object;

    private readonly _apiService = inject(ApiService);
    private readonly _route = inject(ActivatedRoute);
    private readonly _router = inject(Router);
    private readonly _loadingService = inject(LoadingService);
    private readonly _requestDataService = inject(RequestDataService);
    private readonly _downloadService = inject(DownloadService);
    private readonly _orgUnitService = inject(OrgUnitService);
    private readonly _userLanguageService = inject(UserLanguageService);

    constructor() {
        this._operationId$ = new BehaviorSubject(this._route.snapshot.queryParams['operationId'] as string || '');

        const debounceMs = 20;

        /**
         * Data for entire page
         * Mainly pulled by the content-wrapper.component
         */
        this._pageData$ = combineLatest([
            this.operationId$,
            merge(
                // ignore the first language emit on application init
                this._userLanguageService.language$.pipe(
                    take(1),
                ),
                this._userLanguageService.language$.pipe(
                    skip(1),
                    tap(() => this.operationId = ''),
                ),
            ),
            this._requestDataService.data$,
            this._orgUnitService.data$,
            this._reloadSubject,
        ])
            .pipe(
                debounceTime(debounceMs),
                tap(() => this._loadingService.loadingStatus = true),
                switchMap(([operationId]) => this._getResponse(operationId)
                    .pipe(
                        catchError(err => {
                            console.error(err);

                            return of(undefined);
                        }),
                    )),
                map(serverResponse => this._parseServerResponse(serverResponse)),
                tap(() => this._loadingService.loadingStatus = false),
                shareReplay(1),
            );

        combineLatest([
            this.operationId$,
            this._requestDataService.queryParam$
                .pipe(
                    // The first request coming from the RequestDataService is the current page.
                    // This can be ignored; we don't want to reload the page when we just opened it.
                    skip(1),
                ),
        ])
            .pipe(
                // debounce to prevent two navigations at the same time -> could lead to errors (missing parts)
                debounceTime(1),
            )
            .subscribe(([operationId, requestData]) => {
                // When data is requested, and an operationId exists, navigate to the requested data.
                // Without an operation ID, there is no data, and any request can only lead to the dashboard.
                if (operationId !== '') {
                    this._router.navigate(['content'], {
                        queryParams: {
                            operationId,
                            requestData: UrlParserUtil.parseDataToUrl(requestData),
                        },
                    });

                } else {
                    this._router.navigate(['']);
                }
            });

        // use operationId set in queryParams for routing if user uses specific link or reloads the page
        // This will be performed only once if 'this.operationId' was not set already!
        this._route.queryParams
            .subscribe(params => {
                if (params['operationId'] && (this.operationId === '')) {
                    this._operationId$.next(params['operationId'] as string || '');
                }
            });
    }

    public get pageData$(): Observable<PageData | undefined> {
        return this._pageData$;
    }

    /**
     * The operation Id is used in a lot API calls in order to retrieve correct data or functionality
     */
    public set operationId(operationId: string) {
        this._updateOperationId(operationId);
    }

    public get operationId(): string {
        return this._operationId$.getValue();
    }

    public get operationId$(): Observable<string> {
        return this._operationId$.asObservable();
    }

    /**
     * reloads current page, e.g. after save
     */
    public reloadPage(): void {
        this._reloadSubject.next('');
    }

    /**
     * reset current page, e.g. reset Filter
     */
    public resetPage(): void {
        this._updateOperationId(this.operationId);
        this.reloadPage();
    }

    /**
     * Resets the Back Packet
     */
    public resetBackPacket(): void {
        this._backPacket = undefined;
    }

    private _updateOperationId(operationId: string): void {
        this._operationId$.next(operationId);
        this._requestDataService.resetData();
    }

    /**
     * requests the api for data based on operationId and request data
     *
     * @param operationId of type string
     * @returns an observable of ServerResponse, Blob or undefined
     */
    private _getResponse(operationId: string): Observable<ServerResponse | Blob | undefined> {
        if (operationId === '') {
            return of(undefined);
        }

        const operationIdFunction = this._apiService.getFunction<ServerResponse | Blob | undefined>(this.operationId);

        if (typeof operationIdFunction !== 'function') {
            return of(undefined);
        }

        return operationIdFunction({
            backPacket: this._backPacket,
            ...this._requestDataService.data,
        });

    }

    /**
     * parses the server-response according to our definitions defined in frontend
     *
     * @param serverResponse as ServerResponse | Blob
     * @returns PageData or undefined
     */
    private _parseServerResponse(serverResponse?: ServerResponse | Blob): PageData | undefined {

        if (!serverResponse) {
            return undefined;
        }

        // If the server response is a Blob for downloading files
        if (serverResponse instanceof Blob) {

            if (!this._downloadService.downloadDebounced) {
                this._downloadService.downloadFile(serverResponse)
                    .then(() => this._redirectAfterDownload());
            }

            return undefined;
        }

        if (!('items' in serverResponse)) {
            return;
        }

        this._downloadService.downloadDebounced = false;

        const response = serverResponse;
        const responseItem = response.items[0];

        if (!responseItem) {
            return undefined;
        }

        this._backPacket = response.PageResponse.backPacket;

        return {
            PageResponse: response.PageResponse,
            pageKey: responseItem.pageKey,
            pageTitle: responseItem.pageTitle,
            tables: responseItem.tables,
            multitables: responseItem.multitables,
            legend: responseItem.legend,
            buttons: responseItem.buttons,
        };
    }

    /**
     * use stored values from DownloadService to redirect after download
     *
     */
    private _redirectAfterDownload(): void {
        const storedValues = this._downloadService.getStoredValuesOnce();

        if (storedValues.operationId) {
            this.operationId = storedValues.operationId;
        }

        if (storedValues.requestData) {
            this._requestDataService.data = storedValues.requestData;
        }
    }
}
