import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { LicenseService } from './data/license.service';
import { Observable } from 'rxjs';
import { LicenseInfo } from './entity/license-info';
import { ButtonModule } from 'primeng/button';

@Component({
    selector: 'ht-licensing',
    standalone: true,
    imports: [CommonModule, TableModule, ButtonModule],
    templateUrl: './licensing.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicensingComponent implements OnInit {
    public dependencies$?: Observable<Array<LicenseInfo>>;
    public licenseText$?: Observable<string>;

    public currentLicense: string | null = null;

    constructor(protected readonly _licenseService: LicenseService) {}

    public ngOnInit(): void {
        this.dependencies$ = this._licenseService.getLicenseInfo$();
    }

    public showLicenseText(name: string, isInternal: boolean): void {
        this.currentLicense = name;
        this.licenseText$ = this._licenseService.getLicenseText(name, isInternal);
    }

    public goBackToList(): void {
        this.currentLicense = null;
    }
}
