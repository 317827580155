import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, Input, input, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { IconService } from '../../services/icon.service';

import { AppTheming, CanTheme, CanThemeConstructor, mixinTheme } from '@hrs-ui/util-component-support';
import { IconBase } from '../../definitions/icon-base';
import { combineLatest, of, switchMap } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';

const _IconMixinBase: CanThemeConstructor & typeof IconBase = mixinTheme(IconBase);

/**
 * Represents an icon
 */
@Component({
    selector: 'ui-icon',
    templateUrl: './ui-icon.component.html',
    styleUrls: ['./ui-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CommonModule,
    ],
})
export class UiIconComponent extends _IconMixinBase implements CanTheme {
    @Input()
    public override theme: AppTheming = AppTheming.Primary;

    @HostBinding('class.icon')
    private readonly _hasIconClass = true;

    /**
     * Name of the symbol in the svg sprite.
     */
    public readonly iconName = input<string | undefined>(undefined);

    /**
     * Size of the symbol in the svg sprite.
     */
    public readonly iconSize = input<number | undefined>(undefined);

    public readonly iconData: Signal<SafeHtml | undefined>;

    private readonly _iconService = inject(IconService);

    constructor() {
        super();

        this.iconData = toSignal(combineLatest([
            toObservable(this.iconName),
            toObservable(this.iconSize),
        ])
            .pipe(
                switchMap(([name, size]) => ((name && size) ? this._iconService.icon$(name, size) : of(''))),
            ));
    }
}
