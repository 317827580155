import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { SelectOptions } from '../../definitions/select-options';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { TranslationModule, TranslationService } from '@hrs-ui/translation/domain-translation';
import { MatInputModule } from '@angular/material/input';
import { UiIconComponent } from '@hrs-ui/ui/ui-icon';
import { HtSelectComponent } from '../ht-select/ht-select/ht-select.component';
import { HtInputComponent } from '../ht-input/ht-input.component';

@Component({
    selector: 'ht-text',
    templateUrl: './ht-text.component.html',
    standalone: true,
    imports: [
        FormsModule,
        MatInputModule,
        MatTooltipModule,
        UiIconComponent,
        HtInputComponent,
        TranslationModule,
        HtSelectComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class HtTextComponent {
    @Input()
    public placeholder?: string;

    @Input()
    public value?: string;

    @Input()
    public name?: string;

    @Input()
    public type = 'input';

    @Input()
    public nonNeg = false;

    @Input()
    public disabled = false;

    @Input()
    public required = false;

    @Input()
    public tabIndex = -1;

    /**
     * Decides whether this input gets autofocused when the page loads
     */
    @Input()
    public isDefaultTextEntry = false;

    @Input()
    public selectValues?: Array<SelectOptions>;

    @Output()
    public readonly valueChange = new EventEmitter<string>();

    public tooltipText?: string;
    public tooltipTextDefault?: string;

    private _tooltipTimeout?: ReturnType<typeof setTimeout>;

    private readonly _translationService = inject(TranslationService);

    constructor() {
        this._translationService.translate$('content.table.invalidInput')
            .subscribe((text: string | undefined) => {
                this.tooltipTextDefault = text;
            });
    }

    public onValueChange(event: string): void {
        this.valueChange.emit(event);
    }

    /**
     * Catch any input that doesn't match the expected type
     * Most input types validate automatically, so this is only for non-negative numerical for now.
     *
     * @param $event of type InputEvent
     * @param tooltip of type MatTooltip
     * @returns a boolean value
     */
    public validateInput($event: InputEvent, tooltip: MatTooltip): boolean {
        let isValidInput = true;

        if (this.nonNeg && $event.data && !((/^\d*$/).exec($event.data))) {
            isValidInput = false;
        }

        if (!isValidInput) {
            const tooltipCloseDelay = 1000;

            this._enableInvalidInputTooltip(tooltip);

            if (this._tooltipTimeout) {
                clearTimeout(this._tooltipTimeout);
            }

            this._tooltipTimeout = setTimeout(() => {
                this._disableInvalidInputTooltip(tooltip);
            }, tooltipCloseDelay);

            return false;
        } else {
            this._disableInvalidInputTooltip(tooltip);

            return true;
        }
    }

    private _enableInvalidInputTooltip(tooltip: MatTooltip): void {
        this.tooltipText = this.tooltipTextDefault;
        setTimeout(() => tooltip.show());
    }

    private _disableInvalidInputTooltip(tooltip: MatTooltip): void {
        tooltip.hide();
        this.tooltipText = undefined;
    }
}
