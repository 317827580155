import type { HttpClient } from '@angular/common/http';
import type { TranslateLoader } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { catchError, of } from 'rxjs';

export class TranslationProvider implements TranslateLoader {
    constructor(
        private readonly _http: HttpClient,
        public prefix: string = '/assets/i18n/',
        public suffix: string = '.json',
    ) { }

    public getTranslation(lang: string): Observable<unknown> {
        return this._http
            .get(`${ this.prefix }${ lang }${ this.suffix }`)
            .pipe(
                catchError(() => of({})),
            );
    }
}
