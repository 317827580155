import { ChangeDetectionStrategy, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { OrgUnitService, BrowserService } from '@hrs-ui/app-status/domain-app-status';
import { Subscription } from 'rxjs';
import { ModalService } from '@hrs-ui/modal/domain-modal';
import { RouterOutlet } from '@angular/router';
import { ModalComponent } from '@hrs-ui/modal/ui-modal';
import { NotificationListComponent } from '@hrs-ui/notification/ui-notification';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserLanguageService } from '@hrs-ui/translation/domain-translation';

@Component({
    selector: 'ht-ui-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        RouterOutlet,
        ModalComponent,
        NotificationListComponent,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    @ViewChild('main', { static: false })
    private readonly _mainRef?: ElementRef<HTMLDivElement>;

    public isModalOpenSub?: Subscription;

    private readonly _browserService = inject(BrowserService);
    private readonly _orgUnitService = inject(OrgUnitService);
    private readonly _modalService = inject(ModalService);

    constructor() {
        inject(UserLanguageService).initialize();
        this._orgUnitService.data$
            .subscribe(orgUnit => this._browserService.broadcastOrgUnitChangeToOtherTabsWithSameSession(orgUnit));

        // Block keyboard input while a modal is open. This prevents navigation to elements outside the modal.
        // To correctly remove the function, it must be the same object as was added, so it is defined here first.
        const keyBlockerFn = (event: KeyboardEvent): void => event.preventDefault();

        this._modalService.isModalOpen
            .pipe(
                takeUntilDestroyed(),
            )
            .subscribe(isModalOpen => {
                if (this._mainRef && isModalOpen) {
                    this._mainRef.nativeElement.addEventListener('keydown', keyBlockerFn);
                }

                if (this._mainRef && !isModalOpen) {
                    this._mainRef.nativeElement.removeEventListener('keydown', keyBlockerFn);

                }
            });
    }
}
