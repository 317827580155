import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { debounceTime, NEVER, Observable, Subject, withLatestFrom } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ApiService } from '@hrs-ui/api/util-api';
import { createAdapter } from '@state-adapt/core';
import { adaptNgrx, watchNgrx } from '@state-adapt/ngrx';
import { toSource } from '@state-adapt/rxjs';

interface Sidebar {
    width: number;
    isOpen: boolean;
}

const debounceApiSaveTime = 500;
const debounceStoreSaveTime = 50;

@Injectable({
    providedIn: 'root',
})
export class SidebarService {
    protected readonly _sidebarStorageKey: string = 'sidebar';
    protected readonly _defaultSidebarWidth = 250;

    protected readonly _apiService = inject(ApiService);

    protected _statePath = 'sidebar';
    protected _adapter = createAdapter<Sidebar>()({
        patch: (state: Sidebar, event: Partial<Sidebar>) => ({ ...state, ...event }),
    });

    protected _patch$ = new Subject<Partial<Sidebar>>();

    protected _apiUpdateAction$ = this._patch$.pipe(
        withLatestFrom(watchNgrx(this._statePath, this._adapter).state$),
        debounceTime(debounceApiSaveTime),
        switchMap(([options, currentState]) => this._setData$(options, currentState)),
        toSource('[sidebar] updated api state'),
    );

    protected _optimisticUpdateAction$ = this._patch$.pipe(
        debounceTime(debounceStoreSaveTime),
        toSource('[sidebar] updated ui state'),
    );

    protected readonly _apiGetAction$ = this._getSidebarOptions().pipe(
        catchError((err: HttpErrorResponse) => this._handleInvalidStorageKey$(err)),
        toSource('[sidebar] fetched api state'),
    );

    protected readonly _state = adaptNgrx({ width: this._defaultSidebarWidth, isOpen: true } as Sidebar, {
        path: this._statePath,
        adapter: this._adapter,
        sources: {
            set: [this._apiGetAction$, this._apiUpdateAction$],
            patch: this._optimisticUpdateAction$,
        },
    });

    public get state$(): Observable<Sidebar> {
        return this._state.state$;
    }

    public open(): void {
        this._patch$.next({ isOpen: true });
    }

    public close(): void {
        this._patch$.next({ isOpen: false });
    }

    public setWidth(width: number): void {
        this._patch$.next({ width });
    }

    /**
     * inits storage for given key in the api
     *
     * @param key
     */
    protected _initStoreKey(key: string): void {
        this._apiService.getFunction('post-me')(key, {})
            .pipe(
                take(1),
            )
            .subscribe();
    }

    protected _getSidebarOptions(): Observable<Sidebar> {
        return this._apiService.getFunction<Sidebar>('get-me')(this._sidebarStorageKey);
    }

    protected _setData$(state: Partial<Sidebar>, currentState: Sidebar): Observable<Sidebar> {
        const data = {
            width: state.width ?? currentState.width,
            isOpen: state.isOpen ?? currentState.isOpen,
        };

        return this._apiService.getFunction<undefined>('put-me')(this._sidebarStorageKey, data)
            .pipe(
                map(() => data),
            );
    }

    protected _handleInvalidStorageKey$(err: HttpErrorResponse): Observable<never> {
        // Error Code: invalid storage key
        if ((err.error as { code: string }).code === '6002') {
            this._initStoreKey(this._sidebarStorageKey);
        }

        return NEVER;
    }

}
