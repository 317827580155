/* eslint-disable @stylistic/max-len */

/**
 * hTrak HRS API-Service
 * An API allows to access API-Function through the operationId
 *
 * NOTE: This class is auto generated by generateOpIdMap (/build/js-scripts/generateOpIdMap.js).
 * Do not edit the class manually.
 */

import {
    Configuration,
    AccumulationreportApi,
    AccumulationreportfilterApi,
    AddadditionaldeviceApi,
    AddnewdevicetoprofileApi,
    Addnewdevicetoprofile2Api,
    AddproceduretimerApi,
    AddproceduretimerfilterApi,
    AnspcincurrentcontractsApi,
    AnspcincurrentcontractsfilterApi,
    AtrumclistApi,
    AtrumcnotchargeablelistApi,
    AuthApi,
    BackorderlistApi,
    BackorderlistfilterApi,
    BarwonhealthsupplierlookupApi,
    BmreportApi,
    BmreportfilterApi,
    BmwslhdreportApi,
    BmwslhdreportfilterApi,
    CardiobasedevicesApi,
    CardiobasemanufacturersApi,
    CardiobasemiscApi,
    CardiobasestaffApi,
    Cardnames4consultantApi,
    Categories4contractApi,
    Category3forcontractcategoryApi,
    Category4forcontractcategoryApi,
    Category4supplierincontractApi,
    Category5forcontractcategoryApi,
    CcdhbsupplierlookupApi,
    ChangeinventorymappingApi,
    ChangeinventoryreportApi,
    ChangeinventoryreportfilterApi,
    ChangeprocedurematchingreportApi,
    ChangeprocedurematchingreportfilterApi,
    ChgattendingstaffApi,
    ChgcontrastvolumeApi,
    ChgdeletedprocedureflagApi,
    ChgdevicedeletionApi,
    ChghfundptypeApi,
    ChgpatientrefApi,
    ChgproclocationApi,
    ChgproctimestampsApi,
    ChgselmedicationsApi,
    ChgselproctypesApi,
    ChgtimeoutanswersApi,
    CmaddproductApi,
    CmcomparepricingreportApi,
    CmcomparepricingreportfilterApi,
    CmcontractclasssupplierproductsApi,
    CmcontractexceptionsreportApi,
    CmcontractexceptionsreportfilterApi,
    CmcontractproductsApi,
    CmcontractversionvariationsApi,
    CmcontractversionvariationsfilterApi,
    CmeditcontractsApi,
    CmgpalbudgettackingreportApi,
    CmgpalbudgettrackingreportfilterApi,
    CmmenuApi,
    CmpricebanddefineApi,
    Cmproductsinacontract4supplierApi,
    Cmproductsinacontract4supplierfilterApi,
    CmproductusagelistApi,
    CmproductusagelistfilterApi,
    CmpurchproductlistApi,
    CmpurchproductlistfilterApi,
    CmspcincurrentcontractsApi,
    CmspcincurrentcontractsfilterApi,
    CmstandingordersorgpalApi,
    CmstockonhandreportApi,
    CmstockonhandreportfilterApi,
    CmstockonhandreportpdfApi,
    CmsuggestproductcodeApi,
    CmsupplierexclusionsreportApi,
    CmsupplierexclusionsreportfilterApi,
    CmsuppliersApi,
    CmtiersApi,
    CmtiersuppliersApi,
    CmuploadcontractApi,
    CmvieweditcontractsApi,
    CodespricesApi,
    CodespricesfilterApi,
    CodespricesstocktypeApi,
    CodespricesstocktypefilterApi,
    CommitprosthesespricingApi,
    Consultants4specialtyApi,
    ConsumptionsetsApi,
    ConsumptiontypeApi,
    DiagnosticsApi,
    DonotreorderreportApi,
    DonotreorderreportfilterApi,
    DownloadpurchasereqsApi,
    DownloadpurchasereqsdownloadApi,
    DownloadpurchasereqsfilterApi,
    DownloadpurchasereqspdfApi,
    EmailcmreportApi,
    EmailcmreportfilterApi,
    EmailprefcardreportApi,
    EmailprefcardreportfilterApi,
    EmailprocreportApi,
    EmailprocreportfilterApi,
    EmailreportApi,
    EmailreportadminApi,
    EmailreportadminfilterApi,
    EmailreportfilterApi,
    EmailsupplierportalreportsApi,
    EmailsupplierportalreportsfilterApi,
    ImplantablesreportApi,
    ImplantablesreportfilterApi,
    Kitcontract4supplierApi,
    Kitproducts4suppliercontractApi,
    LeadgownusagereportApi,
    LeadgownusagereportfilterApi,
    ListallcurrentactivecontractsApi,
    ListblankproductinfoApi,
    ListblankproductinfofilterApi,
    LocationupdateApi,
    MainmenuApi,
    MaintmenuApi,
    MeApi,
    MrnappointmentepisodelookupApi,
    MrnappointmentepisodelookupfilterApi,
    MrnucqepisodelookupApi,
    MrnucqepisodelookupfilterApi,
    MvpsaccumulationreportApi,
    MvpsaccumulationreportfilterApi,
    MvpsapprovallistApi,
    MvpsbackorderlistApi,
    MvpsbackorderlistfilterApi,
    MvpsdeliverydocketApi,
    MvpsdownloadpurchaseordersApi,
    MvpsdownloadpurchaseordersfilterApi,
    MvpsimprestorderdetailApi,
    MvpsimprestordersApi,
    MvpsimprestordersfilterApi,
    MvpslistApi,
    MvpslistreportApi,
    MvpslistreportfilterApi,
    MvpslistunsentApi,
    MvpsopenorderlistApi,
    MvpsopenorderlistfilterApi,
    MvpsorderpartApi,
    MvpsorderpartfilterApi,
    MvpspolinesApi,
    MvpsporeceiptingreportApi,
    MvpsporeceiptingreportfilterApi,
    MvpsposummaryApi,
    MvpspurchaseorderdetailApi,
    MvpspurchaseorderscountApi,
    MvpspurchorderdetailsApi,
    MvpspurchorderdetailsfilterApi,
    MvpspurchorderstockreceiptApi,
    MvpspurchorderstockreceipthistoryApi,
    MvpspurchorderstockreceiptscanApi,
    MvpspurchorderstockreceiptscanfilterApi,
    MvpspurchproductlistApi,
    MvpspurchproductlistfilterApi,
    MvpsunapprovedApi,
    MvpsunsubmittedApi,
    MvpsviewApi,
    NewpurchaseorderlinesApi,
    NewpurchaseordertosendApi,
    NotusedproductsreportApi,
    NotusedproductsreportfilterApi,
    NslhdpatientbillingreportApi,
    NslhdpatientbillingreportfilterApi,
    NswhealthsupplierlookupApi,
    OpenorderlistApi,
    OpenorderlistfilterApi,
    OpenorderlistfilterpoApi,
    OpenorderlistpoApi,
    OrderpartApi,
    OrderpartfilterApi,
    OrgunitApi,
    OrgunitproductfamilyApi,
    OualternativeApi,
    OucategoriesApi,
    OuconsumptionsetprocsApi,
    OucostcentrecodesApi,
    OucostcentrescanningsheetApi,
    OucostcentrescanningsheetoptionsApi,
    OudeliverylocationsApi,
    OudevconsumpdetailApi,
    OudevicedetailscanningsheetApi,
    OudevicescanningsheetoptionsApi,
    OudevicescanningsheetpdfApi,
    OudevicescanningsheetsApi,
    OudevicesinprocsApi,
    OudevicesitesApi,
    OudownloadsApi,
    OudownloadsoptionApi,
    OuhealthfundgroupsApi,
    OuhealthfundsApi,
    OuhealthfundscanningsheetApi,
    OuhealthfundscanningsheetoptionsApi,
    OukitbomApi,
    OukitscanningsheetApi,
    OukitscanningsheetoptionsApi,
    OulocationsApi,
    OulocationscanningsheetApi,
    OulocationscanningsheetoptionsApi,
    OulocationtypesApi,
    OumedicationsApi,
    OupatienttypesApi,
    OupatienttypescanningsheetApi,
    OupatienttypescanningsheetoptionsApi,
    OuproductfamilyApi,
    OurequiredbyApi,
    OurequisitiongroupsApi,
    OuscopescanningsheetApi,
    OuscopescanningsheetoptionsApi,
    OuscopeserialnumbersApi,
    OustaffreportApi,
    OustaffreportfilterApi,
    OustaffscanningsheetApi,
    OustaffscanningsheetoptionsApi,
    OustocktypesApi,
    OusublocationsApi,
    OusublocationscanningsheetApi,
    OusublocationscanningsheetoptionsApi,
    OusubsublocationsApi,
    OusupplieridApi,
    OusuppliesApi,
    OusuppliesauxApi,
    OusupplylinesApi,
    OusupplylinesauxApi,
    OusupplylinesoverviewApi,
    OutimeoutdefinesApi,
    OutimeouttabsApi,
    OutimerdefinesApi,
    OuunitsoverrideApi,
    OuusersApi,
    OuxlateApi,
    OuxlatehistoryApi,
    PcaddinstanceitemApi,
    PcadditemApi,
    PcbulkpicklistreportApi,
    PcbulkpicklistreportfilterApi,
    PcbulkpicklistreportviewApi,
    Pccontract4supplierApi,
    PcdetailsreportApi,
    PcdetailsreportfilterApi,
    PcdetailsreportpdfApi,
    PcdiathermiesApi,
    PceditApi,
    PcfileuploadApi,
    PcglobaladddeviceApi,
    PcglobalsubstituteApi,
    PcimageApi,
    PcincompletepickupreportApi,
    PcincompletepickupreportfilterApi,
    PcinstanceApi,
    PcinstancelistApi,
    PcinstrumentsApi,
    PcinstrumentsdemandreportApi,
    PcinstrumentsdemandreportfilterApi,
    PclistApi,
    PclistreportApi,
    PclistreportfilterApi,
    PcmaintainApi,
    PcmenuApi,
    PcnewApi,
    PcnewinstanceApi,
    PcnewstaffApi,
    PcpatientpositionsApi,
    PcpickdelayreasonsApi,
    PcprefcardusagereportApi,
    PcprefcardusagereportfilterApi,
    PcpreparationsApi,
    PcprintApi,
    Pcproducts4suppliercontractApi,
    PcproductsdemandreportApi,
    PcproductsdemandreportfilterApi,
    PcreplacecustomdescApi,
    PcreplacedeviceApi,
    PcreplaceprodcategoryApi,
    PcspecialitiesApi,
    PcstaffApi,
    PcsubreportApi,
    PcsubstituteApi,
    PcsubstitutelistApi,
    PcsurgicalglovesApi,
    PctheatrebookingassignApi,
    PctheatrebookingnewApi,
    PcunusedcardreportApi,
    PendingbatchorderssummaryApi,
    PendingbatchorderviewApi,
    PickingsliporderdetailApi,
    PickingslipordersApi,
    PickingslipordersfilterApi,
    PoreceiptingreportApi,
    PoreceiptingreportfilterApi,
    PrapprovalsmaintApi,
    PrefcardscanbarcodeApi,
    PrefcardupdatedevicescannedApi,
    PricinglistreleasesApi,
    ProcedurebillinglistApi,
    ProcedurebillinglistfilterApi,
    ProcedurecategoryApi,
    ProceduredetailsApi,
    ProceduredetailsfilterApi,
    ProceduredetailspdfApi,
    ProcedurelistApi,
    ProcedurelistfilterApi,
    ProceduremergeApi,
    ProcedurestatusApi,
    ProceduretimeoutreportApi,
    ProceduretimeoutreportfilterApi,
    ProceduretimestampsreportApi,
    ProceduretimestampsreportfilterApi,
    ProceduretypeApi,
    ProcmenuApi,
    ProctypeitemcodesApi,
    ProctypereportApi,
    ProctypereportfilterApi,
    ProctypescanningsheetApi,
    ProctypescanningsheetoptionsApi,
    ProctypesitesApi,
    ProctypeviewApi,
    ProdfmlydetailsbyspcApi,
    ProdfmlydetailsbyspcfilterApi,
    Products4supplierApi,
    Productsinacontract4supplierApi,
    Productsinacontract4supplierfilterApi,
    ProductusagelistApi,
    ProductusagelistfilterApi,
    ProsthesespricelistApi,
    ProsthesespricingreportApi,
    ProsthesespricingreportfilterApi,
    PurchaseorderdetailApi,
    PurchaseorderlinesApi,
    PurchaseordersummaryApi,
    PurchmenuApi,
    PurchorderdetailsApi,
    PurchorderdetailsfilterApi,
    PurchorderlistApi,
    PurchorderlistfilterApi,
    PurchorderstockreceiptApi,
    PurchorderstockreceipthistoryApi,
    PurchorderstockreceipthistorypoApi,
    PurchorderstockreceiptpoApi,
    PurchorderstockreceiptscanApi,
    PurchorderstockreceiptscanfilterApi,
    PurchorderstockreceiptscanfilterpoApi,
    PurchorderstockreceiptscanpoApi,
    PurchproductlistApi,
    PurchproductlistfilterApi,
    ReassignconsumptionApi,
    ReassignconsumptionfilterApi,
    ReorderstatusApi,
    ReorderstatusfilterApi,
    Schedule5reportApi,
    Schedule5reportfilterApi,
    Schedule5reportpdfApi,
    SjogcsvbillingreportfilterApi,
    SjogpatientbillingreportApi,
    SjogpatientbillingreportfilterApi,
    SohnondepartmentreportApi,
    SohspcdetailsApi,
    SpanspcincurrentcontractsApi,
    SpanspcincurrentcontractsfilterApi,
    SpbackorderlistApi,
    SpbackorderlistfilterApi,
    Spcs4supplierApi,
    SpdonotreorderreportApi,
    SpdonotreorderreportfilterApi,
    SpeditetadetailsApi,
    SpimplantablesreportApi,
    SpimplantablesreportfilterApi,
    SpnotusedproductsreportApi,
    SpnotusedproductsreportfilterApi,
    SpporeceiptingreportApi,
    SpporeceiptingreportfilterApi,
    SpprocedurelistApi,
    SpprocedurelistfilterApi,
    SpprocedurelistproductsApi,
    Spproductsinacontract4supplierApi,
    Spproductsinacontract4supplierfilterApi,
    SpproductusagelistApi,
    SpproductusagelistfilterApi,
    SppurchorderdetailsApi,
    SppurchorderdetailsfilterApi,
    SppurchorderlistApi,
    SppurchorderlistfilterApi,
    SppurchproductlistApi,
    SppurchproductlistfilterApi,
    Spschedule5reportApi,
    Spschedule5reportfilterApi,
    SpsohnondepartmentreportApi,
    SpsohspcdetailsApi,
    SpstockonhandreportApi,
    SpstockonhandreportfilterApi,
    SpstockreportbyspcApi,
    StaffApi,
    StaffreportemaillistApi,
    StaffrolesApi,
    StafftypeApi,
    StafftyperolesApi,
    StaffviewApi,
    StockadjustApi,
    StockadjustfilterApi,
    StockonhandreportApi,
    StockonhandreportbyexpirydateApi,
    StockonhandreportbyexpirydatefilterApi,
    StockonhandreportbyexpirydatepdfApi,
    StockonhandreportfilterApi,
    StockonhandreportpdfApi,
    StockreportbyspcApi,
    Subcategories4contractcategoryApi,
    Subcategory4supplierincontractApi,
    SuggestproductcodeApi,
    Suppliers4contractApi,
    UnsentposummaryApi,
    UnsentpurchaseordertosendApi,
    UomwprassignmentsApi,
    WccpricelistreleasesApi,
    WccpricelistsApi,
} from '@hrs-ui/apiclient';

import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, filter, take } from 'rxjs';
import { ApiService, ApiFunction } from '@hrs-ui/api/util-api';
import { ApiConfigService } from '@hrs-ui/config/domain-config';
import { BlobDownloaderUtil, XhrDownloaderUtil } from '@hrs-ui/api/domain-api';

@Injectable({
    providedIn: 'root',
})
export class ApiClientService implements ApiService {
    private readonly _functions: Record<string, ApiFunction> = {};

    private readonly _apiConfigService = inject(ApiConfigService);
    private readonly _http = inject(HttpClient);
    private readonly _xhrDownloaderUtil = inject(XhrDownloaderUtil);

    constructor() {
        this._apiConfigService.basePath$.pipe(
            filter((basePath): basePath is string => !!basePath),
            take(1),
        ).subscribe(basePath => {

            const configuration = new Configuration({ basePath });
            const accumulationreportApi = new AccumulationreportApi.AccumulationreportApi(this._http, basePath, configuration);
            const accumulationreportfilterApi = new AccumulationreportfilterApi.AccumulationreportfilterApi(this._http, basePath, configuration);
            const addadditionaldeviceApi = new AddadditionaldeviceApi.AddadditionaldeviceApi(this._http, basePath, configuration);
            const addnewdevicetoprofileApi = new AddnewdevicetoprofileApi.AddnewdevicetoprofileApi(this._http, basePath, configuration);
            const addnewdevicetoprofile2Api = new Addnewdevicetoprofile2Api.Addnewdevicetoprofile2Api(this._http, basePath, configuration);
            const addproceduretimerApi = new AddproceduretimerApi.AddproceduretimerApi(this._http, basePath, configuration);
            const addproceduretimerfilterApi = new AddproceduretimerfilterApi.AddproceduretimerfilterApi(this._http, basePath, configuration);
            const anspcincurrentcontractsApi = new AnspcincurrentcontractsApi.AnspcincurrentcontractsApi(this._http, basePath, configuration);
            const anspcincurrentcontractsfilterApi = new AnspcincurrentcontractsfilterApi.AnspcincurrentcontractsfilterApi(this._http, basePath, configuration);
            const atrumclistApi = new AtrumclistApi.AtrumclistApi(this._http, basePath, configuration);
            const atrumcnotchargeablelistApi = new AtrumcnotchargeablelistApi.AtrumcnotchargeablelistApi(this._http, basePath, configuration);
            const authApi = new AuthApi.AuthApi(this._http, basePath, configuration);
            const backorderlistApi = new BackorderlistApi.BackorderlistApi(this._http, basePath, configuration);
            const backorderlistfilterApi = new BackorderlistfilterApi.BackorderlistfilterApi(this._http, basePath, configuration);
            const barwonhealthsupplierlookupApi = new BarwonhealthsupplierlookupApi.BarwonhealthsupplierlookupApi(this._http, basePath, configuration);
            const bmreportApi = new BmreportApi.BmreportApi(this._http, basePath, configuration);
            const bmreportfilterApi = new BmreportfilterApi.BmreportfilterApi(this._http, basePath, configuration);
            const bmwslhdreportApi = new BmwslhdreportApi.BmwslhdreportApi(this._http, basePath, configuration);
            const bmwslhdreportfilterApi = new BmwslhdreportfilterApi.BmwslhdreportfilterApi(this._http, basePath, configuration);
            const cardiobasedevicesApi = new CardiobasedevicesApi.CardiobasedevicesApi(this._http, basePath, configuration);
            const cardiobasemanufacturersApi = new CardiobasemanufacturersApi.CardiobasemanufacturersApi(this._http, basePath, configuration);
            const cardiobasemiscApi = new CardiobasemiscApi.CardiobasemiscApi(this._http, basePath, configuration);
            const cardiobasestaffApi = new CardiobasestaffApi.CardiobasestaffApi(this._http, basePath, configuration);
            const cardnames4consultantApi = new Cardnames4consultantApi.Cardnames4consultantApi(this._http, basePath, configuration);
            const categories4contractApi = new Categories4contractApi.Categories4contractApi(this._http, basePath, configuration);
            const category3forcontractcategoryApi = new Category3forcontractcategoryApi.Category3forcontractcategoryApi(this._http, basePath, configuration);
            const category4forcontractcategoryApi = new Category4forcontractcategoryApi.Category4forcontractcategoryApi(this._http, basePath, configuration);
            const category4supplierincontractApi = new Category4supplierincontractApi.Category4supplierincontractApi(this._http, basePath, configuration);
            const category5forcontractcategoryApi = new Category5forcontractcategoryApi.Category5forcontractcategoryApi(this._http, basePath, configuration);
            const ccdhbsupplierlookupApi = new CcdhbsupplierlookupApi.CcdhbsupplierlookupApi(this._http, basePath, configuration);
            const changeinventorymappingApi = new ChangeinventorymappingApi.ChangeinventorymappingApi(this._http, basePath, configuration);
            const changeinventoryreportApi = new ChangeinventoryreportApi.ChangeinventoryreportApi(this._http, basePath, configuration);
            const changeinventoryreportfilterApi = new ChangeinventoryreportfilterApi.ChangeinventoryreportfilterApi(this._http, basePath, configuration);
            const changeprocedurematchingreportApi = new ChangeprocedurematchingreportApi.ChangeprocedurematchingreportApi(this._http, basePath, configuration);
            const changeprocedurematchingreportfilterApi = new ChangeprocedurematchingreportfilterApi.ChangeprocedurematchingreportfilterApi(this._http, basePath, configuration);
            const chgattendingstaffApi = new ChgattendingstaffApi.ChgattendingstaffApi(this._http, basePath, configuration);
            const chgcontrastvolumeApi = new ChgcontrastvolumeApi.ChgcontrastvolumeApi(this._http, basePath, configuration);
            const chgdeletedprocedureflagApi = new ChgdeletedprocedureflagApi.ChgdeletedprocedureflagApi(this._http, basePath, configuration);
            const chgdevicedeletionApi = new ChgdevicedeletionApi.ChgdevicedeletionApi(this._http, basePath, configuration);
            const chghfundptypeApi = new ChghfundptypeApi.ChghfundptypeApi(this._http, basePath, configuration);
            const chgpatientrefApi = new ChgpatientrefApi.ChgpatientrefApi(this._http, basePath, configuration);
            const chgproclocationApi = new ChgproclocationApi.ChgproclocationApi(this._http, basePath, configuration);
            const chgproctimestampsApi = new ChgproctimestampsApi.ChgproctimestampsApi(this._http, basePath, configuration);
            const chgselmedicationsApi = new ChgselmedicationsApi.ChgselmedicationsApi(this._http, basePath, configuration);
            const chgselproctypesApi = new ChgselproctypesApi.ChgselproctypesApi(this._http, basePath, configuration);
            const chgtimeoutanswersApi = new ChgtimeoutanswersApi.ChgtimeoutanswersApi(this._http, basePath, configuration);
            const cmaddproductApi = new CmaddproductApi.CmaddproductApi(this._http, basePath, configuration);
            const cmcomparepricingreportApi = new CmcomparepricingreportApi.CmcomparepricingreportApi(this._http, basePath, configuration);
            const cmcomparepricingreportfilterApi = new CmcomparepricingreportfilterApi.CmcomparepricingreportfilterApi(this._http, basePath, configuration);
            const cmcontractclasssupplierproductsApi = new CmcontractclasssupplierproductsApi.CmcontractclasssupplierproductsApi(this._http, basePath, configuration);
            const cmcontractexceptionsreportApi = new CmcontractexceptionsreportApi.CmcontractexceptionsreportApi(this._http, basePath, configuration);
            const cmcontractexceptionsreportfilterApi = new CmcontractexceptionsreportfilterApi.CmcontractexceptionsreportfilterApi(this._http, basePath, configuration);
            const cmcontractproductsApi = new CmcontractproductsApi.CmcontractproductsApi(this._http, basePath, configuration);
            const cmcontractversionvariationsApi = new CmcontractversionvariationsApi.CmcontractversionvariationsApi(this._http, basePath, configuration);
            const cmcontractversionvariationsfilterApi = new CmcontractversionvariationsfilterApi.CmcontractversionvariationsfilterApi(this._http, basePath, configuration);
            const cmeditcontractsApi = new CmeditcontractsApi.CmeditcontractsApi(this._http, basePath, configuration);
            const cmgpalbudgettackingreportApi = new CmgpalbudgettackingreportApi.CmgpalbudgettackingreportApi(this._http, basePath, configuration);
            const cmgpalbudgettrackingreportfilterApi = new CmgpalbudgettrackingreportfilterApi.CmgpalbudgettrackingreportfilterApi(this._http, basePath, configuration);
            const cmmenuApi = new CmmenuApi.CmmenuApi(this._http, basePath, configuration);
            const cmpricebanddefineApi = new CmpricebanddefineApi.CmpricebanddefineApi(this._http, basePath, configuration);
            const cmproductsinacontract4supplierApi = new Cmproductsinacontract4supplierApi.Cmproductsinacontract4supplierApi(this._http, basePath, configuration);
            const cmproductsinacontract4supplierfilterApi = new Cmproductsinacontract4supplierfilterApi.Cmproductsinacontract4supplierfilterApi(this._http, basePath, configuration);
            const cmproductusagelistApi = new CmproductusagelistApi.CmproductusagelistApi(this._http, basePath, configuration);
            const cmproductusagelistfilterApi = new CmproductusagelistfilterApi.CmproductusagelistfilterApi(this._http, basePath, configuration);
            const cmpurchproductlistApi = new CmpurchproductlistApi.CmpurchproductlistApi(this._http, basePath, configuration);
            const cmpurchproductlistfilterApi = new CmpurchproductlistfilterApi.CmpurchproductlistfilterApi(this._http, basePath, configuration);
            const cmspcincurrentcontractsApi = new CmspcincurrentcontractsApi.CmspcincurrentcontractsApi(this._http, basePath, configuration);
            const cmspcincurrentcontractsfilterApi = new CmspcincurrentcontractsfilterApi.CmspcincurrentcontractsfilterApi(this._http, basePath, configuration);
            const cmstandingordersorgpalApi = new CmstandingordersorgpalApi.CmstandingordersorgpalApi(this._http, basePath, configuration);
            const cmstockonhandreportApi = new CmstockonhandreportApi.CmstockonhandreportApi(this._http, basePath, configuration);
            const cmstockonhandreportfilterApi = new CmstockonhandreportfilterApi.CmstockonhandreportfilterApi(this._http, basePath, configuration);
            const cmstockonhandreportpdfApi = new CmstockonhandreportpdfApi.CmstockonhandreportpdfApi(this._http, basePath, configuration);
            const cmsuggestproductcodeApi = new CmsuggestproductcodeApi.CmsuggestproductcodeApi(this._http, basePath, configuration);
            const cmsupplierexclusionsreportApi = new CmsupplierexclusionsreportApi.CmsupplierexclusionsreportApi(this._http, basePath, configuration);
            const cmsupplierexclusionsreportfilterApi = new CmsupplierexclusionsreportfilterApi.CmsupplierexclusionsreportfilterApi(this._http, basePath, configuration);
            const cmsuppliersApi = new CmsuppliersApi.CmsuppliersApi(this._http, basePath, configuration);
            const cmtiersApi = new CmtiersApi.CmtiersApi(this._http, basePath, configuration);
            const cmtiersuppliersApi = new CmtiersuppliersApi.CmtiersuppliersApi(this._http, basePath, configuration);
            const cmuploadcontractApi = new CmuploadcontractApi.CmuploadcontractApi(this._http, basePath, configuration);
            const cmvieweditcontractsApi = new CmvieweditcontractsApi.CmvieweditcontractsApi(this._http, basePath, configuration);
            const codespricesApi = new CodespricesApi.CodespricesApi(this._http, basePath, configuration);
            const codespricesfilterApi = new CodespricesfilterApi.CodespricesfilterApi(this._http, basePath, configuration);
            const codespricesstocktypeApi = new CodespricesstocktypeApi.CodespricesstocktypeApi(this._http, basePath, configuration);
            const codespricesstocktypefilterApi = new CodespricesstocktypefilterApi.CodespricesstocktypefilterApi(this._http, basePath, configuration);
            const commitprosthesespricingApi = new CommitprosthesespricingApi.CommitprosthesespricingApi(this._http, basePath, configuration);
            const consultants4specialtyApi = new Consultants4specialtyApi.Consultants4specialtyApi(this._http, basePath, configuration);
            const consumptionsetsApi = new ConsumptionsetsApi.ConsumptionsetsApi(this._http, basePath, configuration);
            const consumptiontypeApi = new ConsumptiontypeApi.ConsumptiontypeApi(this._http, basePath, configuration);
            const diagnosticsApi = new DiagnosticsApi.DiagnosticsApi(this._http, basePath, configuration);
            const donotreorderreportApi = new DonotreorderreportApi.DonotreorderreportApi(this._http, basePath, configuration);
            const donotreorderreportfilterApi = new DonotreorderreportfilterApi.DonotreorderreportfilterApi(this._http, basePath, configuration);
            const downloadpurchasereqsApi = new DownloadpurchasereqsApi.DownloadpurchasereqsApi(this._http, basePath, configuration);
            const downloadpurchasereqsdownloadApi = new DownloadpurchasereqsdownloadApi.DownloadpurchasereqsdownloadApi(this._http, basePath, configuration);
            const downloadpurchasereqsfilterApi = new DownloadpurchasereqsfilterApi.DownloadpurchasereqsfilterApi(this._http, basePath, configuration);
            const downloadpurchasereqspdfApi = new DownloadpurchasereqspdfApi.DownloadpurchasereqspdfApi(this._http, basePath, configuration);
            const emailcmreportApi = new EmailcmreportApi.EmailcmreportApi(this._http, basePath, configuration);
            const emailcmreportfilterApi = new EmailcmreportfilterApi.EmailcmreportfilterApi(this._http, basePath, configuration);
            const emailprefcardreportApi = new EmailprefcardreportApi.EmailprefcardreportApi(this._http, basePath, configuration);
            const emailprefcardreportfilterApi = new EmailprefcardreportfilterApi.EmailprefcardreportfilterApi(this._http, basePath, configuration);
            const emailprocreportApi = new EmailprocreportApi.EmailprocreportApi(this._http, basePath, configuration);
            const emailprocreportfilterApi = new EmailprocreportfilterApi.EmailprocreportfilterApi(this._http, basePath, configuration);
            const emailreportApi = new EmailreportApi.EmailreportApi(this._http, basePath, configuration);
            const emailreportadminApi = new EmailreportadminApi.EmailreportadminApi(this._http, basePath, configuration);
            const emailreportadminfilterApi = new EmailreportadminfilterApi.EmailreportadminfilterApi(this._http, basePath, configuration);
            const emailreportfilterApi = new EmailreportfilterApi.EmailreportfilterApi(this._http, basePath, configuration);
            const emailsupplierportalreportsApi = new EmailsupplierportalreportsApi.EmailsupplierportalreportsApi(this._http, basePath, configuration);
            const emailsupplierportalreportsfilterApi = new EmailsupplierportalreportsfilterApi.EmailsupplierportalreportsfilterApi(this._http, basePath, configuration);
            const implantablesreportApi = new ImplantablesreportApi.ImplantablesreportApi(this._http, basePath, configuration);
            const implantablesreportfilterApi = new ImplantablesreportfilterApi.ImplantablesreportfilterApi(this._http, basePath, configuration);
            const kitcontract4supplierApi = new Kitcontract4supplierApi.Kitcontract4supplierApi(this._http, basePath, configuration);
            const kitproducts4suppliercontractApi = new Kitproducts4suppliercontractApi.Kitproducts4suppliercontractApi(this._http, basePath, configuration);
            const leadgownusagereportApi = new LeadgownusagereportApi.LeadgownusagereportApi(this._http, basePath, configuration);
            const leadgownusagereportfilterApi = new LeadgownusagereportfilterApi.LeadgownusagereportfilterApi(this._http, basePath, configuration);
            const listallcurrentactivecontractsApi = new ListallcurrentactivecontractsApi.ListallcurrentactivecontractsApi(this._http, basePath, configuration);
            const listblankproductinfoApi = new ListblankproductinfoApi.ListblankproductinfoApi(this._http, basePath, configuration);
            const listblankproductinfofilterApi = new ListblankproductinfofilterApi.ListblankproductinfofilterApi(this._http, basePath, configuration);
            const locationupdateApi = new LocationupdateApi.LocationupdateApi(this._http, basePath, configuration);
            const mainmenuApi = new MainmenuApi.MainmenuApi(this._http, basePath, configuration);
            const maintmenuApi = new MaintmenuApi.MaintmenuApi(this._http, basePath, configuration);
            const meApi = new MeApi.MeApi(this._http, basePath, configuration);
            const mrnappointmentepisodelookupApi = new MrnappointmentepisodelookupApi.MrnappointmentepisodelookupApi(this._http, basePath, configuration);
            const mrnappointmentepisodelookupfilterApi = new MrnappointmentepisodelookupfilterApi.MrnappointmentepisodelookupfilterApi(this._http, basePath, configuration);
            const mrnucqepisodelookupApi = new MrnucqepisodelookupApi.MrnucqepisodelookupApi(this._http, basePath, configuration);
            const mrnucqepisodelookupfilterApi = new MrnucqepisodelookupfilterApi.MrnucqepisodelookupfilterApi(this._http, basePath, configuration);
            const mvpsaccumulationreportApi = new MvpsaccumulationreportApi.MvpsaccumulationreportApi(this._http, basePath, configuration);
            const mvpsaccumulationreportfilterApi = new MvpsaccumulationreportfilterApi.MvpsaccumulationreportfilterApi(this._http, basePath, configuration);
            const mvpsapprovallistApi = new MvpsapprovallistApi.MvpsapprovallistApi(this._http, basePath, configuration);
            const mvpsbackorderlistApi = new MvpsbackorderlistApi.MvpsbackorderlistApi(this._http, basePath, configuration);
            const mvpsbackorderlistfilterApi = new MvpsbackorderlistfilterApi.MvpsbackorderlistfilterApi(this._http, basePath, configuration);
            const mvpsdeliverydocketApi = new MvpsdeliverydocketApi.MvpsdeliverydocketApi(this._http, basePath, configuration);
            const mvpsdownloadpurchaseordersApi = new MvpsdownloadpurchaseordersApi.MvpsdownloadpurchaseordersApi(this._http, basePath, configuration);
            const mvpsdownloadpurchaseordersfilterApi = new MvpsdownloadpurchaseordersfilterApi.MvpsdownloadpurchaseordersfilterApi(this._http, basePath, configuration);
            const mvpsimprestorderdetailApi = new MvpsimprestorderdetailApi.MvpsimprestorderdetailApi(this._http, basePath, configuration);
            const mvpsimprestordersApi = new MvpsimprestordersApi.MvpsimprestordersApi(this._http, basePath, configuration);
            const mvpsimprestordersfilterApi = new MvpsimprestordersfilterApi.MvpsimprestordersfilterApi(this._http, basePath, configuration);
            const mvpslistApi = new MvpslistApi.MvpslistApi(this._http, basePath, configuration);
            const mvpslistreportApi = new MvpslistreportApi.MvpslistreportApi(this._http, basePath, configuration);
            const mvpslistreportfilterApi = new MvpslistreportfilterApi.MvpslistreportfilterApi(this._http, basePath, configuration);
            const mvpslistunsentApi = new MvpslistunsentApi.MvpslistunsentApi(this._http, basePath, configuration);
            const mvpsopenorderlistApi = new MvpsopenorderlistApi.MvpsopenorderlistApi(this._http, basePath, configuration);
            const mvpsopenorderlistfilterApi = new MvpsopenorderlistfilterApi.MvpsopenorderlistfilterApi(this._http, basePath, configuration);
            const mvpsorderpartApi = new MvpsorderpartApi.MvpsorderpartApi(this._http, basePath, configuration);
            const mvpsorderpartfilterApi = new MvpsorderpartfilterApi.MvpsorderpartfilterApi(this._http, basePath, configuration);
            const mvpspolinesApi = new MvpspolinesApi.MvpspolinesApi(this._http, basePath, configuration);
            const mvpsporeceiptingreportApi = new MvpsporeceiptingreportApi.MvpsporeceiptingreportApi(this._http, basePath, configuration);
            const mvpsporeceiptingreportfilterApi = new MvpsporeceiptingreportfilterApi.MvpsporeceiptingreportfilterApi(this._http, basePath, configuration);
            const mvpsposummaryApi = new MvpsposummaryApi.MvpsposummaryApi(this._http, basePath, configuration);
            const mvpspurchaseorderdetailApi = new MvpspurchaseorderdetailApi.MvpspurchaseorderdetailApi(this._http, basePath, configuration);
            const mvpspurchaseorderscountApi = new MvpspurchaseorderscountApi.MvpspurchaseorderscountApi(this._http, basePath, configuration);
            const mvpspurchorderdetailsApi = new MvpspurchorderdetailsApi.MvpspurchorderdetailsApi(this._http, basePath, configuration);
            const mvpspurchorderdetailsfilterApi = new MvpspurchorderdetailsfilterApi.MvpspurchorderdetailsfilterApi(this._http, basePath, configuration);
            const mvpspurchorderstockreceiptApi = new MvpspurchorderstockreceiptApi.MvpspurchorderstockreceiptApi(this._http, basePath, configuration);
            const mvpspurchorderstockreceipthistoryApi = new MvpspurchorderstockreceipthistoryApi.MvpspurchorderstockreceipthistoryApi(this._http, basePath, configuration);
            const mvpspurchorderstockreceiptscanApi = new MvpspurchorderstockreceiptscanApi.MvpspurchorderstockreceiptscanApi(this._http, basePath, configuration);
            const mvpspurchorderstockreceiptscanfilterApi = new MvpspurchorderstockreceiptscanfilterApi.MvpspurchorderstockreceiptscanfilterApi(this._http, basePath, configuration);
            const mvpspurchproductlistApi = new MvpspurchproductlistApi.MvpspurchproductlistApi(this._http, basePath, configuration);
            const mvpspurchproductlistfilterApi = new MvpspurchproductlistfilterApi.MvpspurchproductlistfilterApi(this._http, basePath, configuration);
            const mvpsunapprovedApi = new MvpsunapprovedApi.MvpsunapprovedApi(this._http, basePath, configuration);
            const mvpsunsubmittedApi = new MvpsunsubmittedApi.MvpsunsubmittedApi(this._http, basePath, configuration);
            const mvpsviewApi = new MvpsviewApi.MvpsviewApi(this._http, basePath, configuration);
            const newpurchaseorderlinesApi = new NewpurchaseorderlinesApi.NewpurchaseorderlinesApi(this._http, basePath, configuration);
            const newpurchaseordertosendApi = new NewpurchaseordertosendApi.NewpurchaseordertosendApi(this._http, basePath, configuration);
            const notusedproductsreportApi = new NotusedproductsreportApi.NotusedproductsreportApi(this._http, basePath, configuration);
            const notusedproductsreportfilterApi = new NotusedproductsreportfilterApi.NotusedproductsreportfilterApi(this._http, basePath, configuration);
            const nslhdpatientbillingreportApi = new NslhdpatientbillingreportApi.NslhdpatientbillingreportApi(this._http, basePath, configuration);
            const nslhdpatientbillingreportfilterApi = new NslhdpatientbillingreportfilterApi.NslhdpatientbillingreportfilterApi(this._http, basePath, configuration);
            const nswhealthsupplierlookupApi = new NswhealthsupplierlookupApi.NswhealthsupplierlookupApi(this._http, basePath, configuration);
            const openorderlistApi = new OpenorderlistApi.OpenorderlistApi(this._http, basePath, configuration);
            const openorderlistfilterApi = new OpenorderlistfilterApi.OpenorderlistfilterApi(this._http, basePath, configuration);
            const openorderlistfilterpoApi = new OpenorderlistfilterpoApi.OpenorderlistfilterpoApi(this._http, basePath, configuration);
            const openorderlistpoApi = new OpenorderlistpoApi.OpenorderlistpoApi(this._http, basePath, configuration);
            const orderpartApi = new OrderpartApi.OrderpartApi(this._http, basePath, configuration);
            const orderpartfilterApi = new OrderpartfilterApi.OrderpartfilterApi(this._http, basePath, configuration);
            const orgunitApi = new OrgunitApi.OrgunitApi(this._http, basePath, configuration);
            const orgunitproductfamilyApi = new OrgunitproductfamilyApi.OrgunitproductfamilyApi(this._http, basePath, configuration);
            const oualternativeApi = new OualternativeApi.OualternativeApi(this._http, basePath, configuration);
            const oucategoriesApi = new OucategoriesApi.OucategoriesApi(this._http, basePath, configuration);
            const ouconsumptionsetprocsApi = new OuconsumptionsetprocsApi.OuconsumptionsetprocsApi(this._http, basePath, configuration);
            const oucostcentrecodesApi = new OucostcentrecodesApi.OucostcentrecodesApi(this._http, basePath, configuration);
            const oucostcentrescanningsheetApi = new OucostcentrescanningsheetApi.OucostcentrescanningsheetApi(this._http, basePath, configuration);
            const oucostcentrescanningsheetoptionsApi = new OucostcentrescanningsheetoptionsApi.OucostcentrescanningsheetoptionsApi(this._http, basePath, configuration);
            const oudeliverylocationsApi = new OudeliverylocationsApi.OudeliverylocationsApi(this._http, basePath, configuration);
            const oudevconsumpdetailApi = new OudevconsumpdetailApi.OudevconsumpdetailApi(this._http, basePath, configuration);
            const oudevicedetailscanningsheetApi = new OudevicedetailscanningsheetApi.OudevicedetailscanningsheetApi(this._http, basePath, configuration);
            const oudevicescanningsheetoptionsApi = new OudevicescanningsheetoptionsApi.OudevicescanningsheetoptionsApi(this._http, basePath, configuration);
            const oudevicescanningsheetpdfApi = new OudevicescanningsheetpdfApi.OudevicescanningsheetpdfApi(this._http, basePath, configuration);
            const oudevicescanningsheetsApi = new OudevicescanningsheetsApi.OudevicescanningsheetsApi(this._http, basePath, configuration);
            const oudevicesinprocsApi = new OudevicesinprocsApi.OudevicesinprocsApi(this._http, basePath, configuration);
            const oudevicesitesApi = new OudevicesitesApi.OudevicesitesApi(this._http, basePath, configuration);
            const oudownloadsApi = new OudownloadsApi.OudownloadsApi(this._http, basePath, configuration);
            const oudownloadsoptionApi = new OudownloadsoptionApi.OudownloadsoptionApi(this._http, basePath, configuration);
            const ouhealthfundgroupsApi = new OuhealthfundgroupsApi.OuhealthfundgroupsApi(this._http, basePath, configuration);
            const ouhealthfundsApi = new OuhealthfundsApi.OuhealthfundsApi(this._http, basePath, configuration);
            const ouhealthfundscanningsheetApi = new OuhealthfundscanningsheetApi.OuhealthfundscanningsheetApi(this._http, basePath, configuration);
            const ouhealthfundscanningsheetoptionsApi = new OuhealthfundscanningsheetoptionsApi.OuhealthfundscanningsheetoptionsApi(this._http, basePath, configuration);
            const oukitbomApi = new OukitbomApi.OukitbomApi(this._http, basePath, configuration);
            const oukitscanningsheetApi = new OukitscanningsheetApi.OukitscanningsheetApi(this._http, basePath, configuration);
            const oukitscanningsheetoptionsApi = new OukitscanningsheetoptionsApi.OukitscanningsheetoptionsApi(this._http, basePath, configuration);
            const oulocationsApi = new OulocationsApi.OulocationsApi(this._http, basePath, configuration);
            const oulocationscanningsheetApi = new OulocationscanningsheetApi.OulocationscanningsheetApi(this._http, basePath, configuration);
            const oulocationscanningsheetoptionsApi = new OulocationscanningsheetoptionsApi.OulocationscanningsheetoptionsApi(this._http, basePath, configuration);
            const oulocationtypesApi = new OulocationtypesApi.OulocationtypesApi(this._http, basePath, configuration);
            const oumedicationsApi = new OumedicationsApi.OumedicationsApi(this._http, basePath, configuration);
            const oupatienttypesApi = new OupatienttypesApi.OupatienttypesApi(this._http, basePath, configuration);
            const oupatienttypescanningsheetApi = new OupatienttypescanningsheetApi.OupatienttypescanningsheetApi(this._http, basePath, configuration);
            const oupatienttypescanningsheetoptionsApi = new OupatienttypescanningsheetoptionsApi.OupatienttypescanningsheetoptionsApi(this._http, basePath, configuration);
            const ouproductfamilyApi = new OuproductfamilyApi.OuproductfamilyApi(this._http, basePath, configuration);
            const ourequiredbyApi = new OurequiredbyApi.OurequiredbyApi(this._http, basePath, configuration);
            const ourequisitiongroupsApi = new OurequisitiongroupsApi.OurequisitiongroupsApi(this._http, basePath, configuration);
            const ouscopescanningsheetApi = new OuscopescanningsheetApi.OuscopescanningsheetApi(this._http, basePath, configuration);
            const ouscopescanningsheetoptionsApi = new OuscopescanningsheetoptionsApi.OuscopescanningsheetoptionsApi(this._http, basePath, configuration);
            const ouscopeserialnumbersApi = new OuscopeserialnumbersApi.OuscopeserialnumbersApi(this._http, basePath, configuration);
            const oustaffreportApi = new OustaffreportApi.OustaffreportApi(this._http, basePath, configuration);
            const oustaffreportfilterApi = new OustaffreportfilterApi.OustaffreportfilterApi(this._http, basePath, configuration);
            const oustaffscanningsheetApi = new OustaffscanningsheetApi.OustaffscanningsheetApi(this._http, basePath, configuration);
            const oustaffscanningsheetoptionsApi = new OustaffscanningsheetoptionsApi.OustaffscanningsheetoptionsApi(this._http, basePath, configuration);
            const oustocktypesApi = new OustocktypesApi.OustocktypesApi(this._http, basePath, configuration);
            const ousublocationsApi = new OusublocationsApi.OusublocationsApi(this._http, basePath, configuration);
            const ousublocationscanningsheetApi = new OusublocationscanningsheetApi.OusublocationscanningsheetApi(this._http, basePath, configuration);
            const ousublocationscanningsheetoptionsApi = new OusublocationscanningsheetoptionsApi.OusublocationscanningsheetoptionsApi(this._http, basePath, configuration);
            const ousubsublocationsApi = new OusubsublocationsApi.OusubsublocationsApi(this._http, basePath, configuration);
            const ousupplieridApi = new OusupplieridApi.OusupplieridApi(this._http, basePath, configuration);
            const ousuppliesApi = new OusuppliesApi.OusuppliesApi(this._http, basePath, configuration);
            const ousuppliesauxApi = new OusuppliesauxApi.OusuppliesauxApi(this._http, basePath, configuration);
            const ousupplylinesApi = new OusupplylinesApi.OusupplylinesApi(this._http, basePath, configuration);
            const ousupplylinesauxApi = new OusupplylinesauxApi.OusupplylinesauxApi(this._http, basePath, configuration);
            const ousupplylinesoverviewApi = new OusupplylinesoverviewApi.OusupplylinesoverviewApi(this._http, basePath, configuration);
            const outimeoutdefinesApi = new OutimeoutdefinesApi.OutimeoutdefinesApi(this._http, basePath, configuration);
            const outimeouttabsApi = new OutimeouttabsApi.OutimeouttabsApi(this._http, basePath, configuration);
            const outimerdefinesApi = new OutimerdefinesApi.OutimerdefinesApi(this._http, basePath, configuration);
            const ouunitsoverrideApi = new OuunitsoverrideApi.OuunitsoverrideApi(this._http, basePath, configuration);
            const ouusersApi = new OuusersApi.OuusersApi(this._http, basePath, configuration);
            const ouxlateApi = new OuxlateApi.OuxlateApi(this._http, basePath, configuration);
            const ouxlatehistoryApi = new OuxlatehistoryApi.OuxlatehistoryApi(this._http, basePath, configuration);
            const pcaddinstanceitemApi = new PcaddinstanceitemApi.PcaddinstanceitemApi(this._http, basePath, configuration);
            const pcadditemApi = new PcadditemApi.PcadditemApi(this._http, basePath, configuration);
            const pcbulkpicklistreportApi = new PcbulkpicklistreportApi.PcbulkpicklistreportApi(this._http, basePath, configuration);
            const pcbulkpicklistreportfilterApi = new PcbulkpicklistreportfilterApi.PcbulkpicklistreportfilterApi(this._http, basePath, configuration);
            const pcbulkpicklistreportviewApi = new PcbulkpicklistreportviewApi.PcbulkpicklistreportviewApi(this._http, basePath, configuration);
            const pccontract4supplierApi = new Pccontract4supplierApi.Pccontract4supplierApi(this._http, basePath, configuration);
            const pcdetailsreportApi = new PcdetailsreportApi.PcdetailsreportApi(this._http, basePath, configuration);
            const pcdetailsreportfilterApi = new PcdetailsreportfilterApi.PcdetailsreportfilterApi(this._http, basePath, configuration);
            const pcdetailsreportpdfApi = new PcdetailsreportpdfApi.PcdetailsreportpdfApi(this._http, basePath, configuration);
            const pcdiathermiesApi = new PcdiathermiesApi.PcdiathermiesApi(this._http, basePath, configuration);
            const pceditApi = new PceditApi.PceditApi(this._http, basePath, configuration);
            const pcfileuploadApi = new PcfileuploadApi.PcfileuploadApi(this._http, basePath, configuration);
            const pcglobaladddeviceApi = new PcglobaladddeviceApi.PcglobaladddeviceApi(this._http, basePath, configuration);
            const pcglobalsubstituteApi = new PcglobalsubstituteApi.PcglobalsubstituteApi(this._http, basePath, configuration);
            const pcimageApi = new PcimageApi.PcimageApi(this._http, basePath, configuration);
            const pcincompletepickupreportApi = new PcincompletepickupreportApi.PcincompletepickupreportApi(this._http, basePath, configuration);
            const pcincompletepickupreportfilterApi = new PcincompletepickupreportfilterApi.PcincompletepickupreportfilterApi(this._http, basePath, configuration);
            const pcinstanceApi = new PcinstanceApi.PcinstanceApi(this._http, basePath, configuration);
            const pcinstancelistApi = new PcinstancelistApi.PcinstancelistApi(this._http, basePath, configuration);
            const pcinstrumentsApi = new PcinstrumentsApi.PcinstrumentsApi(this._http, basePath, configuration);
            const pcinstrumentsdemandreportApi = new PcinstrumentsdemandreportApi.PcinstrumentsdemandreportApi(this._http, basePath, configuration);
            const pcinstrumentsdemandreportfilterApi = new PcinstrumentsdemandreportfilterApi.PcinstrumentsdemandreportfilterApi(this._http, basePath, configuration);
            const pclistApi = new PclistApi.PclistApi(this._http, basePath, configuration);
            const pclistreportApi = new PclistreportApi.PclistreportApi(this._http, basePath, configuration);
            const pclistreportfilterApi = new PclistreportfilterApi.PclistreportfilterApi(this._http, basePath, configuration);
            const pcmaintainApi = new PcmaintainApi.PcmaintainApi(this._http, basePath, configuration);
            const pcmenuApi = new PcmenuApi.PcmenuApi(this._http, basePath, configuration);
            const pcnewApi = new PcnewApi.PcnewApi(this._http, basePath, configuration);
            const pcnewinstanceApi = new PcnewinstanceApi.PcnewinstanceApi(this._http, basePath, configuration);
            const pcnewstaffApi = new PcnewstaffApi.PcnewstaffApi(this._http, basePath, configuration);
            const pcpatientpositionsApi = new PcpatientpositionsApi.PcpatientpositionsApi(this._http, basePath, configuration);
            const pcpickdelayreasonsApi = new PcpickdelayreasonsApi.PcpickdelayreasonsApi(this._http, basePath, configuration);
            const pcprefcardusagereportApi = new PcprefcardusagereportApi.PcprefcardusagereportApi(this._http, basePath, configuration);
            const pcprefcardusagereportfilterApi = new PcprefcardusagereportfilterApi.PcprefcardusagereportfilterApi(this._http, basePath, configuration);
            const pcpreparationsApi = new PcpreparationsApi.PcpreparationsApi(this._http, basePath, configuration);
            const pcprintApi = new PcprintApi.PcprintApi(this._http, basePath, configuration);
            const pcproducts4suppliercontractApi = new Pcproducts4suppliercontractApi.Pcproducts4suppliercontractApi(this._http, basePath, configuration);
            const pcproductsdemandreportApi = new PcproductsdemandreportApi.PcproductsdemandreportApi(this._http, basePath, configuration);
            const pcproductsdemandreportfilterApi = new PcproductsdemandreportfilterApi.PcproductsdemandreportfilterApi(this._http, basePath, configuration);
            const pcreplacecustomdescApi = new PcreplacecustomdescApi.PcreplacecustomdescApi(this._http, basePath, configuration);
            const pcreplacedeviceApi = new PcreplacedeviceApi.PcreplacedeviceApi(this._http, basePath, configuration);
            const pcreplaceprodcategoryApi = new PcreplaceprodcategoryApi.PcreplaceprodcategoryApi(this._http, basePath, configuration);
            const pcspecialitiesApi = new PcspecialitiesApi.PcspecialitiesApi(this._http, basePath, configuration);
            const pcstaffApi = new PcstaffApi.PcstaffApi(this._http, basePath, configuration);
            const pcsubreportApi = new PcsubreportApi.PcsubreportApi(this._http, basePath, configuration);
            const pcsubstituteApi = new PcsubstituteApi.PcsubstituteApi(this._http, basePath, configuration);
            const pcsubstitutelistApi = new PcsubstitutelistApi.PcsubstitutelistApi(this._http, basePath, configuration);
            const pcsurgicalglovesApi = new PcsurgicalglovesApi.PcsurgicalglovesApi(this._http, basePath, configuration);
            const pctheatrebookingassignApi = new PctheatrebookingassignApi.PctheatrebookingassignApi(this._http, basePath, configuration);
            const pctheatrebookingnewApi = new PctheatrebookingnewApi.PctheatrebookingnewApi(this._http, basePath, configuration);
            const pcunusedcardreportApi = new PcunusedcardreportApi.PcunusedcardreportApi(this._http, basePath, configuration);
            const pendingbatchorderssummaryApi = new PendingbatchorderssummaryApi.PendingbatchorderssummaryApi(this._http, basePath, configuration);
            const pendingbatchorderviewApi = new PendingbatchorderviewApi.PendingbatchorderviewApi(this._http, basePath, configuration);
            const pickingsliporderdetailApi = new PickingsliporderdetailApi.PickingsliporderdetailApi(this._http, basePath, configuration);
            const pickingslipordersApi = new PickingslipordersApi.PickingslipordersApi(this._http, basePath, configuration);
            const pickingslipordersfilterApi = new PickingslipordersfilterApi.PickingslipordersfilterApi(this._http, basePath, configuration);
            const poreceiptingreportApi = new PoreceiptingreportApi.PoreceiptingreportApi(this._http, basePath, configuration);
            const poreceiptingreportfilterApi = new PoreceiptingreportfilterApi.PoreceiptingreportfilterApi(this._http, basePath, configuration);
            const prapprovalsmaintApi = new PrapprovalsmaintApi.PrapprovalsmaintApi(this._http, basePath, configuration);
            const prefcardscanbarcodeApi = new PrefcardscanbarcodeApi.PrefcardscanbarcodeApi(this._http, basePath, configuration);
            const prefcardupdatedevicescannedApi = new PrefcardupdatedevicescannedApi.PrefcardupdatedevicescannedApi(this._http, basePath, configuration);
            const pricinglistreleasesApi = new PricinglistreleasesApi.PricinglistreleasesApi(this._http, basePath, configuration);
            const procedurebillinglistApi = new ProcedurebillinglistApi.ProcedurebillinglistApi(this._http, basePath, configuration);
            const procedurebillinglistfilterApi = new ProcedurebillinglistfilterApi.ProcedurebillinglistfilterApi(this._http, basePath, configuration);
            const procedurecategoryApi = new ProcedurecategoryApi.ProcedurecategoryApi(this._http, basePath, configuration);
            const proceduredetailsApi = new ProceduredetailsApi.ProceduredetailsApi(this._http, basePath, configuration);
            const proceduredetailsfilterApi = new ProceduredetailsfilterApi.ProceduredetailsfilterApi(this._http, basePath, configuration);
            const proceduredetailspdfApi = new ProceduredetailspdfApi.ProceduredetailspdfApi(this._http, basePath, configuration);
            const procedurelistApi = new ProcedurelistApi.ProcedurelistApi(this._http, basePath, configuration);
            const procedurelistfilterApi = new ProcedurelistfilterApi.ProcedurelistfilterApi(this._http, basePath, configuration);
            const proceduremergeApi = new ProceduremergeApi.ProceduremergeApi(this._http, basePath, configuration);
            const procedurestatusApi = new ProcedurestatusApi.ProcedurestatusApi(this._http, basePath, configuration);
            const proceduretimeoutreportApi = new ProceduretimeoutreportApi.ProceduretimeoutreportApi(this._http, basePath, configuration);
            const proceduretimeoutreportfilterApi = new ProceduretimeoutreportfilterApi.ProceduretimeoutreportfilterApi(this._http, basePath, configuration);
            const proceduretimestampsreportApi = new ProceduretimestampsreportApi.ProceduretimestampsreportApi(this._http, basePath, configuration);
            const proceduretimestampsreportfilterApi = new ProceduretimestampsreportfilterApi.ProceduretimestampsreportfilterApi(this._http, basePath, configuration);
            const proceduretypeApi = new ProceduretypeApi.ProceduretypeApi(this._http, basePath, configuration);
            const procmenuApi = new ProcmenuApi.ProcmenuApi(this._http, basePath, configuration);
            const proctypeitemcodesApi = new ProctypeitemcodesApi.ProctypeitemcodesApi(this._http, basePath, configuration);
            const proctypereportApi = new ProctypereportApi.ProctypereportApi(this._http, basePath, configuration);
            const proctypereportfilterApi = new ProctypereportfilterApi.ProctypereportfilterApi(this._http, basePath, configuration);
            const proctypescanningsheetApi = new ProctypescanningsheetApi.ProctypescanningsheetApi(this._http, basePath, configuration);
            const proctypescanningsheetoptionsApi = new ProctypescanningsheetoptionsApi.ProctypescanningsheetoptionsApi(this._http, basePath, configuration);
            const proctypesitesApi = new ProctypesitesApi.ProctypesitesApi(this._http, basePath, configuration);
            const proctypeviewApi = new ProctypeviewApi.ProctypeviewApi(this._http, basePath, configuration);
            const prodfmlydetailsbyspcApi = new ProdfmlydetailsbyspcApi.ProdfmlydetailsbyspcApi(this._http, basePath, configuration);
            const prodfmlydetailsbyspcfilterApi = new ProdfmlydetailsbyspcfilterApi.ProdfmlydetailsbyspcfilterApi(this._http, basePath, configuration);
            const products4supplierApi = new Products4supplierApi.Products4supplierApi(this._http, basePath, configuration);
            const productsinacontract4supplierApi = new Productsinacontract4supplierApi.Productsinacontract4supplierApi(this._http, basePath, configuration);
            const productsinacontract4supplierfilterApi = new Productsinacontract4supplierfilterApi.Productsinacontract4supplierfilterApi(this._http, basePath, configuration);
            const productusagelistApi = new ProductusagelistApi.ProductusagelistApi(this._http, basePath, configuration);
            const productusagelistfilterApi = new ProductusagelistfilterApi.ProductusagelistfilterApi(this._http, basePath, configuration);
            const prosthesespricelistApi = new ProsthesespricelistApi.ProsthesespricelistApi(this._http, basePath, configuration);
            const prosthesespricingreportApi = new ProsthesespricingreportApi.ProsthesespricingreportApi(this._http, basePath, configuration);
            const prosthesespricingreportfilterApi = new ProsthesespricingreportfilterApi.ProsthesespricingreportfilterApi(this._http, basePath, configuration);
            const purchaseorderdetailApi = new PurchaseorderdetailApi.PurchaseorderdetailApi(this._http, basePath, configuration);
            const purchaseorderlinesApi = new PurchaseorderlinesApi.PurchaseorderlinesApi(this._http, basePath, configuration);
            const purchaseordersummaryApi = new PurchaseordersummaryApi.PurchaseordersummaryApi(this._http, basePath, configuration);
            const purchmenuApi = new PurchmenuApi.PurchmenuApi(this._http, basePath, configuration);
            const purchorderdetailsApi = new PurchorderdetailsApi.PurchorderdetailsApi(this._http, basePath, configuration);
            const purchorderdetailsfilterApi = new PurchorderdetailsfilterApi.PurchorderdetailsfilterApi(this._http, basePath, configuration);
            const purchorderlistApi = new PurchorderlistApi.PurchorderlistApi(this._http, basePath, configuration);
            const purchorderlistfilterApi = new PurchorderlistfilterApi.PurchorderlistfilterApi(this._http, basePath, configuration);
            const purchorderstockreceiptApi = new PurchorderstockreceiptApi.PurchorderstockreceiptApi(this._http, basePath, configuration);
            const purchorderstockreceipthistoryApi = new PurchorderstockreceipthistoryApi.PurchorderstockreceipthistoryApi(this._http, basePath, configuration);
            const purchorderstockreceipthistorypoApi = new PurchorderstockreceipthistorypoApi.PurchorderstockreceipthistorypoApi(this._http, basePath, configuration);
            const purchorderstockreceiptpoApi = new PurchorderstockreceiptpoApi.PurchorderstockreceiptpoApi(this._http, basePath, configuration);
            const purchorderstockreceiptscanApi = new PurchorderstockreceiptscanApi.PurchorderstockreceiptscanApi(this._http, basePath, configuration);
            const purchorderstockreceiptscanfilterApi = new PurchorderstockreceiptscanfilterApi.PurchorderstockreceiptscanfilterApi(this._http, basePath, configuration);
            const purchorderstockreceiptscanfilterpoApi = new PurchorderstockreceiptscanfilterpoApi.PurchorderstockreceiptscanfilterpoApi(this._http, basePath, configuration);
            const purchorderstockreceiptscanpoApi = new PurchorderstockreceiptscanpoApi.PurchorderstockreceiptscanpoApi(this._http, basePath, configuration);
            const purchproductlistApi = new PurchproductlistApi.PurchproductlistApi(this._http, basePath, configuration);
            const purchproductlistfilterApi = new PurchproductlistfilterApi.PurchproductlistfilterApi(this._http, basePath, configuration);
            const reassignconsumptionApi = new ReassignconsumptionApi.ReassignconsumptionApi(this._http, basePath, configuration);
            const reassignconsumptionfilterApi = new ReassignconsumptionfilterApi.ReassignconsumptionfilterApi(this._http, basePath, configuration);
            const reorderstatusApi = new ReorderstatusApi.ReorderstatusApi(this._http, basePath, configuration);
            const reorderstatusfilterApi = new ReorderstatusfilterApi.ReorderstatusfilterApi(this._http, basePath, configuration);
            const schedule5reportApi = new Schedule5reportApi.Schedule5reportApi(this._http, basePath, configuration);
            const schedule5reportfilterApi = new Schedule5reportfilterApi.Schedule5reportfilterApi(this._http, basePath, configuration);
            const schedule5reportpdfApi = new Schedule5reportpdfApi.Schedule5reportpdfApi(this._http, basePath, configuration);
            const sjogcsvbillingreportfilterApi = new SjogcsvbillingreportfilterApi.SjogcsvbillingreportfilterApi(this._http, basePath, configuration);
            const sjogpatientbillingreportApi = new SjogpatientbillingreportApi.SjogpatientbillingreportApi(this._http, basePath, configuration);
            const sjogpatientbillingreportfilterApi = new SjogpatientbillingreportfilterApi.SjogpatientbillingreportfilterApi(this._http, basePath, configuration);
            const sohnondepartmentreportApi = new SohnondepartmentreportApi.SohnondepartmentreportApi(this._http, basePath, configuration);
            const sohspcdetailsApi = new SohspcdetailsApi.SohspcdetailsApi(this._http, basePath, configuration);
            const spanspcincurrentcontractsApi = new SpanspcincurrentcontractsApi.SpanspcincurrentcontractsApi(this._http, basePath, configuration);
            const spanspcincurrentcontractsfilterApi = new SpanspcincurrentcontractsfilterApi.SpanspcincurrentcontractsfilterApi(this._http, basePath, configuration);
            const spbackorderlistApi = new SpbackorderlistApi.SpbackorderlistApi(this._http, basePath, configuration);
            const spbackorderlistfilterApi = new SpbackorderlistfilterApi.SpbackorderlistfilterApi(this._http, basePath, configuration);
            const spcs4supplierApi = new Spcs4supplierApi.Spcs4supplierApi(this._http, basePath, configuration);
            const spdonotreorderreportApi = new SpdonotreorderreportApi.SpdonotreorderreportApi(this._http, basePath, configuration);
            const spdonotreorderreportfilterApi = new SpdonotreorderreportfilterApi.SpdonotreorderreportfilterApi(this._http, basePath, configuration);
            const speditetadetailsApi = new SpeditetadetailsApi.SpeditetadetailsApi(this._http, basePath, configuration);
            const spimplantablesreportApi = new SpimplantablesreportApi.SpimplantablesreportApi(this._http, basePath, configuration);
            const spimplantablesreportfilterApi = new SpimplantablesreportfilterApi.SpimplantablesreportfilterApi(this._http, basePath, configuration);
            const spnotusedproductsreportApi = new SpnotusedproductsreportApi.SpnotusedproductsreportApi(this._http, basePath, configuration);
            const spnotusedproductsreportfilterApi = new SpnotusedproductsreportfilterApi.SpnotusedproductsreportfilterApi(this._http, basePath, configuration);
            const spporeceiptingreportApi = new SpporeceiptingreportApi.SpporeceiptingreportApi(this._http, basePath, configuration);
            const spporeceiptingreportfilterApi = new SpporeceiptingreportfilterApi.SpporeceiptingreportfilterApi(this._http, basePath, configuration);
            const spprocedurelistApi = new SpprocedurelistApi.SpprocedurelistApi(this._http, basePath, configuration);
            const spprocedurelistfilterApi = new SpprocedurelistfilterApi.SpprocedurelistfilterApi(this._http, basePath, configuration);
            const spprocedurelistproductsApi = new SpprocedurelistproductsApi.SpprocedurelistproductsApi(this._http, basePath, configuration);
            const spproductsinacontract4supplierApi = new Spproductsinacontract4supplierApi.Spproductsinacontract4supplierApi(this._http, basePath, configuration);
            const spproductsinacontract4supplierfilterApi = new Spproductsinacontract4supplierfilterApi.Spproductsinacontract4supplierfilterApi(this._http, basePath, configuration);
            const spproductusagelistApi = new SpproductusagelistApi.SpproductusagelistApi(this._http, basePath, configuration);
            const spproductusagelistfilterApi = new SpproductusagelistfilterApi.SpproductusagelistfilterApi(this._http, basePath, configuration);
            const sppurchorderdetailsApi = new SppurchorderdetailsApi.SppurchorderdetailsApi(this._http, basePath, configuration);
            const sppurchorderdetailsfilterApi = new SppurchorderdetailsfilterApi.SppurchorderdetailsfilterApi(this._http, basePath, configuration);
            const sppurchorderlistApi = new SppurchorderlistApi.SppurchorderlistApi(this._http, basePath, configuration);
            const sppurchorderlistfilterApi = new SppurchorderlistfilterApi.SppurchorderlistfilterApi(this._http, basePath, configuration);
            const sppurchproductlistApi = new SppurchproductlistApi.SppurchproductlistApi(this._http, basePath, configuration);
            const sppurchproductlistfilterApi = new SppurchproductlistfilterApi.SppurchproductlistfilterApi(this._http, basePath, configuration);
            const spschedule5reportApi = new Spschedule5reportApi.Spschedule5reportApi(this._http, basePath, configuration);
            const spschedule5reportfilterApi = new Spschedule5reportfilterApi.Spschedule5reportfilterApi(this._http, basePath, configuration);
            const spsohnondepartmentreportApi = new SpsohnondepartmentreportApi.SpsohnondepartmentreportApi(this._http, basePath, configuration);
            const spsohspcdetailsApi = new SpsohspcdetailsApi.SpsohspcdetailsApi(this._http, basePath, configuration);
            const spstockonhandreportApi = new SpstockonhandreportApi.SpstockonhandreportApi(this._http, basePath, configuration);
            const spstockonhandreportfilterApi = new SpstockonhandreportfilterApi.SpstockonhandreportfilterApi(this._http, basePath, configuration);
            const spstockreportbyspcApi = new SpstockreportbyspcApi.SpstockreportbyspcApi(this._http, basePath, configuration);
            const staffApi = new StaffApi.StaffApi(this._http, basePath, configuration);
            const staffreportemaillistApi = new StaffreportemaillistApi.StaffreportemaillistApi(this._http, basePath, configuration);
            const staffrolesApi = new StaffrolesApi.StaffrolesApi(this._http, basePath, configuration);
            const stafftypeApi = new StafftypeApi.StafftypeApi(this._http, basePath, configuration);
            const stafftyperolesApi = new StafftyperolesApi.StafftyperolesApi(this._http, basePath, configuration);
            const staffviewApi = new StaffviewApi.StaffviewApi(this._http, basePath, configuration);
            const stockadjustApi = new StockadjustApi.StockadjustApi(this._http, basePath, configuration);
            const stockadjustfilterApi = new StockadjustfilterApi.StockadjustfilterApi(this._http, basePath, configuration);
            const stockonhandreportApi = new StockonhandreportApi.StockonhandreportApi(this._http, basePath, configuration);
            const stockonhandreportbyexpirydateApi = new StockonhandreportbyexpirydateApi.StockonhandreportbyexpirydateApi(this._http, basePath, configuration);
            const stockonhandreportbyexpirydatefilterApi = new StockonhandreportbyexpirydatefilterApi.StockonhandreportbyexpirydatefilterApi(this._http, basePath, configuration);
            const stockonhandreportbyexpirydatepdfApi = new StockonhandreportbyexpirydatepdfApi.StockonhandreportbyexpirydatepdfApi(this._http, basePath, configuration);
            const stockonhandreportfilterApi = new StockonhandreportfilterApi.StockonhandreportfilterApi(this._http, basePath, configuration);
            const stockonhandreportpdfApi = new StockonhandreportpdfApi.StockonhandreportpdfApi(this._http, basePath, configuration);
            const stockreportbyspcApi = new StockreportbyspcApi.StockreportbyspcApi(this._http, basePath, configuration);
            const subcategories4contractcategoryApi = new Subcategories4contractcategoryApi.Subcategories4contractcategoryApi(this._http, basePath, configuration);
            const subcategory4supplierincontractApi = new Subcategory4supplierincontractApi.Subcategory4supplierincontractApi(this._http, basePath, configuration);
            const suggestproductcodeApi = new SuggestproductcodeApi.SuggestproductcodeApi(this._http, basePath, configuration);
            const suppliers4contractApi = new Suppliers4contractApi.Suppliers4contractApi(this._http, basePath, configuration);
            const unsentposummaryApi = new UnsentposummaryApi.UnsentposummaryApi(this._http, basePath, configuration);
            const unsentpurchaseordertosendApi = new UnsentpurchaseordertosendApi.UnsentpurchaseordertosendApi(this._http, basePath, configuration);
            const uomwprassignmentsApi = new UomwprassignmentsApi.UomwprassignmentsApi(this._http, basePath, configuration);
            const wccpricelistreleasesApi = new WccpricelistreleasesApi.WccpricelistreleasesApi(this._http, basePath, configuration);
            const wccpricelistsApi = new WccpricelistsApi.WccpricelistsApi(this._http, basePath, configuration);

            this._functions['putAccumulationreport'] = accumulationreportApi.putAccumulationreport
                .bind(accumulationreportApi);
            this._functions['putAccumulationreportfilter'] = accumulationreportfilterApi.putAccumulationreportfilter
                .bind(accumulationreportfilterApi);
            this._functions['putAddadditionaldevice'] = addadditionaldeviceApi.putAddadditionaldevice
                .bind(addadditionaldeviceApi);
            this._functions['postAddadditionaldevice'] = addadditionaldeviceApi.postAddadditionaldevice
                .bind(addadditionaldeviceApi);
            this._functions['putAddnewdevicetoprofile'] = addnewdevicetoprofileApi.putAddnewdevicetoprofile
                .bind(addnewdevicetoprofileApi);
            this._functions['putAddnewdevicetoprofile2'] = addnewdevicetoprofile2Api.putAddnewdevicetoprofile2
                .bind(addnewdevicetoprofile2Api);
            this._functions['putAddproceduretimer'] = addproceduretimerApi.putAddproceduretimer
                .bind(addproceduretimerApi);
            this._functions['postAddproceduretimer'] = addproceduretimerApi.postAddproceduretimer
                .bind(addproceduretimerApi);
            this._functions['putAddproceduretimerfilter'] = addproceduretimerfilterApi.putAddproceduretimerfilter
                .bind(addproceduretimerfilterApi);
            this._functions['putAnspcincurrentcontracts'] = anspcincurrentcontractsApi.putAnspcincurrentcontracts
                .bind(anspcincurrentcontractsApi);
            this._functions['putAnspcincurrentcontractsfilter'] = anspcincurrentcontractsfilterApi.putAnspcincurrentcontractsfilter
                .bind(anspcincurrentcontractsfilterApi);
            this._functions['putAtrumclist'] = atrumclistApi.putAtrumclist
                .bind(atrumclistApi);
            this._functions['getAtrumclist'] = atrumclistApi.getAtrumclist
                .bind(atrumclistApi);
            this._functions['postAtrumclist'] = atrumclistApi.postAtrumclist
                .bind(atrumclistApi);
            this._functions['putAtrumcnotchargeablelist'] = atrumcnotchargeablelistApi.putAtrumcnotchargeablelist
                .bind(atrumcnotchargeablelistApi);
            this._functions['getAtrumcnotchargeablelist'] = atrumcnotchargeablelistApi.getAtrumcnotchargeablelist
                .bind(atrumcnotchargeablelistApi);
            this._functions['postAtrumcnotchargeablelist'] = atrumcnotchargeablelistApi.postAtrumcnotchargeablelist
                .bind(atrumcnotchargeablelistApi);
            this._functions['authLogin'] = authApi.authLogin
                .bind(authApi);
            this._functions['authLogout'] = authApi.authLogout
                .bind(authApi);
            this._functions['authMe'] = authApi.authMe
                .bind(authApi);
            this._functions['authOrgunits'] = authApi.authOrgunits
                .bind(authApi);
            this._functions['authLanguages'] = authApi.authLanguages
                .bind(authApi);
            this._functions['authSwitchLanguage'] = authApi.authSwitchLanguage
                .bind(authApi);
            this._functions['authChangeOrgunit'] = authApi.authChangeOrgunit
                .bind(authApi);
            this._functions['putBackorderlist'] = backorderlistApi.putBackorderlist
                .bind(backorderlistApi);
            this._functions['putBackorderlistfilter'] = backorderlistfilterApi.putBackorderlistfilter
                .bind(backorderlistfilterApi);
            this._functions['putBarwonhealthsupplierlookup'] = barwonhealthsupplierlookupApi.putBarwonhealthsupplierlookup
                .bind(barwonhealthsupplierlookupApi);
            this._functions['putBmreport'] = bmreportApi.putBmreport
                .bind(bmreportApi);
            this._functions['putBmreportfilter'] = bmreportfilterApi.putBmreportfilter
                .bind(bmreportfilterApi);
            this._functions['putBmwslhdreport'] = bmwslhdreportApi.putBmwslhdreport
                .bind(bmwslhdreportApi);
            this._functions['postBmwslhdreport'] = bmwslhdreportApi.postBmwslhdreport
                .bind(bmwslhdreportApi);
            this._functions['putBmwslhdreportfilter'] = bmwslhdreportfilterApi.putBmwslhdreportfilter
                .bind(bmwslhdreportfilterApi);
            this._functions['putCardiobasedevices'] = cardiobasedevicesApi.putCardiobasedevices
                .bind(cardiobasedevicesApi);
            this._functions['getCardiobasedevices'] = cardiobasedevicesApi.getCardiobasedevices
                .bind(cardiobasedevicesApi);
            this._functions['postCardiobasedevices'] = cardiobasedevicesApi.postCardiobasedevices
                .bind(cardiobasedevicesApi);
            this._functions['putCardiobasemanufacturers'] = cardiobasemanufacturersApi.putCardiobasemanufacturers
                .bind(cardiobasemanufacturersApi);
            this._functions['getCardiobasemanufacturers'] = cardiobasemanufacturersApi.getCardiobasemanufacturers
                .bind(cardiobasemanufacturersApi);
            this._functions['postCardiobasemanufacturers'] = cardiobasemanufacturersApi.postCardiobasemanufacturers
                .bind(cardiobasemanufacturersApi);
            this._functions['putCardiobasemisc'] = cardiobasemiscApi.putCardiobasemisc
                .bind(cardiobasemiscApi);
            this._functions['getCardiobasemisc'] = cardiobasemiscApi.getCardiobasemisc
                .bind(cardiobasemiscApi);
            this._functions['postCardiobasemisc'] = cardiobasemiscApi.postCardiobasemisc
                .bind(cardiobasemiscApi);
            this._functions['putCardiobasestaff'] = cardiobasestaffApi.putCardiobasestaff
                .bind(cardiobasestaffApi);
            this._functions['getCardiobasestaff'] = cardiobasestaffApi.getCardiobasestaff
                .bind(cardiobasestaffApi);
            this._functions['postCardiobasestaff'] = cardiobasestaffApi.postCardiobasestaff
                .bind(cardiobasestaffApi);
            this._functions['putCardnames4consultant'] = cardnames4consultantApi.putCardnames4consultant
                .bind(cardnames4consultantApi);
            this._functions['putCategories4contract'] = categories4contractApi.putCategories4contract
                .bind(categories4contractApi);
            this._functions['putCategory3forcontractcategory'] = category3forcontractcategoryApi.putCategory3forcontractcategory
                .bind(category3forcontractcategoryApi);
            this._functions['putCategory4forcontractcategory'] = category4forcontractcategoryApi.putCategory4forcontractcategory
                .bind(category4forcontractcategoryApi);
            this._functions['putCategory4supplierincontract'] = category4supplierincontractApi.putCategory4supplierincontract
                .bind(category4supplierincontractApi);
            this._functions['putCategory5forcontractcategory'] = category5forcontractcategoryApi.putCategory5forcontractcategory
                .bind(category5forcontractcategoryApi);
            this._functions['putCcdhbsupplierlookup'] = ccdhbsupplierlookupApi.putCcdhbsupplierlookup
                .bind(ccdhbsupplierlookupApi);
            this._functions['putChangeinventorymapping'] = changeinventorymappingApi.putChangeinventorymapping
                .bind(changeinventorymappingApi);
            this._functions['getChangeinventorymapping'] = changeinventorymappingApi.getChangeinventorymapping
                .bind(changeinventorymappingApi);
            this._functions['postChangeinventorymapping'] = changeinventorymappingApi.postChangeinventorymapping
                .bind(changeinventorymappingApi);
            this._functions['putChangeinventoryreport'] = changeinventoryreportApi.putChangeinventoryreport
                .bind(changeinventoryreportApi);
            this._functions['putChangeinventoryreportfilter'] = changeinventoryreportfilterApi.putChangeinventoryreportfilter
                .bind(changeinventoryreportfilterApi);
            this._functions['putChangeprocedurematchingreport'] = changeprocedurematchingreportApi.putChangeprocedurematchingreport
                .bind(changeprocedurematchingreportApi);
            this._functions['putChangeprocedurematchingreportfilter'] = changeprocedurematchingreportfilterApi.putChangeprocedurematchingreportfilter
                .bind(changeprocedurematchingreportfilterApi);
            this._functions['putChgattendingstaff'] = chgattendingstaffApi.putChgattendingstaff
                .bind(chgattendingstaffApi);
            this._functions['postChgattendingstaff'] = chgattendingstaffApi.postChgattendingstaff
                .bind(chgattendingstaffApi);
            this._functions['putChgcontrastvolume'] = chgcontrastvolumeApi.putChgcontrastvolume
                .bind(chgcontrastvolumeApi);
            this._functions['postChgcontrastvolume'] = chgcontrastvolumeApi.postChgcontrastvolume
                .bind(chgcontrastvolumeApi);
            this._functions['putChgdeletedprocedureflag'] = chgdeletedprocedureflagApi.putChgdeletedprocedureflag
                .bind(chgdeletedprocedureflagApi);
            this._functions['postChgdeletedprocedureflag'] = chgdeletedprocedureflagApi.postChgdeletedprocedureflag
                .bind(chgdeletedprocedureflagApi);
            this._functions['putChgdevicedeletion'] = chgdevicedeletionApi.putChgdevicedeletion
                .bind(chgdevicedeletionApi);
            this._functions['postChgdevicedeletion'] = chgdevicedeletionApi.postChgdevicedeletion
                .bind(chgdevicedeletionApi);
            this._functions['putChghfundptype'] = chghfundptypeApi.putChghfundptype
                .bind(chghfundptypeApi);
            this._functions['postChghfundptype'] = chghfundptypeApi.postChghfundptype
                .bind(chghfundptypeApi);
            this._functions['putChgpatientref'] = chgpatientrefApi.putChgpatientref
                .bind(chgpatientrefApi);
            this._functions['postChgpatientref'] = chgpatientrefApi.postChgpatientref
                .bind(chgpatientrefApi);
            this._functions['putChgproclocation'] = chgproclocationApi.putChgproclocation
                .bind(chgproclocationApi);
            this._functions['postChgproclocation'] = chgproclocationApi.postChgproclocation
                .bind(chgproclocationApi);
            this._functions['putChgproctimestamps'] = chgproctimestampsApi.putChgproctimestamps
                .bind(chgproctimestampsApi);
            this._functions['postChgproctimestamps'] = chgproctimestampsApi.postChgproctimestamps
                .bind(chgproctimestampsApi);
            this._functions['putChgselmedications'] = chgselmedicationsApi.putChgselmedications
                .bind(chgselmedicationsApi);
            this._functions['postChgselmedications'] = chgselmedicationsApi.postChgselmedications
                .bind(chgselmedicationsApi);
            this._functions['putChgselproctypes'] = chgselproctypesApi.putChgselproctypes
                .bind(chgselproctypesApi);
            this._functions['postChgselproctypes'] = chgselproctypesApi.postChgselproctypes
                .bind(chgselproctypesApi);
            this._functions['putChgtimeoutanswers'] = chgtimeoutanswersApi.putChgtimeoutanswers
                .bind(chgtimeoutanswersApi);
            this._functions['postChgtimeoutanswers'] = chgtimeoutanswersApi.postChgtimeoutanswers
                .bind(chgtimeoutanswersApi);
            this._functions['putCmaddproduct'] = cmaddproductApi.putCmaddproduct
                .bind(cmaddproductApi);
            this._functions['putCmcomparepricingreport'] = cmcomparepricingreportApi.putCmcomparepricingreport
                .bind(cmcomparepricingreportApi);
            this._functions['putCmcomparepricingreportfilter'] = cmcomparepricingreportfilterApi.putCmcomparepricingreportfilter
                .bind(cmcomparepricingreportfilterApi);
            this._functions['putCmcontractclasssupplierproducts'] = cmcontractclasssupplierproductsApi.putCmcontractclasssupplierproducts
                .bind(cmcontractclasssupplierproductsApi);
            this._functions['getCmcontractclasssupplierproducts'] = cmcontractclasssupplierproductsApi.getCmcontractclasssupplierproducts
                .bind(cmcontractclasssupplierproductsApi);
            this._functions['postCmcontractclasssupplierproducts'] = cmcontractclasssupplierproductsApi.postCmcontractclasssupplierproducts
                .bind(cmcontractclasssupplierproductsApi);
            this._functions['putCmcontractexceptionsreport'] = cmcontractexceptionsreportApi.putCmcontractexceptionsreport
                .bind(cmcontractexceptionsreportApi);
            this._functions['putCmcontractexceptionsreportfilter'] = cmcontractexceptionsreportfilterApi.putCmcontractexceptionsreportfilter
                .bind(cmcontractexceptionsreportfilterApi);
            this._functions['putCmcontractproducts'] = cmcontractproductsApi.putCmcontractproducts
                .bind(cmcontractproductsApi);
            this._functions['getCmcontractproducts'] = cmcontractproductsApi.getCmcontractproducts
                .bind(cmcontractproductsApi);
            this._functions['postCmcontractproducts'] = cmcontractproductsApi.postCmcontractproducts
                .bind(cmcontractproductsApi);
            this._functions['putCmcontractversionvariations'] = cmcontractversionvariationsApi.putCmcontractversionvariations
                .bind(cmcontractversionvariationsApi);
            this._functions['postCmcontractversionvariations'] = cmcontractversionvariationsApi.postCmcontractversionvariations
                .bind(cmcontractversionvariationsApi);
            this._functions['putCmcontractversionvariationsfilter'] = cmcontractversionvariationsfilterApi.putCmcontractversionvariationsfilter
                .bind(cmcontractversionvariationsfilterApi);
            this._functions['putCmeditcontracts'] = cmeditcontractsApi.putCmeditcontracts
                .bind(cmeditcontractsApi);
            this._functions['getCmeditcontracts'] = cmeditcontractsApi.getCmeditcontracts
                .bind(cmeditcontractsApi);
            this._functions['postCmeditcontracts'] = cmeditcontractsApi.postCmeditcontracts
                .bind(cmeditcontractsApi);
            this._functions['putCmgpalbudgettackingreport'] = cmgpalbudgettackingreportApi.putCmgpalbudgettackingreport
                .bind(cmgpalbudgettackingreportApi);
            this._functions['putCmgpalbudgettrackingreportfilter'] = cmgpalbudgettrackingreportfilterApi.putCmgpalbudgettrackingreportfilter
                .bind(cmgpalbudgettrackingreportfilterApi);
            this._functions['getCmmenu'] = cmmenuApi.getCmmenu
                .bind(cmmenuApi);
            this._functions['putCmpricebanddefine'] = cmpricebanddefineApi.putCmpricebanddefine
                .bind(cmpricebanddefineApi);
            this._functions['getCmpricebanddefine'] = cmpricebanddefineApi.getCmpricebanddefine
                .bind(cmpricebanddefineApi);
            this._functions['postCmpricebanddefine'] = cmpricebanddefineApi.postCmpricebanddefine
                .bind(cmpricebanddefineApi);
            this._functions['putCmproductsinacontract4supplier'] = cmproductsinacontract4supplierApi.putCmproductsinacontract4supplier
                .bind(cmproductsinacontract4supplierApi);
            this._functions['putCmproductsinacontract4supplierfilter'] = cmproductsinacontract4supplierfilterApi.putCmproductsinacontract4supplierfilter
                .bind(cmproductsinacontract4supplierfilterApi);
            this._functions['putCmproductusagelist'] = cmproductusagelistApi.putCmproductusagelist
                .bind(cmproductusagelistApi);
            this._functions['putCmproductusagelistfilter'] = cmproductusagelistfilterApi.putCmproductusagelistfilter
                .bind(cmproductusagelistfilterApi);
            this._functions['putCmpurchproductlist'] = cmpurchproductlistApi.putCmpurchproductlist
                .bind(cmpurchproductlistApi);
            this._functions['putCmpurchproductlistfilter'] = cmpurchproductlistfilterApi.putCmpurchproductlistfilter
                .bind(cmpurchproductlistfilterApi);
            this._functions['putCmspcincurrentcontracts'] = cmspcincurrentcontractsApi.putCmspcincurrentcontracts
                .bind(cmspcincurrentcontractsApi);
            this._functions['putCmspcincurrentcontractsfilter'] = cmspcincurrentcontractsfilterApi.putCmspcincurrentcontractsfilter
                .bind(cmspcincurrentcontractsfilterApi);
            this._functions['putCmstandingordersorgpal'] = cmstandingordersorgpalApi.putCmstandingordersorgpal
                .bind(cmstandingordersorgpalApi);
            this._functions['getCmstandingordersorgpal'] = cmstandingordersorgpalApi.getCmstandingordersorgpal
                .bind(cmstandingordersorgpalApi);
            this._functions['postCmstandingordersorgpal'] = cmstandingordersorgpalApi.postCmstandingordersorgpal
                .bind(cmstandingordersorgpalApi);
            this._functions['putCmstockonhandreport'] = cmstockonhandreportApi.putCmstockonhandreport
                .bind(cmstockonhandreportApi);
            this._functions['putCmstockonhandreportfilter'] = cmstockonhandreportfilterApi.putCmstockonhandreportfilter
                .bind(cmstockonhandreportfilterApi);
            this._functions['putCmstockonhandreportpdf'] = cmstockonhandreportpdfApi.putCmstockonhandreportpdf
                .bind(cmstockonhandreportpdfApi);
            this._functions['putCmsuggestproductcode'] = cmsuggestproductcodeApi.putCmsuggestproductcode
                .bind(cmsuggestproductcodeApi);
            this._functions['putCmsupplierexclusionsreport'] = cmsupplierexclusionsreportApi.putCmsupplierexclusionsreport
                .bind(cmsupplierexclusionsreportApi);
            this._functions['putCmsupplierexclusionsreportfilter'] = cmsupplierexclusionsreportfilterApi.putCmsupplierexclusionsreportfilter
                .bind(cmsupplierexclusionsreportfilterApi);
            this._functions['putCmsuppliers'] = cmsuppliersApi.putCmsuppliers
                .bind(cmsuppliersApi);
            this._functions['getCmsuppliers'] = cmsuppliersApi.getCmsuppliers
                .bind(cmsuppliersApi);
            this._functions['postCmsuppliers'] = cmsuppliersApi.postCmsuppliers
                .bind(cmsuppliersApi);
            this._functions['putCmtiers'] = cmtiersApi.putCmtiers
                .bind(cmtiersApi);
            this._functions['getCmtiers'] = cmtiersApi.getCmtiers
                .bind(cmtiersApi);
            this._functions['postCmtiers'] = cmtiersApi.postCmtiers
                .bind(cmtiersApi);
            this._functions['putCmtiersuppliers'] = cmtiersuppliersApi.putCmtiersuppliers
                .bind(cmtiersuppliersApi);
            this._functions['getCmtiersuppliers'] = cmtiersuppliersApi.getCmtiersuppliers
                .bind(cmtiersuppliersApi);
            this._functions['postCmtiersuppliers'] = cmtiersuppliersApi.postCmtiersuppliers
                .bind(cmtiersuppliersApi);
            this._functions['putCmuploadcontract'] = cmuploadcontractApi.putCmuploadcontract
                .bind(cmuploadcontractApi);
            this._functions['getCmuploadcontract'] = cmuploadcontractApi.getCmuploadcontract
                .bind(cmuploadcontractApi);
            this._functions['postCmuploadcontract'] = cmuploadcontractApi.postCmuploadcontract
                .bind(cmuploadcontractApi);
            this._functions['putCmvieweditcontracts'] = cmvieweditcontractsApi.putCmvieweditcontracts
                .bind(cmvieweditcontractsApi);
            this._functions['getCmvieweditcontracts'] = cmvieweditcontractsApi.getCmvieweditcontracts
                .bind(cmvieweditcontractsApi);
            this._functions['postCmvieweditcontracts'] = cmvieweditcontractsApi.postCmvieweditcontracts
                .bind(cmvieweditcontractsApi);
            this._functions['putCodesprices'] = codespricesApi.putCodesprices
                .bind(codespricesApi);
            this._functions['getCodesprices'] = codespricesApi.getCodesprices
                .bind(codespricesApi);
            this._functions['postCodesprices'] = codespricesApi.postCodesprices
                .bind(codespricesApi);
            this._functions['putCodespricesfilter'] = codespricesfilterApi.putCodespricesfilter
                .bind(codespricesfilterApi);
            this._functions['putCodespricesstocktype'] = codespricesstocktypeApi.putCodespricesstocktype
                .bind(codespricesstocktypeApi);
            this._functions['getCodespricesstocktype'] = codespricesstocktypeApi.getCodespricesstocktype
                .bind(codespricesstocktypeApi);
            this._functions['postCodespricesstocktype'] = codespricesstocktypeApi.postCodespricesstocktype
                .bind(codespricesstocktypeApi);
            this._functions['putCodespricesstocktypefilter'] = codespricesstocktypefilterApi.putCodespricesstocktypefilter
                .bind(codespricesstocktypefilterApi);
            this._functions['putCommitprosthesespricing'] = commitprosthesespricingApi.putCommitprosthesespricing
                .bind(commitprosthesespricingApi);
            this._functions['putConsultants4specialty'] = consultants4specialtyApi.putConsultants4specialty
                .bind(consultants4specialtyApi);
            this._functions['putConsumptionsets'] = consumptionsetsApi.putConsumptionsets
                .bind(consumptionsetsApi);
            this._functions['getConsumptionsets'] = consumptionsetsApi.getConsumptionsets
                .bind(consumptionsetsApi);
            this._functions['postConsumptionsets'] = consumptionsetsApi.postConsumptionsets
                .bind(consumptionsetsApi);
            this._functions['putConsumptiontype'] = consumptiontypeApi.putConsumptiontype
                .bind(consumptiontypeApi);
            this._functions['getConsumptiontype'] = consumptiontypeApi.getConsumptiontype
                .bind(consumptiontypeApi);
            this._functions['postConsumptiontype'] = consumptiontypeApi.postConsumptiontype
                .bind(consumptiontypeApi);
            this._functions['putDiagnostics'] = diagnosticsApi.putDiagnostics
                .bind(diagnosticsApi);
            this._functions['putDonotreorderreport'] = donotreorderreportApi.putDonotreorderreport
                .bind(donotreorderreportApi);
            this._functions['putDonotreorderreportfilter'] = donotreorderreportfilterApi.putDonotreorderreportfilter
                .bind(donotreorderreportfilterApi);
            this._functions['putDownloadpurchasereqs'] = downloadpurchasereqsApi.putDownloadpurchasereqs
                .bind(downloadpurchasereqsApi);
            this._functions['putDownloadpurchasereqsdownload'] = downloadpurchasereqsdownloadApi.putDownloadpurchasereqsdownload
                .bind(downloadpurchasereqsdownloadApi);
            this._functions['putDownloadpurchasereqsfilter'] = downloadpurchasereqsfilterApi.putDownloadpurchasereqsfilter
                .bind(downloadpurchasereqsfilterApi);
            this._functions['putDownloadpurchasereqspdf'] = downloadpurchasereqspdfApi.putDownloadpurchasereqspdf
                .bind(downloadpurchasereqspdfApi);
            this._functions['putEmailcmreport'] = emailcmreportApi.putEmailcmreport
                .bind(emailcmreportApi);
            this._functions['putEmailcmreportfilter'] = emailcmreportfilterApi.putEmailcmreportfilter
                .bind(emailcmreportfilterApi);
            this._functions['putEmailprefcardreport'] = emailprefcardreportApi.putEmailprefcardreport
                .bind(emailprefcardreportApi);
            this._functions['putEmailprefcardreportfilter'] = emailprefcardreportfilterApi.putEmailprefcardreportfilter
                .bind(emailprefcardreportfilterApi);
            this._functions['putEmailprocreport'] = emailprocreportApi.putEmailprocreport
                .bind(emailprocreportApi);
            this._functions['putEmailprocreportfilter'] = emailprocreportfilterApi.putEmailprocreportfilter
                .bind(emailprocreportfilterApi);
            this._functions['putEmailreport'] = emailreportApi.putEmailreport
                .bind(emailreportApi);
            this._functions['putEmailreportadmin'] = emailreportadminApi.putEmailreportadmin
                .bind(emailreportadminApi);
            this._functions['putEmailreportadminfilter'] = emailreportadminfilterApi.putEmailreportadminfilter
                .bind(emailreportadminfilterApi);
            this._functions['putEmailreportfilter'] = emailreportfilterApi.putEmailreportfilter
                .bind(emailreportfilterApi);
            this._functions['putEmailsupplierportalreports'] = emailsupplierportalreportsApi.putEmailsupplierportalreports
                .bind(emailsupplierportalreportsApi);
            this._functions['putEmailsupplierportalreportsfilter'] = emailsupplierportalreportsfilterApi.putEmailsupplierportalreportsfilter
                .bind(emailsupplierportalreportsfilterApi);
            this._functions['putImplantablesreport'] = implantablesreportApi.putImplantablesreport
                .bind(implantablesreportApi);
            this._functions['putImplantablesreportfilter'] = implantablesreportfilterApi.putImplantablesreportfilter
                .bind(implantablesreportfilterApi);
            this._functions['putKitcontract4supplier'] = kitcontract4supplierApi.putKitcontract4supplier
                .bind(kitcontract4supplierApi);
            this._functions['putKitproducts4suppliercontract'] = kitproducts4suppliercontractApi.putKitproducts4suppliercontract
                .bind(kitproducts4suppliercontractApi);
            this._functions['putLeadgownusagereport'] = leadgownusagereportApi.putLeadgownusagereport
                .bind(leadgownusagereportApi);
            this._functions['putLeadgownusagereportfilter'] = leadgownusagereportfilterApi.putLeadgownusagereportfilter
                .bind(leadgownusagereportfilterApi);
            this._functions['putListallcurrentactivecontracts'] = listallcurrentactivecontractsApi.putListallcurrentactivecontracts
                .bind(listallcurrentactivecontractsApi);
            this._functions['putListblankproductinfo'] = listblankproductinfoApi.putListblankproductinfo
                .bind(listblankproductinfoApi);
            this._functions['putListblankproductinfofilter'] = listblankproductinfofilterApi.putListblankproductinfofilter
                .bind(listblankproductinfofilterApi);
            this._functions['putLocationupdate'] = locationupdateApi.putLocationupdate
                .bind(locationupdateApi);
            this._functions['postLocationupdate'] = locationupdateApi.postLocationupdate
                .bind(locationupdateApi);
            this._functions['getMainmenu'] = mainmenuApi.getMainmenu
                .bind(mainmenuApi);
            this._functions['getMaintmenu'] = maintmenuApi.getMaintmenu
                .bind(maintmenuApi);
            this._functions['getMe'] = meApi.getMe
                .bind(meApi);
            this._functions['postMe'] = meApi.postMe
                .bind(meApi);
            this._functions['putMe'] = meApi.putMe
                .bind(meApi);
            this._functions['deleteMe'] = meApi.deleteMe
                .bind(meApi);
            this._functions['putMrnappointmentepisodelookup'] = mrnappointmentepisodelookupApi.putMrnappointmentepisodelookup
                .bind(mrnappointmentepisodelookupApi);
            this._functions['putMrnappointmentepisodelookupfilter'] = mrnappointmentepisodelookupfilterApi.putMrnappointmentepisodelookupfilter
                .bind(mrnappointmentepisodelookupfilterApi);
            this._functions['putMrnucqepisodelookup'] = mrnucqepisodelookupApi.putMrnucqepisodelookup
                .bind(mrnucqepisodelookupApi);
            this._functions['putMrnucqepisodelookupfilter'] = mrnucqepisodelookupfilterApi.putMrnucqepisodelookupfilter
                .bind(mrnucqepisodelookupfilterApi);
            this._functions['putMvpsaccumulationreport'] = mvpsaccumulationreportApi.putMvpsaccumulationreport
                .bind(mvpsaccumulationreportApi);
            this._functions['putMvpsaccumulationreportfilter'] = mvpsaccumulationreportfilterApi.putMvpsaccumulationreportfilter
                .bind(mvpsaccumulationreportfilterApi);
            this._functions['putMvpsapprovallist'] = mvpsapprovallistApi.putMvpsapprovallist
                .bind(mvpsapprovallistApi);
            this._functions['putMvpsbackorderlist'] = mvpsbackorderlistApi.putMvpsbackorderlist
                .bind(mvpsbackorderlistApi);
            this._functions['putMvpsbackorderlistfilter'] = mvpsbackorderlistfilterApi.putMvpsbackorderlistfilter
                .bind(mvpsbackorderlistfilterApi);
            this._functions['putMvpsdeliverydocket'] = mvpsdeliverydocketApi.putMvpsdeliverydocket
                .bind(mvpsdeliverydocketApi);
            this._functions['putMvpsdownloadpurchaseorders'] = mvpsdownloadpurchaseordersApi.putMvpsdownloadpurchaseorders
                .bind(mvpsdownloadpurchaseordersApi);
            this._functions['putMvpsdownloadpurchaseordersfilter'] = mvpsdownloadpurchaseordersfilterApi.putMvpsdownloadpurchaseordersfilter
                .bind(mvpsdownloadpurchaseordersfilterApi);
            this._functions['putMvpsimprestorderdetail'] = mvpsimprestorderdetailApi.putMvpsimprestorderdetail
                .bind(mvpsimprestorderdetailApi);
            this._functions['putMvpsimprestorders'] = mvpsimprestordersApi.putMvpsimprestorders
                .bind(mvpsimprestordersApi);
            this._functions['putMvpsimprestordersfilter'] = mvpsimprestordersfilterApi.putMvpsimprestordersfilter
                .bind(mvpsimprestordersfilterApi);
            this._functions['putMvpslist'] = mvpslistApi.putMvpslist
                .bind(mvpslistApi);
            this._functions['putMvpslistreport'] = mvpslistreportApi.putMvpslistreport
                .bind(mvpslistreportApi);
            this._functions['putMvpslistreportfilter'] = mvpslistreportfilterApi.putMvpslistreportfilter
                .bind(mvpslistreportfilterApi);
            this._functions['putMvpslistunsent'] = mvpslistunsentApi.putMvpslistunsent
                .bind(mvpslistunsentApi);
            this._functions['putMvpsopenorderlist'] = mvpsopenorderlistApi.putMvpsopenorderlist
                .bind(mvpsopenorderlistApi);
            this._functions['postMvpsopenorderlist'] = mvpsopenorderlistApi.postMvpsopenorderlist
                .bind(mvpsopenorderlistApi);
            this._functions['putMvpsopenorderlistfilter'] = mvpsopenorderlistfilterApi.putMvpsopenorderlistfilter
                .bind(mvpsopenorderlistfilterApi);
            this._functions['putMvpsorderpart'] = mvpsorderpartApi.putMvpsorderpart
                .bind(mvpsorderpartApi);
            this._functions['postMvpsorderpart'] = mvpsorderpartApi.postMvpsorderpart
                .bind(mvpsorderpartApi);
            this._functions['putMvpsorderpartfilter'] = mvpsorderpartfilterApi.putMvpsorderpartfilter
                .bind(mvpsorderpartfilterApi);
            this._functions['putMvpspolines'] = mvpspolinesApi.putMvpspolines
                .bind(mvpspolinesApi);
            this._functions['postMvpspolines'] = mvpspolinesApi.postMvpspolines
                .bind(mvpspolinesApi);
            this._functions['putMvpsporeceiptingreport'] = mvpsporeceiptingreportApi.putMvpsporeceiptingreport
                .bind(mvpsporeceiptingreportApi);
            this._functions['putMvpsporeceiptingreportfilter'] = mvpsporeceiptingreportfilterApi.putMvpsporeceiptingreportfilter
                .bind(mvpsporeceiptingreportfilterApi);
            this._functions['putMvpsposummary'] = mvpsposummaryApi.putMvpsposummary
                .bind(mvpsposummaryApi);
            this._functions['putMvpspurchaseorderdetail'] = mvpspurchaseorderdetailApi.putMvpspurchaseorderdetail
                .bind(mvpspurchaseorderdetailApi);
            this._functions['putMvpspurchaseorderscount'] = mvpspurchaseorderscountApi.putMvpspurchaseorderscount
                .bind(mvpspurchaseorderscountApi);
            this._functions['putMvpspurchaseordersunsubmit'] = mvpspurchaseorderscountApi.putMvpspurchaseordersunsubmit
                .bind(mvpspurchaseorderscountApi);
            this._functions['putMvpspurchaseorderswait'] = mvpspurchaseorderscountApi.putMvpspurchaseorderswait
                .bind(mvpspurchaseorderscountApi);
            this._functions['putMvpspurchorderdetails'] = mvpspurchorderdetailsApi.putMvpspurchorderdetails
                .bind(mvpspurchorderdetailsApi);
            this._functions['putMvpspurchorderdetailsfilter'] = mvpspurchorderdetailsfilterApi.putMvpspurchorderdetailsfilter
                .bind(mvpspurchorderdetailsfilterApi);
            this._functions['putMvpspurchorderstockreceipt'] = mvpspurchorderstockreceiptApi.putMvpspurchorderstockreceipt
                .bind(mvpspurchorderstockreceiptApi);
            this._functions['postMvpspurchorderstockreceipt'] = mvpspurchorderstockreceiptApi.postMvpspurchorderstockreceipt
                .bind(mvpspurchorderstockreceiptApi);
            this._functions['putMvpspurchorderstockreceipthistory'] = mvpspurchorderstockreceipthistoryApi.putMvpspurchorderstockreceipthistory
                .bind(mvpspurchorderstockreceipthistoryApi);
            this._functions['postMvpspurchorderstockreceipthistory'] = mvpspurchorderstockreceipthistoryApi.postMvpspurchorderstockreceipthistory
                .bind(mvpspurchorderstockreceipthistoryApi);
            this._functions['putMvpspurchorderstockreceiptscan'] = mvpspurchorderstockreceiptscanApi.putMvpspurchorderstockreceiptscan
                .bind(mvpspurchorderstockreceiptscanApi);
            this._functions['putMvpspurchorderstockreceiptscanfilter'] = mvpspurchorderstockreceiptscanfilterApi.putMvpspurchorderstockreceiptscanfilter
                .bind(mvpspurchorderstockreceiptscanfilterApi);
            this._functions['putMvpspurchproductlist'] = mvpspurchproductlistApi.putMvpspurchproductlist
                .bind(mvpspurchproductlistApi);
            this._functions['putMvpspurchproductlistfilter'] = mvpspurchproductlistfilterApi.putMvpspurchproductlistfilter
                .bind(mvpspurchproductlistfilterApi);
            this._functions['putMvpsunapproved'] = mvpsunapprovedApi.putMvpsunapproved
                .bind(mvpsunapprovedApi);
            this._functions['putMvpsunsubmitted'] = mvpsunsubmittedApi.putMvpsunsubmitted
                .bind(mvpsunsubmittedApi);
            this._functions['putMvpsview'] = mvpsviewApi.putMvpsview
                .bind(mvpsviewApi);
            this._functions['putNewpurchaseorderlines'] = newpurchaseorderlinesApi.putNewpurchaseorderlines
                .bind(newpurchaseorderlinesApi);
            this._functions['postNewpurchaseorderlines'] = newpurchaseorderlinesApi.postNewpurchaseorderlines
                .bind(newpurchaseorderlinesApi);
            this._functions['putNewpurchaseordertosend'] = newpurchaseordertosendApi.putNewpurchaseordertosend
                .bind(newpurchaseordertosendApi);
            this._functions['putNotusedproductsreport'] = notusedproductsreportApi.putNotusedproductsreport
                .bind(notusedproductsreportApi);
            this._functions['putNotusedproductsreportfilter'] = notusedproductsreportfilterApi.putNotusedproductsreportfilter
                .bind(notusedproductsreportfilterApi);
            this._functions['putNslhdpatientbillingreport'] = nslhdpatientbillingreportApi.putNslhdpatientbillingreport
                .bind(nslhdpatientbillingreportApi);
            this._functions['putNslhdpatientbillingreportfilter'] = nslhdpatientbillingreportfilterApi.putNslhdpatientbillingreportfilter
                .bind(nslhdpatientbillingreportfilterApi);
            this._functions['putNswhealthsupplierlookup'] = nswhealthsupplierlookupApi.putNswhealthsupplierlookup
                .bind(nswhealthsupplierlookupApi);
            this._functions['putOpenorderlist'] = openorderlistApi.putOpenorderlist
                .bind(openorderlistApi);
            this._functions['postOpenorderlist'] = openorderlistApi.postOpenorderlist
                .bind(openorderlistApi);
            this._functions['putOpenorderlistfilter'] = openorderlistfilterApi.putOpenorderlistfilter
                .bind(openorderlistfilterApi);
            this._functions['putOpenorderlistfilterpo'] = openorderlistfilterpoApi.putOpenorderlistfilterpo
                .bind(openorderlistfilterpoApi);
            this._functions['putOpenorderlistpo'] = openorderlistpoApi.putOpenorderlistpo
                .bind(openorderlistpoApi);
            this._functions['postOpenorderlistpo'] = openorderlistpoApi.postOpenorderlistpo
                .bind(openorderlistpoApi);
            this._functions['putOrderpart'] = orderpartApi.putOrderpart
                .bind(orderpartApi);
            this._functions['postOrderpart'] = orderpartApi.postOrderpart
                .bind(orderpartApi);
            this._functions['putOrderpartfilter'] = orderpartfilterApi.putOrderpartfilter
                .bind(orderpartfilterApi);
            this._functions['putOrgunit'] = orgunitApi.putOrgunit
                .bind(orgunitApi);
            this._functions['postOrgunit'] = orgunitApi.postOrgunit
                .bind(orgunitApi);
            this._functions['putOrgunitproductfamily'] = orgunitproductfamilyApi.putOrgunitproductfamily
                .bind(orgunitproductfamilyApi);
            this._functions['putOualternative'] = oualternativeApi.putOualternative
                .bind(oualternativeApi);
            this._functions['postOualternative'] = oualternativeApi.postOualternative
                .bind(oualternativeApi);
            this._functions['putOucategories'] = oucategoriesApi.putOucategories
                .bind(oucategoriesApi);
            this._functions['getOucategories'] = oucategoriesApi.getOucategories
                .bind(oucategoriesApi);
            this._functions['postOucategories'] = oucategoriesApi.postOucategories
                .bind(oucategoriesApi);
            this._functions['putOuconsumptionsetprocs'] = ouconsumptionsetprocsApi.putOuconsumptionsetprocs
                .bind(ouconsumptionsetprocsApi);
            this._functions['putOucostcentrecodes'] = oucostcentrecodesApi.putOucostcentrecodes
                .bind(oucostcentrecodesApi);
            this._functions['getOucostcentrecodes'] = oucostcentrecodesApi.getOucostcentrecodes
                .bind(oucostcentrecodesApi);
            this._functions['postOucostcentrecodes'] = oucostcentrecodesApi.postOucostcentrecodes
                .bind(oucostcentrecodesApi);
            this._functions['putOucostcentrescanningsheet'] = oucostcentrescanningsheetApi.putOucostcentrescanningsheet
                .bind(oucostcentrescanningsheetApi);
            this._functions['putOucostcentrescanningsheetoptions'] = oucostcentrescanningsheetoptionsApi.putOucostcentrescanningsheetoptions
                .bind(oucostcentrescanningsheetoptionsApi);
            this._functions['putOudeliverylocations'] = oudeliverylocationsApi.putOudeliverylocations
                .bind(oudeliverylocationsApi);
            this._functions['getOudeliverylocations'] = oudeliverylocationsApi.getOudeliverylocations
                .bind(oudeliverylocationsApi);
            this._functions['postOudeliverylocations'] = oudeliverylocationsApi.postOudeliverylocations
                .bind(oudeliverylocationsApi);
            this._functions['putOudevconsumpdetail'] = oudevconsumpdetailApi.putOudevconsumpdetail
                .bind(oudevconsumpdetailApi);
            this._functions['putOudevicedetailscanningsheet'] = oudevicedetailscanningsheetApi.putOudevicedetailscanningsheet
                .bind(oudevicedetailscanningsheetApi);
            this._functions['getOudevicedetailscanningsheet'] = oudevicedetailscanningsheetApi.getOudevicedetailscanningsheet
                .bind(oudevicedetailscanningsheetApi);
            this._functions['postOudevicedetailscanningsheet'] = oudevicedetailscanningsheetApi.postOudevicedetailscanningsheet
                .bind(oudevicedetailscanningsheetApi);
            this._functions['putOudevicescanningsheetoptions'] = oudevicescanningsheetoptionsApi.putOudevicescanningsheetoptions
                .bind(oudevicescanningsheetoptionsApi);
            this._functions['putOudevicescanningsheetpdf'] = oudevicescanningsheetpdfApi.putOudevicescanningsheetpdf
                .bind(oudevicescanningsheetpdfApi);
            this._functions['putOudevicescanningsheets'] = oudevicescanningsheetsApi.putOudevicescanningsheets
                .bind(oudevicescanningsheetsApi);
            this._functions['getOudevicescanningsheets'] = oudevicescanningsheetsApi.getOudevicescanningsheets
                .bind(oudevicescanningsheetsApi);
            this._functions['postOudevicescanningsheets'] = oudevicescanningsheetsApi.postOudevicescanningsheets
                .bind(oudevicescanningsheetsApi);
            this._functions['putOudevicesinprocs'] = oudevicesinprocsApi.putOudevicesinprocs
                .bind(oudevicesinprocsApi);
            this._functions['putOudevicesites'] = oudevicesitesApi.putOudevicesites
                .bind(oudevicesitesApi);
            this._functions['getOudevicesites'] = oudevicesitesApi.getOudevicesites
                .bind(oudevicesitesApi);
            this._functions['postOudevicesites'] = oudevicesitesApi.postOudevicesites
                .bind(oudevicesitesApi);
            this._functions['putOudownloads'] = oudownloadsApi.putOudownloads
                .bind(oudownloadsApi);
            this._functions['putOudownloadsoption'] = oudownloadsoptionApi.putOudownloadsoption
                .bind(oudownloadsoptionApi);
            this._functions['putOuhealthfundgroups'] = ouhealthfundgroupsApi.putOuhealthfundgroups
                .bind(ouhealthfundgroupsApi);
            this._functions['getOuhealthfundgroups'] = ouhealthfundgroupsApi.getOuhealthfundgroups
                .bind(ouhealthfundgroupsApi);
            this._functions['postOuhealthfundgroups'] = ouhealthfundgroupsApi.postOuhealthfundgroups
                .bind(ouhealthfundgroupsApi);
            this._functions['putOuhealthfunds'] = ouhealthfundsApi.putOuhealthfunds
                .bind(ouhealthfundsApi);
            this._functions['getOuhealthfunds'] = ouhealthfundsApi.getOuhealthfunds
                .bind(ouhealthfundsApi);
            this._functions['postOuhealthfunds'] = ouhealthfundsApi.postOuhealthfunds
                .bind(ouhealthfundsApi);
            this._functions['putOuhealthfundscanningsheet'] = ouhealthfundscanningsheetApi.putOuhealthfundscanningsheet
                .bind(ouhealthfundscanningsheetApi);
            this._functions['putOuhealthfundscanningsheetoptions'] = ouhealthfundscanningsheetoptionsApi.putOuhealthfundscanningsheetoptions
                .bind(ouhealthfundscanningsheetoptionsApi);
            this._functions['putOukitbom'] = oukitbomApi.putOukitbom
                .bind(oukitbomApi);
            this._functions['postOukitbom'] = oukitbomApi.postOukitbom
                .bind(oukitbomApi);
            this._functions['putOukitscanningsheet'] = oukitscanningsheetApi.putOukitscanningsheet
                .bind(oukitscanningsheetApi);
            this._functions['putOukitscanningsheetoptions'] = oukitscanningsheetoptionsApi.putOukitscanningsheetoptions
                .bind(oukitscanningsheetoptionsApi);
            this._functions['putOulocations'] = oulocationsApi.putOulocations
                .bind(oulocationsApi);
            this._functions['getOulocations'] = oulocationsApi.getOulocations
                .bind(oulocationsApi);
            this._functions['postOulocations'] = oulocationsApi.postOulocations
                .bind(oulocationsApi);
            this._functions['putOulocationscanningsheet'] = oulocationscanningsheetApi.putOulocationscanningsheet
                .bind(oulocationscanningsheetApi);
            this._functions['putOulocationscanningsheetoptions'] = oulocationscanningsheetoptionsApi.putOulocationscanningsheetoptions
                .bind(oulocationscanningsheetoptionsApi);
            this._functions['putOulocationtypes'] = oulocationtypesApi.putOulocationtypes
                .bind(oulocationtypesApi);
            this._functions['getOulocationtypes'] = oulocationtypesApi.getOulocationtypes
                .bind(oulocationtypesApi);
            this._functions['postOulocationtypes'] = oulocationtypesApi.postOulocationtypes
                .bind(oulocationtypesApi);
            this._functions['putOumedications'] = oumedicationsApi.putOumedications
                .bind(oumedicationsApi);
            this._functions['getOumedications'] = oumedicationsApi.getOumedications
                .bind(oumedicationsApi);
            this._functions['postOumedications'] = oumedicationsApi.postOumedications
                .bind(oumedicationsApi);
            this._functions['putOupatienttypes'] = oupatienttypesApi.putOupatienttypes
                .bind(oupatienttypesApi);
            this._functions['getOupatienttypes'] = oupatienttypesApi.getOupatienttypes
                .bind(oupatienttypesApi);
            this._functions['postOupatienttypes'] = oupatienttypesApi.postOupatienttypes
                .bind(oupatienttypesApi);
            this._functions['putOupatienttypescanningsheet'] = oupatienttypescanningsheetApi.putOupatienttypescanningsheet
                .bind(oupatienttypescanningsheetApi);
            this._functions['putOupatienttypescanningsheetoptions'] = oupatienttypescanningsheetoptionsApi.putOupatienttypescanningsheetoptions
                .bind(oupatienttypescanningsheetoptionsApi);
            this._functions['putOuproductfamily'] = ouproductfamilyApi.putOuproductfamily
                .bind(ouproductfamilyApi);
            this._functions['getOuproductfamily'] = ouproductfamilyApi.getOuproductfamily
                .bind(ouproductfamilyApi);
            this._functions['putOurequiredby'] = ourequiredbyApi.putOurequiredby
                .bind(ourequiredbyApi);
            this._functions['getOurequiredby'] = ourequiredbyApi.getOurequiredby
                .bind(ourequiredbyApi);
            this._functions['postOurequiredby'] = ourequiredbyApi.postOurequiredby
                .bind(ourequiredbyApi);
            this._functions['putOurequisitiongroups'] = ourequisitiongroupsApi.putOurequisitiongroups
                .bind(ourequisitiongroupsApi);
            this._functions['getOurequisitiongroups'] = ourequisitiongroupsApi.getOurequisitiongroups
                .bind(ourequisitiongroupsApi);
            this._functions['postOurequisitiongroups'] = ourequisitiongroupsApi.postOurequisitiongroups
                .bind(ourequisitiongroupsApi);
            this._functions['putOuscopescanningsheet'] = ouscopescanningsheetApi.putOuscopescanningsheet
                .bind(ouscopescanningsheetApi);
            this._functions['putOuscopescanningsheetoptions'] = ouscopescanningsheetoptionsApi.putOuscopescanningsheetoptions
                .bind(ouscopescanningsheetoptionsApi);
            this._functions['putOuscopeserialnumbers'] = ouscopeserialnumbersApi.putOuscopeserialnumbers
                .bind(ouscopeserialnumbersApi);
            this._functions['getOuscopeserialnumbers'] = ouscopeserialnumbersApi.getOuscopeserialnumbers
                .bind(ouscopeserialnumbersApi);
            this._functions['postOuscopeserialnumbers'] = ouscopeserialnumbersApi.postOuscopeserialnumbers
                .bind(ouscopeserialnumbersApi);
            this._functions['putOustaffreport'] = oustaffreportApi.putOustaffreport
                .bind(oustaffreportApi);
            this._functions['putOustaffreportfilter'] = oustaffreportfilterApi.putOustaffreportfilter
                .bind(oustaffreportfilterApi);
            this._functions['putOustaffscanningsheet'] = oustaffscanningsheetApi.putOustaffscanningsheet
                .bind(oustaffscanningsheetApi);
            this._functions['putOustaffscanningsheetoptions'] = oustaffscanningsheetoptionsApi.putOustaffscanningsheetoptions
                .bind(oustaffscanningsheetoptionsApi);
            this._functions['putOustocktypes'] = oustocktypesApi.putOustocktypes
                .bind(oustocktypesApi);
            this._functions['getOustocktypes'] = oustocktypesApi.getOustocktypes
                .bind(oustocktypesApi);
            this._functions['postOustocktypes'] = oustocktypesApi.postOustocktypes
                .bind(oustocktypesApi);
            this._functions['putOusublocations'] = ousublocationsApi.putOusublocations
                .bind(ousublocationsApi);
            this._functions['getOusublocations'] = ousublocationsApi.getOusublocations
                .bind(ousublocationsApi);
            this._functions['postOusublocations'] = ousublocationsApi.postOusublocations
                .bind(ousublocationsApi);
            this._functions['putOusublocationscanningsheet'] = ousublocationscanningsheetApi.putOusublocationscanningsheet
                .bind(ousublocationscanningsheetApi);
            this._functions['putOusublocationscanningsheetoptions'] = ousublocationscanningsheetoptionsApi.putOusublocationscanningsheetoptions
                .bind(ousublocationscanningsheetoptionsApi);
            this._functions['putOusubsublocations'] = ousubsublocationsApi.putOusubsublocations
                .bind(ousubsublocationsApi);
            this._functions['getOusubsublocations'] = ousubsublocationsApi.getOusubsublocations
                .bind(ousubsublocationsApi);
            this._functions['postOusubsublocations'] = ousubsublocationsApi.postOusubsublocations
                .bind(ousubsublocationsApi);
            this._functions['putOusupplierid'] = ousupplieridApi.putOusupplierid
                .bind(ousupplieridApi);
            this._functions['getOusupplierid'] = ousupplieridApi.getOusupplierid
                .bind(ousupplieridApi);
            this._functions['postOusupplierid'] = ousupplieridApi.postOusupplierid
                .bind(ousupplieridApi);
            this._functions['putOusupplies'] = ousuppliesApi.putOusupplies
                .bind(ousuppliesApi);
            this._functions['getOusupplies'] = ousuppliesApi.getOusupplies
                .bind(ousuppliesApi);
            this._functions['postOusupplies'] = ousuppliesApi.postOusupplies
                .bind(ousuppliesApi);
            this._functions['putOusuppliesaux'] = ousuppliesauxApi.putOusuppliesaux
                .bind(ousuppliesauxApi);
            this._functions['getOusuppliesaux'] = ousuppliesauxApi.getOusuppliesaux
                .bind(ousuppliesauxApi);
            this._functions['postOusuppliesaux'] = ousuppliesauxApi.postOusuppliesaux
                .bind(ousuppliesauxApi);
            this._functions['putOusupplylines'] = ousupplylinesApi.putOusupplylines
                .bind(ousupplylinesApi);
            this._functions['getOusupplylines'] = ousupplylinesApi.getOusupplylines
                .bind(ousupplylinesApi);
            this._functions['postOusupplylines'] = ousupplylinesApi.postOusupplylines
                .bind(ousupplylinesApi);
            this._functions['putOusupplylinesaux'] = ousupplylinesauxApi.putOusupplylinesaux
                .bind(ousupplylinesauxApi);
            this._functions['getOusupplylinesaux'] = ousupplylinesauxApi.getOusupplylinesaux
                .bind(ousupplylinesauxApi);
            this._functions['postOusupplylinesaux'] = ousupplylinesauxApi.postOusupplylinesaux
                .bind(ousupplylinesauxApi);
            this._functions['putOusupplylinesoverview'] = ousupplylinesoverviewApi.putOusupplylinesoverview
                .bind(ousupplylinesoverviewApi);
            this._functions['postOusupplylinesoverview'] = ousupplylinesoverviewApi.postOusupplylinesoverview
                .bind(ousupplylinesoverviewApi);
            this._functions['putOutimeoutdefines'] = outimeoutdefinesApi.putOutimeoutdefines
                .bind(outimeoutdefinesApi);
            this._functions['getOutimeoutdefines'] = outimeoutdefinesApi.getOutimeoutdefines
                .bind(outimeoutdefinesApi);
            this._functions['postOutimeoutdefines'] = outimeoutdefinesApi.postOutimeoutdefines
                .bind(outimeoutdefinesApi);
            this._functions['putOutimeouttabs'] = outimeouttabsApi.putOutimeouttabs
                .bind(outimeouttabsApi);
            this._functions['getOutimeouttabs'] = outimeouttabsApi.getOutimeouttabs
                .bind(outimeouttabsApi);
            this._functions['postOutimeouttabs'] = outimeouttabsApi.postOutimeouttabs
                .bind(outimeouttabsApi);
            this._functions['putOutimerdefines'] = outimerdefinesApi.putOutimerdefines
                .bind(outimerdefinesApi);
            this._functions['getOutimerdefines'] = outimerdefinesApi.getOutimerdefines
                .bind(outimerdefinesApi);
            this._functions['postOutimerdefines'] = outimerdefinesApi.postOutimerdefines
                .bind(outimerdefinesApi);
            this._functions['putOuunitsoverride'] = ouunitsoverrideApi.putOuunitsoverride
                .bind(ouunitsoverrideApi);
            this._functions['postOuunitsoverride'] = ouunitsoverrideApi.postOuunitsoverride
                .bind(ouunitsoverrideApi);
            this._functions['putOuusers'] = ouusersApi.putOuusers
                .bind(ouusersApi);
            this._functions['getOuusers'] = ouusersApi.getOuusers
                .bind(ouusersApi);
            this._functions['postOuusers'] = ouusersApi.postOuusers
                .bind(ouusersApi);
            this._functions['putOuxlate'] = ouxlateApi.putOuxlate
                .bind(ouxlateApi);
            this._functions['postOuxlate'] = ouxlateApi.postOuxlate
                .bind(ouxlateApi);
            this._functions['putOuxlatehistory'] = ouxlatehistoryApi.putOuxlatehistory
                .bind(ouxlatehistoryApi);
            this._functions['putPcaddinstanceitem'] = pcaddinstanceitemApi.putPcaddinstanceitem
                .bind(pcaddinstanceitemApi);
            this._functions['putPcadditem'] = pcadditemApi.putPcadditem
                .bind(pcadditemApi);
            this._functions['putPcbulkpicklistreport'] = pcbulkpicklistreportApi.putPcbulkpicklistreport
                .bind(pcbulkpicklistreportApi);
            this._functions['putPcbulkpicklistreportfilter'] = pcbulkpicklistreportfilterApi.putPcbulkpicklistreportfilter
                .bind(pcbulkpicklistreportfilterApi);
            this._functions['putPcbulkpicklistreportview'] = pcbulkpicklistreportviewApi.putPcbulkpicklistreportview
                .bind(pcbulkpicklistreportviewApi);
            this._functions['getPcbulkpicklistreportview'] = pcbulkpicklistreportviewApi.getPcbulkpicklistreportview
                .bind(pcbulkpicklistreportviewApi);
            this._functions['postPcbulkpicklistreportview'] = pcbulkpicklistreportviewApi.postPcbulkpicklistreportview
                .bind(pcbulkpicklistreportviewApi);
            this._functions['putPccontract4supplier'] = pccontract4supplierApi.putPccontract4supplier
                .bind(pccontract4supplierApi);
            this._functions['putPcdetailsreport'] = pcdetailsreportApi.putPcdetailsreport
                .bind(pcdetailsreportApi);
            this._functions['putPcdetailsreportfilter'] = pcdetailsreportfilterApi.putPcdetailsreportfilter
                .bind(pcdetailsreportfilterApi);
            this._functions['putPcdetailsreportpdf'] = pcdetailsreportpdfApi.putPcdetailsreportpdf
                .bind(pcdetailsreportpdfApi);
            this._functions['putPcdiathermies'] = pcdiathermiesApi.putPcdiathermies
                .bind(pcdiathermiesApi);
            this._functions['getPcdiathermies'] = pcdiathermiesApi.getPcdiathermies
                .bind(pcdiathermiesApi);
            this._functions['postPcdiathermies'] = pcdiathermiesApi.postPcdiathermies
                .bind(pcdiathermiesApi);
            this._functions['putPcedit'] = pceditApi.putPcedit
                .bind(pceditApi);
            this._functions['postPcedit'] = pceditApi.postPcedit
                .bind(pceditApi);
            this._functions['putPcfileupload'] = pcfileuploadApi.putPcfileupload
                .bind(pcfileuploadApi);
            this._functions['getPcfileupload'] = pcfileuploadApi.getPcfileupload
                .bind(pcfileuploadApi);
            this._functions['postPcfileupload'] = pcfileuploadApi.postPcfileupload
                .bind(pcfileuploadApi);
            this._functions['putPcglobaladddevice'] = pcglobaladddeviceApi.putPcglobaladddevice
                .bind(pcglobaladddeviceApi);
            this._functions['getPcglobaladddevice'] = pcglobaladddeviceApi.getPcglobaladddevice
                .bind(pcglobaladddeviceApi);
            this._functions['postPcglobaladddevice'] = pcglobaladddeviceApi.postPcglobaladddevice
                .bind(pcglobaladddeviceApi);
            this._functions['putPcglobalsubstitute'] = pcglobalsubstituteApi.putPcglobalsubstitute
                .bind(pcglobalsubstituteApi);
            this._functions['getPcglobalsubstitute'] = pcglobalsubstituteApi.getPcglobalsubstitute
                .bind(pcglobalsubstituteApi);
            this._functions['postPcglobalsubstitute'] = pcglobalsubstituteApi.postPcglobalsubstitute
                .bind(pcglobalsubstituteApi);
            this._functions['getImage'] = pcimageApi.getImage
                .bind(pcimageApi);
            this._functions['putImage'] = pcimageApi.putImage
                .bind(pcimageApi);
            this._functions['deleteImage'] = pcimageApi.deleteImage
                .bind(pcimageApi);
            this._functions['putPcincompletepickupreport'] = pcincompletepickupreportApi.putPcincompletepickupreport
                .bind(pcincompletepickupreportApi);
            this._functions['putPcincompletepickupreportfilter'] = pcincompletepickupreportfilterApi.putPcincompletepickupreportfilter
                .bind(pcincompletepickupreportfilterApi);
            this._functions['putPcinstance'] = pcinstanceApi.putPcinstance
                .bind(pcinstanceApi);
            this._functions['getPcinstance'] = pcinstanceApi.getPcinstance
                .bind(pcinstanceApi);
            this._functions['postPcinstance'] = pcinstanceApi.postPcinstance
                .bind(pcinstanceApi);
            this._functions['putPcinstancelist'] = pcinstancelistApi.putPcinstancelist
                .bind(pcinstancelistApi);
            this._functions['getPcinstancelist'] = pcinstancelistApi.getPcinstancelist
                .bind(pcinstancelistApi);
            this._functions['postPcinstancelist'] = pcinstancelistApi.postPcinstancelist
                .bind(pcinstancelistApi);
            this._functions['putPcinstruments'] = pcinstrumentsApi.putPcinstruments
                .bind(pcinstrumentsApi);
            this._functions['getPcinstruments'] = pcinstrumentsApi.getPcinstruments
                .bind(pcinstrumentsApi);
            this._functions['postPcinstruments'] = pcinstrumentsApi.postPcinstruments
                .bind(pcinstrumentsApi);
            this._functions['putPcinstrumentsdemandreport'] = pcinstrumentsdemandreportApi.putPcinstrumentsdemandreport
                .bind(pcinstrumentsdemandreportApi);
            this._functions['putPcinstrumentsdemandreportfilter'] = pcinstrumentsdemandreportfilterApi.putPcinstrumentsdemandreportfilter
                .bind(pcinstrumentsdemandreportfilterApi);
            this._functions['putPclist'] = pclistApi.putPclist
                .bind(pclistApi);
            this._functions['getPclist'] = pclistApi.getPclist
                .bind(pclistApi);
            this._functions['postPclist'] = pclistApi.postPclist
                .bind(pclistApi);
            this._functions['putPclistreport'] = pclistreportApi.putPclistreport
                .bind(pclistreportApi);
            this._functions['postPclistreport'] = pclistreportApi.postPclistreport
                .bind(pclistreportApi);
            this._functions['putPclistreportfilter'] = pclistreportfilterApi.putPclistreportfilter
                .bind(pclistreportfilterApi);
            this._functions['putPcmaintain'] = pcmaintainApi.putPcmaintain
                .bind(pcmaintainApi);
            this._functions['getPcmaintain'] = pcmaintainApi.getPcmaintain
                .bind(pcmaintainApi);
            this._functions['postPcmaintain'] = pcmaintainApi.postPcmaintain
                .bind(pcmaintainApi);
            this._functions['getPcmenu'] = pcmenuApi.getPcmenu
                .bind(pcmenuApi);
            this._functions['putPcnew'] = pcnewApi.putPcnew
                .bind(pcnewApi);
            this._functions['getPcnew'] = pcnewApi.getPcnew
                .bind(pcnewApi);
            this._functions['postPcnew'] = pcnewApi.postPcnew
                .bind(pcnewApi);
            this._functions['putPcnewinstance'] = pcnewinstanceApi.putPcnewinstance
                .bind(pcnewinstanceApi);
            this._functions['getPcnewinstance'] = pcnewinstanceApi.getPcnewinstance
                .bind(pcnewinstanceApi);
            this._functions['postPcnewinstance'] = pcnewinstanceApi.postPcnewinstance
                .bind(pcnewinstanceApi);
            this._functions['putPcnewstaff'] = pcnewstaffApi.putPcnewstaff
                .bind(pcnewstaffApi);
            this._functions['getPcnewstaff'] = pcnewstaffApi.getPcnewstaff
                .bind(pcnewstaffApi);
            this._functions['postPcnewstaff'] = pcnewstaffApi.postPcnewstaff
                .bind(pcnewstaffApi);
            this._functions['putPcpatientpositions'] = pcpatientpositionsApi.putPcpatientpositions
                .bind(pcpatientpositionsApi);
            this._functions['getPcpatientpositions'] = pcpatientpositionsApi.getPcpatientpositions
                .bind(pcpatientpositionsApi);
            this._functions['postPcpatientpositions'] = pcpatientpositionsApi.postPcpatientpositions
                .bind(pcpatientpositionsApi);
            this._functions['putPcpickdelayreasons'] = pcpickdelayreasonsApi.putPcpickdelayreasons
                .bind(pcpickdelayreasonsApi);
            this._functions['getPcpickdelayreasons'] = pcpickdelayreasonsApi.getPcpickdelayreasons
                .bind(pcpickdelayreasonsApi);
            this._functions['postPcpickdelayreasons'] = pcpickdelayreasonsApi.postPcpickdelayreasons
                .bind(pcpickdelayreasonsApi);
            this._functions['putPcprefcardusagereport'] = pcprefcardusagereportApi.putPcprefcardusagereport
                .bind(pcprefcardusagereportApi);
            this._functions['putPcprefcardusagereportfilter'] = pcprefcardusagereportfilterApi.putPcprefcardusagereportfilter
                .bind(pcprefcardusagereportfilterApi);
            this._functions['putPcpreparations'] = pcpreparationsApi.putPcpreparations
                .bind(pcpreparationsApi);
            this._functions['getPcpreparations'] = pcpreparationsApi.getPcpreparations
                .bind(pcpreparationsApi);
            this._functions['postPcpreparations'] = pcpreparationsApi.postPcpreparations
                .bind(pcpreparationsApi);
            this._functions['putPcprint'] = pcprintApi.putPcprint
                .bind(pcprintApi);
            this._functions['putPcproducts4suppliercontract'] = pcproducts4suppliercontractApi.putPcproducts4suppliercontract
                .bind(pcproducts4suppliercontractApi);
            this._functions['putPcproductsdemandreport'] = pcproductsdemandreportApi.putPcproductsdemandreport
                .bind(pcproductsdemandreportApi);
            this._functions['putPcproductsdemandreportfilter'] = pcproductsdemandreportfilterApi.putPcproductsdemandreportfilter
                .bind(pcproductsdemandreportfilterApi);
            this._functions['putPcreplacecustomdesc'] = pcreplacecustomdescApi.putPcreplacecustomdesc
                .bind(pcreplacecustomdescApi);
            this._functions['postPcreplacecustomdesc'] = pcreplacecustomdescApi.postPcreplacecustomdesc
                .bind(pcreplacecustomdescApi);
            this._functions['putPcreplacedevice'] = pcreplacedeviceApi.putPcreplacedevice
                .bind(pcreplacedeviceApi);
            this._functions['postPcreplacedevice'] = pcreplacedeviceApi.postPcreplacedevice
                .bind(pcreplacedeviceApi);
            this._functions['putPcreplaceprodcategory'] = pcreplaceprodcategoryApi.putPcreplaceprodcategory
                .bind(pcreplaceprodcategoryApi);
            this._functions['postPcreplaceprodcategory'] = pcreplaceprodcategoryApi.postPcreplaceprodcategory
                .bind(pcreplaceprodcategoryApi);
            this._functions['putPcspecialities'] = pcspecialitiesApi.putPcspecialities
                .bind(pcspecialitiesApi);
            this._functions['getPcspecialities'] = pcspecialitiesApi.getPcspecialities
                .bind(pcspecialitiesApi);
            this._functions['postPcspecialities'] = pcspecialitiesApi.postPcspecialities
                .bind(pcspecialitiesApi);
            this._functions['putPcstaff'] = pcstaffApi.putPcstaff
                .bind(pcstaffApi);
            this._functions['getPcstaff'] = pcstaffApi.getPcstaff
                .bind(pcstaffApi);
            this._functions['postPcstaff'] = pcstaffApi.postPcstaff
                .bind(pcstaffApi);
            this._functions['putPcsubreport'] = pcsubreportApi.putPcsubreport
                .bind(pcsubreportApi);
            this._functions['getPcsubreport'] = pcsubreportApi.getPcsubreport
                .bind(pcsubreportApi);
            this._functions['putPcsubstitute'] = pcsubstituteApi.putPcsubstitute
                .bind(pcsubstituteApi);
            this._functions['getPcsubstitute'] = pcsubstituteApi.getPcsubstitute
                .bind(pcsubstituteApi);
            this._functions['postPcsubstitute'] = pcsubstituteApi.postPcsubstitute
                .bind(pcsubstituteApi);
            this._functions['putPcsubstitutelist'] = pcsubstitutelistApi.putPcsubstitutelist
                .bind(pcsubstitutelistApi);
            this._functions['getPcsubstitutelist'] = pcsubstitutelistApi.getPcsubstitutelist
                .bind(pcsubstitutelistApi);
            this._functions['postPcsubstitutelist'] = pcsubstitutelistApi.postPcsubstitutelist
                .bind(pcsubstitutelistApi);
            this._functions['putPcsurgicalgloves'] = pcsurgicalglovesApi.putPcsurgicalgloves
                .bind(pcsurgicalglovesApi);
            this._functions['getPcsurgicalgloves'] = pcsurgicalglovesApi.getPcsurgicalgloves
                .bind(pcsurgicalglovesApi);
            this._functions['postPcsurgicalgloves'] = pcsurgicalglovesApi.postPcsurgicalgloves
                .bind(pcsurgicalglovesApi);
            this._functions['putPctheatrebookingassign'] = pctheatrebookingassignApi.putPctheatrebookingassign
                .bind(pctheatrebookingassignApi);
            this._functions['getPctheatrebookingassign'] = pctheatrebookingassignApi.getPctheatrebookingassign
                .bind(pctheatrebookingassignApi);
            this._functions['postPctheatrebookingassign'] = pctheatrebookingassignApi.postPctheatrebookingassign
                .bind(pctheatrebookingassignApi);
            this._functions['putPctheatrebookingnew'] = pctheatrebookingnewApi.putPctheatrebookingnew
                .bind(pctheatrebookingnewApi);
            this._functions['getPctheatrebookingnew'] = pctheatrebookingnewApi.getPctheatrebookingnew
                .bind(pctheatrebookingnewApi);
            this._functions['postPctheatrebookingnew'] = pctheatrebookingnewApi.postPctheatrebookingnew
                .bind(pctheatrebookingnewApi);
            this._functions['putPcunusedcardreport'] = pcunusedcardreportApi.putPcunusedcardreport
                .bind(pcunusedcardreportApi);
            this._functions['putPendingbatchorderssummary'] = pendingbatchorderssummaryApi.putPendingbatchorderssummary
                .bind(pendingbatchorderssummaryApi);
            this._functions['putPendingbatchorderview'] = pendingbatchorderviewApi.putPendingbatchorderview
                .bind(pendingbatchorderviewApi);
            this._functions['putPickingsliporderdetail'] = pickingsliporderdetailApi.putPickingsliporderdetail
                .bind(pickingsliporderdetailApi);
            this._functions['putPickingsliporders'] = pickingslipordersApi.putPickingsliporders
                .bind(pickingslipordersApi);
            this._functions['putPickingslipordersfilter'] = pickingslipordersfilterApi.putPickingslipordersfilter
                .bind(pickingslipordersfilterApi);
            this._functions['putPoreceiptingreport'] = poreceiptingreportApi.putPoreceiptingreport
                .bind(poreceiptingreportApi);
            this._functions['putPoreceiptingreportfilter'] = poreceiptingreportfilterApi.putPoreceiptingreportfilter
                .bind(poreceiptingreportfilterApi);
            this._functions['putPrapprovalsmaint'] = prapprovalsmaintApi.putPrapprovalsmaint
                .bind(prapprovalsmaintApi);
            this._functions['getPrapprovalsmaint'] = prapprovalsmaintApi.getPrapprovalsmaint
                .bind(prapprovalsmaintApi);
            this._functions['postPrapprovalsmaint'] = prapprovalsmaintApi.postPrapprovalsmaint
                .bind(prapprovalsmaintApi);
            this._functions['putPrefcardscanbarcode'] = prefcardscanbarcodeApi.putPrefcardscanbarcode
                .bind(prefcardscanbarcodeApi);
            this._functions['postPrefcardscanbarcode'] = prefcardscanbarcodeApi.postPrefcardscanbarcode
                .bind(prefcardscanbarcodeApi);
            this._functions['putPrefcardupdatedevicescanned'] = prefcardupdatedevicescannedApi.putPrefcardupdatedevicescanned
                .bind(prefcardupdatedevicescannedApi);
            this._functions['postPrefcardupdatedevicescanned'] = prefcardupdatedevicescannedApi.postPrefcardupdatedevicescanned
                .bind(prefcardupdatedevicescannedApi);
            this._functions['putPricinglistreleases'] = pricinglistreleasesApi.putPricinglistreleases
                .bind(pricinglistreleasesApi);
            this._functions['getPricinglistreleases'] = pricinglistreleasesApi.getPricinglistreleases
                .bind(pricinglistreleasesApi);
            this._functions['putProcedurebillinglist'] = procedurebillinglistApi.putProcedurebillinglist
                .bind(procedurebillinglistApi);
            this._functions['postProcedurebillinglist'] = procedurebillinglistApi.postProcedurebillinglist
                .bind(procedurebillinglistApi);
            this._functions['putProcedurebillinglistfilter'] = procedurebillinglistfilterApi.putProcedurebillinglistfilter
                .bind(procedurebillinglistfilterApi);
            this._functions['putProcedurecategory'] = procedurecategoryApi.putProcedurecategory
                .bind(procedurecategoryApi);
            this._functions['getProcedurecategory'] = procedurecategoryApi.getProcedurecategory
                .bind(procedurecategoryApi);
            this._functions['postProcedurecategory'] = procedurecategoryApi.postProcedurecategory
                .bind(procedurecategoryApi);
            this._functions['putProceduredetails'] = proceduredetailsApi.putProceduredetails
                .bind(proceduredetailsApi);
            this._functions['putProceduredetailsfilter'] = proceduredetailsfilterApi.putProceduredetailsfilter
                .bind(proceduredetailsfilterApi);
            this._functions['putProceduredetailspdf'] = proceduredetailspdfApi.putProceduredetailspdf
                .bind(proceduredetailspdfApi);
            this._functions['putProcedurelist'] = procedurelistApi.putProcedurelist
                .bind(procedurelistApi);
            this._functions['postProcedurelist'] = procedurelistApi.postProcedurelist
                .bind(procedurelistApi);
            this._functions['putProcedurelistfilter'] = procedurelistfilterApi.putProcedurelistfilter
                .bind(procedurelistfilterApi);
            this._functions['putProceduremerge'] = proceduremergeApi.putProceduremerge
                .bind(proceduremergeApi);
            this._functions['postProceduremerge'] = proceduremergeApi.postProceduremerge
                .bind(proceduremergeApi);
            this._functions['putProcedurestatus'] = procedurestatusApi.putProcedurestatus
                .bind(procedurestatusApi);
            this._functions['getProcedurestatus'] = procedurestatusApi.getProcedurestatus
                .bind(procedurestatusApi);
            this._functions['postProcedurestatus'] = procedurestatusApi.postProcedurestatus
                .bind(procedurestatusApi);
            this._functions['putProceduretimeoutreport'] = proceduretimeoutreportApi.putProceduretimeoutreport
                .bind(proceduretimeoutreportApi);
            this._functions['putProceduretimeoutreportfilter'] = proceduretimeoutreportfilterApi.putProceduretimeoutreportfilter
                .bind(proceduretimeoutreportfilterApi);
            this._functions['putProceduretimestampsreport'] = proceduretimestampsreportApi.putProceduretimestampsreport
                .bind(proceduretimestampsreportApi);
            this._functions['putProceduretimestampsreportfilter'] = proceduretimestampsreportfilterApi.putProceduretimestampsreportfilter
                .bind(proceduretimestampsreportfilterApi);
            this._functions['putProceduretype'] = proceduretypeApi.putProceduretype
                .bind(proceduretypeApi);
            this._functions['getProceduretype'] = proceduretypeApi.getProceduretype
                .bind(proceduretypeApi);
            this._functions['postProceduretype'] = proceduretypeApi.postProceduretype
                .bind(proceduretypeApi);
            this._functions['getProcmenu'] = procmenuApi.getProcmenu
                .bind(procmenuApi);
            this._functions['putProctypeitemcodes'] = proctypeitemcodesApi.putProctypeitemcodes
                .bind(proctypeitemcodesApi);
            this._functions['getProctypeitemcodes'] = proctypeitemcodesApi.getProctypeitemcodes
                .bind(proctypeitemcodesApi);
            this._functions['postProctypeitemcodes'] = proctypeitemcodesApi.postProctypeitemcodes
                .bind(proctypeitemcodesApi);
            this._functions['putProctypereport'] = proctypereportApi.putProctypereport
                .bind(proctypereportApi);
            this._functions['putProctypereportfilter'] = proctypereportfilterApi.putProctypereportfilter
                .bind(proctypereportfilterApi);
            this._functions['putProctypescanningsheet'] = proctypescanningsheetApi.putProctypescanningsheet
                .bind(proctypescanningsheetApi);
            this._functions['putProctypescanningsheetoptions'] = proctypescanningsheetoptionsApi.putProctypescanningsheetoptions
                .bind(proctypescanningsheetoptionsApi);
            this._functions['putProctypesites'] = proctypesitesApi.putProctypesites
                .bind(proctypesitesApi);
            this._functions['getProctypesites'] = proctypesitesApi.getProctypesites
                .bind(proctypesitesApi);
            this._functions['postProctypesites'] = proctypesitesApi.postProctypesites
                .bind(proctypesitesApi);
            this._functions['putProctypeview'] = proctypeviewApi.putProctypeview
                .bind(proctypeviewApi);
            this._functions['putProdfmlydetailsbyspc'] = prodfmlydetailsbyspcApi.putProdfmlydetailsbyspc
                .bind(prodfmlydetailsbyspcApi);
            this._functions['putProdfmlydetailsbyspcfilter'] = prodfmlydetailsbyspcfilterApi.putProdfmlydetailsbyspcfilter
                .bind(prodfmlydetailsbyspcfilterApi);
            this._functions['putProducts4supplier'] = products4supplierApi.putProducts4supplier
                .bind(products4supplierApi);
            this._functions['putProductsinacontract4supplier'] = productsinacontract4supplierApi.putProductsinacontract4supplier
                .bind(productsinacontract4supplierApi);
            this._functions['putProductsinacontract4supplierfilter'] = productsinacontract4supplierfilterApi.putProductsinacontract4supplierfilter
                .bind(productsinacontract4supplierfilterApi);
            this._functions['putProductusagelist'] = productusagelistApi.putProductusagelist
                .bind(productusagelistApi);
            this._functions['putProductusagelistfilter'] = productusagelistfilterApi.putProductusagelistfilter
                .bind(productusagelistfilterApi);
            this._functions['putProsthesespricelist'] = prosthesespricelistApi.putProsthesespricelist
                .bind(prosthesespricelistApi);
            this._functions['getProsthesespricelist'] = prosthesespricelistApi.getProsthesespricelist
                .bind(prosthesespricelistApi);
            this._functions['postProsthesespricelist'] = prosthesespricelistApi.postProsthesespricelist
                .bind(prosthesespricelistApi);
            this._functions['putProsthesespricingreport'] = prosthesespricingreportApi.putProsthesespricingreport
                .bind(prosthesespricingreportApi);
            this._functions['putProsthesespricingreportfilter'] = prosthesespricingreportfilterApi.putProsthesespricingreportfilter
                .bind(prosthesespricingreportfilterApi);
            this._functions['putPurchaseorderdetail'] = purchaseorderdetailApi.putPurchaseorderdetail
                .bind(purchaseorderdetailApi);
            this._functions['putPurchaseorderlines'] = purchaseorderlinesApi.putPurchaseorderlines
                .bind(purchaseorderlinesApi);
            this._functions['postPurchaseorderlines'] = purchaseorderlinesApi.postPurchaseorderlines
                .bind(purchaseorderlinesApi);
            this._functions['putPurchaseordersummary'] = purchaseordersummaryApi.putPurchaseordersummary
                .bind(purchaseordersummaryApi);
            this._functions['getPurchmenu'] = purchmenuApi.getPurchmenu
                .bind(purchmenuApi);
            this._functions['putPurchorderdetails'] = purchorderdetailsApi.putPurchorderdetails
                .bind(purchorderdetailsApi);
            this._functions['putPurchorderdetailsfilter'] = purchorderdetailsfilterApi.putPurchorderdetailsfilter
                .bind(purchorderdetailsfilterApi);
            this._functions['putPurchorderlist'] = purchorderlistApi.putPurchorderlist
                .bind(purchorderlistApi);
            this._functions['putPurchorderlistfilter'] = purchorderlistfilterApi.putPurchorderlistfilter
                .bind(purchorderlistfilterApi);
            this._functions['putPurchorderstockreceipt'] = purchorderstockreceiptApi.putPurchorderstockreceipt
                .bind(purchorderstockreceiptApi);
            this._functions['postPurchorderstockreceipt'] = purchorderstockreceiptApi.postPurchorderstockreceipt
                .bind(purchorderstockreceiptApi);
            this._functions['putPurchorderstockreceipthistory'] = purchorderstockreceipthistoryApi.putPurchorderstockreceipthistory
                .bind(purchorderstockreceipthistoryApi);
            this._functions['postPurchorderstockreceipthistory'] = purchorderstockreceipthistoryApi.postPurchorderstockreceipthistory
                .bind(purchorderstockreceipthistoryApi);
            this._functions['putPurchorderstockreceipthistorypo'] = purchorderstockreceipthistorypoApi.putPurchorderstockreceipthistorypo
                .bind(purchorderstockreceipthistorypoApi);
            this._functions['postPurchorderstockreceipthistorypo'] = purchorderstockreceipthistorypoApi.postPurchorderstockreceipthistorypo
                .bind(purchorderstockreceipthistorypoApi);
            this._functions['putPurchorderstockreceiptpo'] = purchorderstockreceiptpoApi.putPurchorderstockreceiptpo
                .bind(purchorderstockreceiptpoApi);
            this._functions['postPurchorderstockreceiptpo'] = purchorderstockreceiptpoApi.postPurchorderstockreceiptpo
                .bind(purchorderstockreceiptpoApi);
            this._functions['putPurchorderstockreceiptscan'] = purchorderstockreceiptscanApi.putPurchorderstockreceiptscan
                .bind(purchorderstockreceiptscanApi);
            this._functions['putPurchorderstockreceiptscanfilter'] = purchorderstockreceiptscanfilterApi.putPurchorderstockreceiptscanfilter
                .bind(purchorderstockreceiptscanfilterApi);
            this._functions['putPurchorderstockreceiptscanfilterpo'] = purchorderstockreceiptscanfilterpoApi.putPurchorderstockreceiptscanfilterpo
                .bind(purchorderstockreceiptscanfilterpoApi);
            this._functions['putPurchorderstockreceiptscanpo'] = purchorderstockreceiptscanpoApi.putPurchorderstockreceiptscanpo
                .bind(purchorderstockreceiptscanpoApi);
            this._functions['putPurchproductlist'] = purchproductlistApi.putPurchproductlist
                .bind(purchproductlistApi);
            this._functions['putPurchproductlistfilter'] = purchproductlistfilterApi.putPurchproductlistfilter
                .bind(purchproductlistfilterApi);
            this._functions['putReassignconsumption'] = reassignconsumptionApi.putReassignconsumption
                .bind(reassignconsumptionApi);
            this._functions['getReassignconsumption'] = reassignconsumptionApi.getReassignconsumption
                .bind(reassignconsumptionApi);
            this._functions['postReassignconsumption'] = reassignconsumptionApi.postReassignconsumption
                .bind(reassignconsumptionApi);
            this._functions['putReassignconsumptionfilter'] = reassignconsumptionfilterApi.putReassignconsumptionfilter
                .bind(reassignconsumptionfilterApi);
            this._functions['putReorderstatus'] = reorderstatusApi.putReorderstatus
                .bind(reorderstatusApi);
            this._functions['getReorderstatus'] = reorderstatusApi.getReorderstatus
                .bind(reorderstatusApi);
            this._functions['postReorderstatus'] = reorderstatusApi.postReorderstatus
                .bind(reorderstatusApi);
            this._functions['putReorderstatusfilter'] = reorderstatusfilterApi.putReorderstatusfilter
                .bind(reorderstatusfilterApi);
            this._functions['putSchedule5report'] = schedule5reportApi.putSchedule5report
                .bind(schedule5reportApi);
            this._functions['putSchedule5reportfilter'] = schedule5reportfilterApi.putSchedule5reportfilter
                .bind(schedule5reportfilterApi);
            this._functions['putSchedule5reportpdf'] = schedule5reportpdfApi.putSchedule5reportpdf
                .bind(schedule5reportpdfApi);
            this._functions['putSjogcsvbillingreportfilter'] = sjogcsvbillingreportfilterApi.putSjogcsvbillingreportfilter
                .bind(sjogcsvbillingreportfilterApi);
            this._functions['putSjogpatientbillingreport'] = sjogpatientbillingreportApi.putSjogpatientbillingreport
                .bind(sjogpatientbillingreportApi);
            this._functions['putSjogpatientbillingreportfilter'] = sjogpatientbillingreportfilterApi.putSjogpatientbillingreportfilter
                .bind(sjogpatientbillingreportfilterApi);
            this._functions['putSohnondepartmentreport'] = sohnondepartmentreportApi.putSohnondepartmentreport
                .bind(sohnondepartmentreportApi);
            this._functions['putSohspcdetails'] = sohspcdetailsApi.putSohspcdetails
                .bind(sohspcdetailsApi);
            this._functions['putSpanspcincurrentcontracts'] = spanspcincurrentcontractsApi.putSpanspcincurrentcontracts
                .bind(spanspcincurrentcontractsApi);
            this._functions['putSpanspcincurrentcontractsfilter'] = spanspcincurrentcontractsfilterApi.putSpanspcincurrentcontractsfilter
                .bind(spanspcincurrentcontractsfilterApi);
            this._functions['putSpbackorderlist'] = spbackorderlistApi.putSpbackorderlist
                .bind(spbackorderlistApi);
            this._functions['putSpbackorderlistfilter'] = spbackorderlistfilterApi.putSpbackorderlistfilter
                .bind(spbackorderlistfilterApi);
            this._functions['putSpcs4supplier'] = spcs4supplierApi.putSpcs4supplier
                .bind(spcs4supplierApi);
            this._functions['putSpdonotreorderreport'] = spdonotreorderreportApi.putSpdonotreorderreport
                .bind(spdonotreorderreportApi);
            this._functions['putSpdonotreorderreportfilter'] = spdonotreorderreportfilterApi.putSpdonotreorderreportfilter
                .bind(spdonotreorderreportfilterApi);
            this._functions['putSpeditetadetails'] = speditetadetailsApi.putSpeditetadetails
                .bind(speditetadetailsApi);
            this._functions['postSpeditetadetails'] = speditetadetailsApi.postSpeditetadetails
                .bind(speditetadetailsApi);
            this._functions['putSpimplantablesreport'] = spimplantablesreportApi.putSpimplantablesreport
                .bind(spimplantablesreportApi);
            this._functions['putSpimplantablesreportfilter'] = spimplantablesreportfilterApi.putSpimplantablesreportfilter
                .bind(spimplantablesreportfilterApi);
            this._functions['putSpnotusedproductsreport'] = spnotusedproductsreportApi.putSpnotusedproductsreport
                .bind(spnotusedproductsreportApi);
            this._functions['putSpnotusedproductsreportfilter'] = spnotusedproductsreportfilterApi.putSpnotusedproductsreportfilter
                .bind(spnotusedproductsreportfilterApi);
            this._functions['putSpporeceiptingreport'] = spporeceiptingreportApi.putSpporeceiptingreport
                .bind(spporeceiptingreportApi);
            this._functions['putSpporeceiptingreportfilter'] = spporeceiptingreportfilterApi.putSpporeceiptingreportfilter
                .bind(spporeceiptingreportfilterApi);
            this._functions['putSpprocedurelist'] = spprocedurelistApi.putSpprocedurelist
                .bind(spprocedurelistApi);
            this._functions['postSpprocedurelist'] = spprocedurelistApi.postSpprocedurelist
                .bind(spprocedurelistApi);
            this._functions['putSpprocedurelistfilter'] = spprocedurelistfilterApi.putSpprocedurelistfilter
                .bind(spprocedurelistfilterApi);
            this._functions['putSpprocedurelistproducts'] = spprocedurelistproductsApi.putSpprocedurelistproducts
                .bind(spprocedurelistproductsApi);
            this._functions['putSpproductsinacontract4supplier'] = spproductsinacontract4supplierApi.putSpproductsinacontract4supplier
                .bind(spproductsinacontract4supplierApi);
            this._functions['putSpproductsinacontract4supplierfilter'] = spproductsinacontract4supplierfilterApi.putSpproductsinacontract4supplierfilter
                .bind(spproductsinacontract4supplierfilterApi);
            this._functions['putSpproductusagelist'] = spproductusagelistApi.putSpproductusagelist
                .bind(spproductusagelistApi);
            this._functions['putSpproductusagelistfilter'] = spproductusagelistfilterApi.putSpproductusagelistfilter
                .bind(spproductusagelistfilterApi);
            this._functions['putSppurchorderdetails'] = sppurchorderdetailsApi.putSppurchorderdetails
                .bind(sppurchorderdetailsApi);
            this._functions['putSppurchorderdetailsfilter'] = sppurchorderdetailsfilterApi.putSppurchorderdetailsfilter
                .bind(sppurchorderdetailsfilterApi);
            this._functions['putSppurchorderlist'] = sppurchorderlistApi.putSppurchorderlist
                .bind(sppurchorderlistApi);
            this._functions['putSppurchorderlistfilter'] = sppurchorderlistfilterApi.putSppurchorderlistfilter
                .bind(sppurchorderlistfilterApi);
            this._functions['putSppurchproductlist'] = sppurchproductlistApi.putSppurchproductlist
                .bind(sppurchproductlistApi);
            this._functions['putSppurchproductlistfilter'] = sppurchproductlistfilterApi.putSppurchproductlistfilter
                .bind(sppurchproductlistfilterApi);
            this._functions['putSpschedule5report'] = spschedule5reportApi.putSpschedule5report
                .bind(spschedule5reportApi);
            this._functions['putSpschedule5reportfilter'] = spschedule5reportfilterApi.putSpschedule5reportfilter
                .bind(spschedule5reportfilterApi);
            this._functions['putSpsohnondepartmentreport'] = spsohnondepartmentreportApi.putSpsohnondepartmentreport
                .bind(spsohnondepartmentreportApi);
            this._functions['putSpsohspcdetails'] = spsohspcdetailsApi.putSpsohspcdetails
                .bind(spsohspcdetailsApi);
            this._functions['putSpstockonhandreport'] = spstockonhandreportApi.putSpstockonhandreport
                .bind(spstockonhandreportApi);
            this._functions['putSpstockonhandreportfilter'] = spstockonhandreportfilterApi.putSpstockonhandreportfilter
                .bind(spstockonhandreportfilterApi);
            this._functions['putSpstockreportbyspc'] = spstockreportbyspcApi.putSpstockreportbyspc
                .bind(spstockreportbyspcApi);
            this._functions['putStaff'] = staffApi.putStaff
                .bind(staffApi);
            this._functions['getStaff'] = staffApi.getStaff
                .bind(staffApi);
            this._functions['postStaff'] = staffApi.postStaff
                .bind(staffApi);
            this._functions['putStaffreportemaillist'] = staffreportemaillistApi.putStaffreportemaillist
                .bind(staffreportemaillistApi);
            this._functions['getStaffreportemaillist'] = staffreportemaillistApi.getStaffreportemaillist
                .bind(staffreportemaillistApi);
            this._functions['postStaffreportemaillist'] = staffreportemaillistApi.postStaffreportemaillist
                .bind(staffreportemaillistApi);
            this._functions['putStaffroles'] = staffrolesApi.putStaffroles
                .bind(staffrolesApi);
            this._functions['getStaffroles'] = staffrolesApi.getStaffroles
                .bind(staffrolesApi);
            this._functions['postStaffroles'] = staffrolesApi.postStaffroles
                .bind(staffrolesApi);
            this._functions['putStafftype'] = stafftypeApi.putStafftype
                .bind(stafftypeApi);
            this._functions['getStafftype'] = stafftypeApi.getStafftype
                .bind(stafftypeApi);
            this._functions['postStafftype'] = stafftypeApi.postStafftype
                .bind(stafftypeApi);
            this._functions['putStafftyperoles'] = stafftyperolesApi.putStafftyperoles
                .bind(stafftyperolesApi);
            this._functions['postStafftyperoles'] = stafftyperolesApi.postStafftyperoles
                .bind(stafftyperolesApi);
            this._functions['putStaffview'] = staffviewApi.putStaffview
                .bind(staffviewApi);
            this._functions['putStockadjust'] = stockadjustApi.putStockadjust
                .bind(stockadjustApi);
            this._functions['postStockadjust'] = stockadjustApi.postStockadjust
                .bind(stockadjustApi);
            this._functions['putStockadjustfilter'] = stockadjustfilterApi.putStockadjustfilter
                .bind(stockadjustfilterApi);
            this._functions['putStockonhandreport'] = stockonhandreportApi.putStockonhandreport
                .bind(stockonhandreportApi);
            this._functions['putStockonhandreportbyexpirydate'] = stockonhandreportbyexpirydateApi.putStockonhandreportbyexpirydate
                .bind(stockonhandreportbyexpirydateApi);
            this._functions['putStockonhandreportbyexpirydatefilter'] = stockonhandreportbyexpirydatefilterApi.putStockonhandreportbyexpirydatefilter
                .bind(stockonhandreportbyexpirydatefilterApi);
            this._functions['putStockonhandreportbyexpirydatepdf'] = stockonhandreportbyexpirydatepdfApi.putStockonhandreportbyexpirydatepdf
                .bind(stockonhandreportbyexpirydatepdfApi);
            this._functions['putStockonhandreportfilter'] = stockonhandreportfilterApi.putStockonhandreportfilter
                .bind(stockonhandreportfilterApi);
            this._functions['putStockonhandreportpdf'] = stockonhandreportpdfApi.putStockonhandreportpdf
                .bind(stockonhandreportpdfApi);
            this._functions['putStockreportbyspc'] = stockreportbyspcApi.putStockreportbyspc
                .bind(stockreportbyspcApi);
            this._functions['putSubcategories4contractcategory'] = subcategories4contractcategoryApi.putSubcategories4contractcategory
                .bind(subcategories4contractcategoryApi);
            this._functions['putSubcategory4supplierincontract'] = subcategory4supplierincontractApi.putSubcategory4supplierincontract
                .bind(subcategory4supplierincontractApi);
            this._functions['putSuggestproductcode'] = suggestproductcodeApi.putSuggestproductcode
                .bind(suggestproductcodeApi);
            this._functions['putSuppliers4contract'] = suppliers4contractApi.putSuppliers4contract
                .bind(suppliers4contractApi);
            this._functions['putUnsentposummary'] = unsentposummaryApi.putUnsentposummary
                .bind(unsentposummaryApi);
            this._functions['postUnsentposummary'] = unsentposummaryApi.postUnsentposummary
                .bind(unsentposummaryApi);
            this._functions['putUnsentpurchaseordertosend'] = unsentpurchaseordertosendApi.putUnsentpurchaseordertosend
                .bind(unsentpurchaseordertosendApi);
            this._functions['postUnsentpurchaseordertosend'] = unsentpurchaseordertosendApi.postUnsentpurchaseordertosend
                .bind(unsentpurchaseordertosendApi);
            this._functions['putUomwprassignments'] = uomwprassignmentsApi.putUomwprassignments
                .bind(uomwprassignmentsApi);
            this._functions['getUomwprassignments'] = uomwprassignmentsApi.getUomwprassignments
                .bind(uomwprassignmentsApi);
            this._functions['postUomwprassignments'] = uomwprassignmentsApi.postUomwprassignments
                .bind(uomwprassignmentsApi);
            this._functions['putWccpricelistreleases'] = wccpricelistreleasesApi.putWccpricelistreleases
                .bind(wccpricelistreleasesApi);
            this._functions['getWccpricelistreleases'] = wccpricelistreleasesApi.getWccpricelistreleases
                .bind(wccpricelistreleasesApi);
            this._functions['postWccpricelistreleases'] = wccpricelistreleasesApi.postWccpricelistreleases
                .bind(wccpricelistreleasesApi);
            this._functions['putWccpricelists'] = wccpricelistsApi.putWccpricelists
                .bind(wccpricelistsApi);
            this._functions['getWccpricelists'] = wccpricelistsApi.getWccpricelists
                .bind(wccpricelistsApi);
            this._functions['postWccpricelists'] = wccpricelistsApi.postWccpricelists
                .bind(wccpricelistsApi);

            // override APIs below which need xhr-Downloader, see 'build/api-client-generator/downloadApis' for list of APIs

            const downloadpurchasereqspdfApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/oumain/downLoadpr2');

            this._functions['putDownloadpurchasereqspdf'] = downloadpurchasereqspdfApiXhr.downloadFileAsPut
                .bind(downloadpurchasereqspdfApiXhr);

            const oucostcentrescanningsheetApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/CostCentreScanningSheet');

            this._functions['putOucostcentrescanningsheet'] = oucostcentrescanningsheetApiXhr.downloadFileAsPut
                .bind(oucostcentrescanningsheetApiXhr);

            const oudevicescanningsheetpdfApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/DeviceScanningSheet');

            this._functions['putOudevicescanningsheetpdf'] = oudevicescanningsheetpdfApiXhr.downloadFileAsPut
                .bind(oudevicescanningsheetpdfApiXhr);

            const oudownloadsApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/Downloads');

            this._functions['putOudownloads'] = oudownloadsApiXhr.downloadFileAsPut
                .bind(oudownloadsApiXhr);

            const ouhealthfundscanningsheetApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/HealthFundScanningSheet');

            this._functions['putOuhealthfundscanningsheet'] = ouhealthfundscanningsheetApiXhr.downloadFileAsPut
                .bind(ouhealthfundscanningsheetApiXhr);

            const oukitscanningsheetApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/KitScanningSheet');

            this._functions['putOukitscanningsheet'] = oukitscanningsheetApiXhr.downloadFileAsPut
                .bind(oukitscanningsheetApiXhr);

            const oulocationscanningsheetApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/LocationScanningSheet');

            this._functions['putOulocationscanningsheet'] = oulocationscanningsheetApiXhr.downloadFileAsPut
                .bind(oulocationscanningsheetApiXhr);

            const oupatienttypescanningsheetApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/PatientTypeScanningSheet');

            this._functions['putOupatienttypescanningsheet'] = oupatienttypescanningsheetApiXhr.downloadFileAsPut
                .bind(oupatienttypescanningsheetApiXhr);

            const ouscopescanningsheetApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/ScopeScanningSheet');

            this._functions['putOuscopescanningsheet'] = ouscopescanningsheetApiXhr.downloadFileAsPut
                .bind(ouscopescanningsheetApiXhr);

            const oustaffscanningsheetApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/StaffScanningSheet');

            this._functions['putOustaffscanningsheet'] = oustaffscanningsheetApiXhr.downloadFileAsPut
                .bind(oustaffscanningsheetApiXhr);

            const ousublocationscanningsheetApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/SubLocationScanningSheet');

            this._functions['putOusublocationscanningsheet'] = ousublocationscanningsheetApiXhr.downloadFileAsPut
                .bind(ousublocationscanningsheetApiXhr);

            const proceduredetailspdfApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouprocreports/ProcedureDetailsReportPDF');

            this._functions['putProceduredetailspdf'] = proceduredetailspdfApiXhr.downloadFileAsPut
                .bind(proceduredetailspdfApiXhr);

            const proctypescanningsheetApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/ProcedureTypeScanningSheet');

            this._functions['putProctypescanningsheet'] = proctypescanningsheetApiXhr.downloadFileAsPut
                .bind(proctypescanningsheetApiXhr);

            const stockonhandreportbyexpirydatepdfApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouadmin/SOHReportByExpiryDatePDF');

            this._functions['putStockonhandreportbyexpirydatepdf'] = stockonhandreportbyexpirydatepdfApiXhr.downloadFileAsPut
                .bind(stockonhandreportbyexpirydatepdfApiXhr);

            const stockonhandreportpdfApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/oumain/SOHReportPDF');

            this._functions['putStockonhandreportpdf'] = stockonhandreportpdfApiXhr.downloadFileAsPut
                .bind(stockonhandreportpdfApiXhr);

            const pcprintApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/pc/pcprint');

            this._functions['putPcprint'] = pcprintApiXhr.downloadFileAsPut
                .bind(pcprintApiXhr);

            const pcimageApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/image/prefcard');

            this._functions['getImage'] = pcimageApiXhr.downloadFileAsGet
                .bind(pcimageApiXhr);

            const schedule5reportpdfApiXhr = new BlobDownloaderUtil(this._xhrDownloaderUtil, basePath, '/ouprocreports/Schedule5ReportPDF');

            this._functions['putSchedule5reportpdf'] = schedule5reportpdfApiXhr.downloadFileAsPut
                .bind(schedule5reportpdfApiXhr);
        });
    }

    public getFunction<T>(operationId: string): ApiFunction<T> {
        return this._functions[this._camelize(operationId)] as ApiFunction<T>;
    }

    public executeOperationId<T>(operationId: string): Observable<T> {
        return (this._functions[this._camelize(operationId)] as () => Observable<T>)();
    }

    private _camelize(string: string): string {
        return string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m: string, char: string) => char.toUpperCase());
    }
}
