import {
    ChangeDetectionStrategy,
    Component,
    Input,
    HostBinding,
    ViewChild,
    inject, input, computed, output,
} from '@angular/core';
import { ControlContainer, FormsModule, NgForm } from '@angular/forms';
import { UserLanguageService } from '@hrs-ui/translation/domain-translation';
import { DatePicker } from 'primeng/datepicker';
import { DataTypes } from '@hrs-ui/util-definitions';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';

@Component({
    selector: 'ht-datepicker',
    templateUrl: './ht-datepicker.component.html',
    styleUrls: ['./ht-datepicker.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        MatDatepickerModule,
        MatInputModule,
        DatePicker,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})

export class HtDatepickerComponent {
    @HostBinding('class')
    public class = 'form-element';

    @ViewChild('calendar', { static: false })
    public calendarElement?: DatePicker;

    public placeholder = input<string>();

    // ngModel binding not yet working with signals. It may be the null value though
    @Input()
    public value?: Date | null;

    public name = input('');

    public dataType = input(DataTypes.DateTime);

    public disabled = input(false);

    public required = input(false);

    public tabIndex = input(-1);

    public readonly valueChange = output<Date>();

    private readonly _userLanguageService = inject(UserLanguageService);

    public placeholderWithFallback = computed(() => (this.placeholder()
        ? this.placeholder()
        : this._getDateFormatPlaceholder(this.dateFormat())
    ));

    public dateFormat = toSignal(this._userLanguageService.language$.pipe(
        map(lang => this._getDateFormat(lang)),
    ), { initialValue: 'mm/dd/y' });

    public showTime = computed(() => this.dataType() === 'datetime');

    private _getDateFormat(lang: string): string {
        switch (lang) {
            case 'de-DE':
            case 'nl-NL':
                return 'dd.mm.y';
            case 'en-AU':
            case 'en-NZ':
            case 'en-GB':
            default:
                return 'dd/mm/y';
        }
    }

    private _getDateFormatPlaceholder(dateFormat: string): string {
        if (this.showTime()) {
            switch (dateFormat) {
                case 'dd.mm.y':
                    return 'dd.mm.yy hh:mm';
                case 'dd/mm/y':
                default:
                    return 'dd/mm/yy hh:mm';
            }
        }

        switch (dateFormat) {
            case 'dd.mm.y':
                return 'dd.mm.yy';
            case 'dd/mm/y':
            default:
                return 'dd/mm/yy';
        }
    }
}
